import styled from 'styled-components';

const UiInputText = ({
  label,
  value,
  onChange,
  disabled,
  autoFocus,
  name,
  placeholder,
  onKeyUp,
  error,
  errorMsg,
  noMargin,
}: any) => {
  return (
    <Container onClick={(ev: any) => ev.stopPropagation()} noMargin={noMargin}>
      {label && <Label>{label}</Label>}
      <Input
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        name={name}
        placeholder={placeholder}
        autocomplete="off"
        onKeyUp={onKeyUp}
      />
      {error && <Error>{errorMsg}</Error>}
    </Container>
  );
};

const Container: any = styled.div<any>`
  color: #222;
  display: flex;
  flex-direction: column;
  margin: ${({noMargin}) => (noMargin ? '0' : '10px')};
  flex-grow: 1;
`;

const Label: any = styled.label`
  font-size: 0.8em;
`;

const Error: any = styled.div`
  font-size: 0.7em;
  margin-top: 4px;
  color: red;
`;

const Input: any = styled.input.attrs({type: 'text'})<any>`
  background-color: ${({disabled}) => (disabled ? '#EEE' : 'transparent')};
  border: 1px solid #ddd;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'auto')};
  padding: 10px;
  width: calc(100% - 20px);

  &:focus {
    border: 1px solid #099cec;
  }
`;

export default UiInputText;
