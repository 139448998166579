import {UiInfo} from 'components/UiInfo'

const InfosLogradouros = ({infos}: any) => (
    <>
        <UiInfo label="Nome" value={infos["NOME_RUA"]}/>
        <UiInfo label="Tipo de pavimentação" value={infos["TIPO_PAVIM"]}/>
        <UiInfo label="Água" value={infos["AGUA"]}/>
        <UiInfo label="Energia" value={infos["ENERGIA"]}/>
        <UiInfo label="Esgoto" value={infos["ESGOTO"]}/>
        <UiInfo label="Tratamento de esgoto" value={infos["TRAT_ESGOT"]}/>
    </>
)

export default InfosLogradouros
