import {DateTime} from 'luxon';
import React, {useCallback, useEffect, useState} from 'react';
import {Transition} from 'react-transition-group';
import styled from 'styled-components';
import {
  INotification,
  INotificationsContextValue,
  INotificationsProviderProps,
} from './INotificationsProvider';

const getBackgroundColor = ({type}: any) => {
  switch (type) {
    case 'warning':
      return 'rgb(245, 167, 0)';
    case 'danger':
      return 'DarkRed';
    case 'success':
      return '#4bb543';
    default:
      return 'rgb(245, 167, 0)';
  }
};

export const UiNotifications = React.createContext<INotificationsContextValue>({
  notifications: [],
  setNotifications: undefined,
  addNotification: undefined,
});

export const UiNotificationsProvider = ({children}: INotificationsProviderProps) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(notifications.length > 0);
  }, [notifications]);

  useEffect(() => {
    let t = setInterval(() => {
      setNotifications((nots: INotification[]) =>
        nots.filter(n => n.createdAt.plus(n.timeout).diffNow().valueOf() > 0)
      );
    }, 1000);

    return () => {
      clearInterval(t);
    };
  }, [notifications]);

  const addNotification = useCallback((notification: INotification) => {
    setNotifications(n => [
      ...n,
      {
        ...notification,
        timeout: notification.timeout || 500000,
        createdAt: DateTime.local(),
      },
    ]);
  }, []);

  const renderNotifications = () => {
    return notifications.map((n, i) => {
      return (
        <Transition in={show} timeout={1} key={n.id} unmountOnExit>
          {state => {
            return (
              <NotificationContainer type={n.type} index={i} state={state}>
                <NotificationTexts>
                  <NotificationText>{n.text}</NotificationText>
                  <NotificationSubtext>{n.subtext}</NotificationSubtext>
                </NotificationTexts>
                <NotificationIcon>{n.icon}</NotificationIcon>
              </NotificationContainer>
            );
          }}
        </Transition>
      );
    });
  };

  return (
    <UiNotifications.Provider
      value={{
        notifications,
        setNotifications,
        addNotification,
      }}
    >
      {renderNotifications()}
      {children}
    </UiNotifications.Provider>
  );
};

const NotificationContainer: any = styled.div<any>`
  background-color: ${getBackgroundColor};
  border-radius: 2px;
  color: white;
  padding: 10px;
  position: fixed;
  left: 100%;
  top: ${({index}: any) => `${index * 80 + 30}px`};
  width: 350px;
  z-index: 10000;

  display: flex;

  transition: all 0.2s ease-in-out;
  transform: translateX(${({state}: any) => (state === 'entered' ? `-375px` : 0)});
`;

const NotificationTexts: any = styled.div`
  flex-grow: 1;
`;

const NotificationText: any = styled.div`
  display: flex;
  align-items: center;
  min-height: 25px;
  font-weight: bold;
  user-select: none;
  cursor: default;
`;
const NotificationSubtext: any = styled.div`
  display: flex;
  align-items: center;
  min-height: 25px;
  font-weight: bold;
  font-size: 0.9em;
  user-select: none;
  cursor: default;
`;
const NotificationIcon: any = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;
