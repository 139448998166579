import axios from "axios";

const SIG_URL =
    process.env.REACT_APP_SIG_ENV === "sigcidades"
        ? "https://sigcidades.com.br/"
        : process.env.REACT_APP_SIG_ENV === "sigraian" ?
            "https://sig.raian.dev/"
            : "http://127.0.0.1:8080/"

const ORTO_URL = `${SIG_URL}ortofoto`
export const API_URL = `${SIG_URL}api`
const EXPORT_URL = `${SIG_URL}exports`

export const axiosInstance = axios.create({baseURL: API_URL});

const postLayer = async (form: any, id: string, cidade: string, headers: any) =>
    await axiosInstance.post(`${API_URL}/camada?idGeoJSON=${id}&cidade=${cidade}`, form, {headers})

const mergeLayer = async (geoJSONID: string, cidade: string, mergeInID: string, whichIDKeep: string, headers: any) =>
    await axiosInstance.get(`${API_URL}/camada/${mergeInID}/merge?idGeoJSON=${geoJSONID}&cidade=${cidade}&whichIDKeep=${whichIDKeep}`,
        {headers})

const updateLayer = async (cidade: string, id: string, form: any, headers: any) =>
    await axiosInstance.put(`${API_URL}/camada/${id}?cidade=${cidade}`, form, {headers})

const deleteLayer = async (cidade: string, id: string, headers: any) =>
    await axiosInstance.delete(`${API_URL}/camada/${id}?cidade=${cidade}`, {headers})

const saveLogradouro = async (id: string, form: any, cidade: string, headers: any) =>
    await axiosInstance.put(`${API_URL}/logradouro/${id}?cidade=${cidade}`, form, {headers})

const saveLote = async (id: string, form: any, cidade: string, headers: any) =>
    await axiosInstance.put(`${API_URL}/lote/${id}?cidade=${cidade}`, form, {headers})

const saveEdificacao = async (id: string, form: any, cidade: string, headers: any) =>
    await axiosInstance.put(`${API_URL}/edificacao/${id}?cidade=${cidade}`, form, {headers})

const saveLayerDraw = async (layer: any, features: any, cidade: string, headers: any) =>
    await axiosInstance.put(`${API_URL}/camada/${layer.id}/featuresAdd?cidade=${cidade}`, features, {headers})

const deleteFeatures = async (layer: any, features: any, cidade: string, headers: any) =>
    await axiosInstance.put(`${API_URL}/camada/${layer.id}/featuresDelete?cidade=${cidade}`, features, {headers})

const saveLogoGlobalConfig = async (form: any, headers: any) => await axiosInstance.put(`/globalconfig/logo`, form, {headers})

const saveCidadeSelectorConfig = async (form: any, headers: any) => await axiosInstance.put(`${API_URL}/globalconfig/cidadeSelector`, form, {headers})

const postCidade = async (form: any, headers: any) => await axiosInstance.post(`${API_URL}/cidades`, form, {headers})

const putCidade = async (id: string, form: any, headers: any) => await axiosInstance.put(`${API_URL}/cidades/${id}`, form, {headers})

const deleteUsuario = async (cidade: string, id: string, headers: any) => await axiosInstance.delete(`${API_URL}/usuario/${id}?cidade=${cidade}`, {headers})

const login = async (form: any, cidade: string) => await axiosInstance.post(`${API_URL}/login?cidade=${cidade}`, form)

const cleanCache = async (cidade: string, headers: any) => await axiosInstance.get(`${API_URL}/cidade/${cidade}/clearCache?cidade=${cidade}`, {headers});

const deleteArquivoLogradouro = async (id: string, headers: any) => await axiosInstance.delete(`${API_URL}/arquivo/logradouro/${id}`, {headers})

const deleteArquivoLote = async (id: string, headers: any) => await axiosInstance.delete(`${API_URL}/arquivo/lote/${id}`, {headers})

const executaFiltro = async (cidade: string, filtros: any) => await axiosInstance.post(`/executaFiltro?cidade=${cidade}`, filtros)

const overwriteFile = async (form: any, path: string, cidade: string, onUploadProgress: any, headers: any) =>
    await axiosInstance.post(`${API_URL}/drive/overwritefile?path=${path}&cidade=${cidade}`, form, {
        onUploadProgress,
        headers
    })

const createFolder = async (path: string, cidade: string, headers: any) =>
    await axiosInstance.post(`${API_URL}/drive/folder?path=${path}&cidade=${cidade}`, {}, {
        headers
    })

const uploadTmpFile = async (form: any, folderID: string, cidade: string, headers: any, onUploadProgress?: any) =>
    await axiosInstance.post(`${API_URL}/drive/createtmpfile?folderID=${folderID}&cidade=${cidade}`, form,
        {onUploadProgress, headers})

const convertShapefileToGeoJSON = async (id: string, cidade: string, headers: any) =>
    await axiosInstance.get(`${API_URL}/camada/${id}/convert?cidade=${cidade}`, {headers})

const processCSVFile = async (cidade: string, id: string, filename: string, headers: any) =>
    await axiosInstance.get(`${API_URL}/processos/csv?cidade=${cidade}&path=${id}/${filename}`, {headers});

const postUsuario = async (cidade: string, form: any, tipo: string, headers: any) =>
    tipo ? await axiosInstance.post(`${API_URL}/superusuario?cidade=${cidade}`, form, {headers}) :
        await axiosInstance.post(`${API_URL}/usuario?cidade=${cidade}`, form, {headers})

const putUsuario = async (cidade: string, id: string, form: any, tipo: string, headers: any) =>
    tipo ? await axiosInstance.put(`${API_URL}/superusuario/${id}?cidade=${cidade}`, form, {headers}) :
        await axiosInstance.put(`${API_URL}/usuario/${id}?cidade=${cidade}`, form, {headers})

const startReprojectionOrtoimagem = async (cidade: string, idArquivo: string, filename: string, headers: any) => await axiosInstance.get(
    `${ORTO_URL}/reprojection/orto?idArquivo=${idArquivo}&cidade=${cidade}&filename=${filename}`, {headers}
)

const startReprojectionMde = async (idArquivo: string, cidade: string, headers: any) =>
    await axiosInstance.get(`${ORTO_URL}/reprojection/mde?idArquivo=${idArquivo}&idCidade=${cidade}`, {headers})

const searchInfo = async (cidade: string, busca: string, campos: any) =>
    await axiosInstance.post(`${API_URL}/search?cidade=${cidade}`, {busca, campos});

const attachMarcoGeodesicoFile = async (data: any, nome: string, id: string, headers: any) =>
    await axiosInstance.post(`${API_URL}/arquivo/geodesico?id=${id}`, {data, nome}, {headers})

const postArquivoLogradouro = async (id: string, data: any, nome: string, descricao: string, headers: any) =>
    await axiosInstance.post(`${API_URL}/arquivo/logradouro?id=${id}`, {data, nome, descricao}, {headers})

const postArquivoLote = async (id: string, data: string, nome: string, descricao: string, headers: any) =>
    await axiosInstance.post(`${API_URL}/arquivo/lote?id=${id}`, {data, nome, descricao}, {headers})

const postImagemLote = async (id: string, data: string, nome: string, descricao: string, headers: any) =>
    await axiosInstance.post(`${API_URL}/imagem/lote`, {id, data, nome, descricao}, {headers})

const deleteImagemEdificacao = async (id: string, nome: string, headers: any) =>
    await axiosInstance.delete(`${API_URL}/imagem/edificacao/${id}?nome=${nome}`, {headers})

const deleteImagemLote = async (id: string, nome: string, headers: any) =>
    await axiosInstance.delete(`${API_URL}/imagem/lote/${id}?nome=${nome}`, {headers})

const deleteImagemLogradouro = async (id: string, nome: string, headers: any) =>
    await axiosInstance.delete(`${API_URL}/imagem/logradouro/${id}?nome=${nome}`, {headers})

const gerarConsultaPrevia = async (cidade: string, id: string, imageData: string) =>
    await axiosInstance.post(`${API_URL}/processos/consultaprevia?cidade=${cidade}&id=${id}`, {imageData})

const uploadFileV2 = async (form: any, onFinish: any, onUploadProgress: any, headers: any) => {
    const resp = await axiosInstance.post(`${API_URL}/upload`, form, {onUploadProgress, headers});
    onFinish(resp.data);
};

export const API = {
    API_URL, ORTO_URL, EXPORT_URL,
    processCSVFile, login, putUsuario, postUsuario, uploadFileV2, gerarConsultaPrevia, executaFiltro,
    searchInfo, putCidade, postCidade, saveCidadeSelectorConfig, saveLogoGlobalConfig,
    attachMarcoGeodesicoFile, saveLayerDraw, deleteFeatures, overwriteFile,
    cleanCache, saveEdificacao, deleteImagemEdificacao, updateLayer, uploadTmpFile, convertShapefileToGeoJSON,
    startReprojectionOrtoimagem, startReprojectionMde, deleteLayer, postLayer, saveLogradouro,
    postArquivoLogradouro, deleteArquivoLogradouro, deleteImagemLogradouro, mergeLayer,
    saveLote, postArquivoLote, postImagemLote, deleteImagemLote, deleteArquivoLote, deleteUsuario, createFolder
}
