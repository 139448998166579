import {AppContext} from 'contexts/AppContext';
import {useContext} from 'react';
import {REDUCER_MAPA_TEMATICO_ACTIONS} from 'reducers/mapatematico';
import styled from 'styled-components';

const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const LegendaMapaTematicoModal = () => {
  const {mapaTematicoState, mapaTematicoDispatch} = useContext(AppContext);

  const handleChangeColor = (e: any) => {
    const newColor = getRandomColor();
    const novaLegenda = {
      ...mapaTematicoState.legenda,
      [e]: newColor,
    };
    mapaTematicoDispatch({
      type: REDUCER_MAPA_TEMATICO_ACTIONS.SET_ITEMS_LEGENDA,
      payload: novaLegenda,
    });
  };

  const renderLegenda = () => (
    <>
      <Titulo>Legenda - {mapaTematicoState.campo}</Titulo>
      <Items>
        {Object.keys(mapaTematicoState.legenda).map(item => (
          <Item key={item}>
            <Quadrado
              color={mapaTematicoState.legenda[item]}
              onClick={() => handleChangeColor(item)}
            />
            <Texto>{item || 'Sem dados'}</Texto>
          </Item>
        ))}
      </Items>
    </>
  );

  if (!Object.keys(mapaTematicoState.legenda).length) {
    return null;
  }

  return <Container id={'legenda'}>{renderLegenda()}</Container>;
};
const Container: any = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 30px;
  z-index: 9999;

  flex-direction: column;
  background-color: #ffffffdd;
  backdrop-filter: blur(5px);
  border: 1px solid silver;
`;

const Titulo: any = styled.div`
  align-items: center;
  border-bottom: 1px solid silver;
  display: flex;
  font-weight: bold;
  justify-content: center;
  min-height: 30px;
  min-width: 250px;
`;
const Items: any = styled.div`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Item: any = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 30px;
  min-width: 250px;
`;
const Quadrado: any = styled.div`
  height: 20px;
  width: 30px;
  background-color: ${({color}) => color};
  margin: 5px;
  cursor: pointer;
`;
const Texto: any = styled.div`
  display: flex;
  align-items: center;
`;

export default LegendaMapaTematicoModal;
