import React, {useEffect, useMemo, useReducer} from "react";
import {REDUCER_SESSION_ACTIONS, REDUCER_SESSION_INITIAL, sessionReducer} from "reducers/session";
import {ISessionContextValue, SessionContextProps} from "./IContexts";
import {useGet} from "../hooks/useGet";

export const SessionContext = React.createContext<ISessionContextValue>({
        sessionState: REDUCER_SESSION_INITIAL,
        sessionDispatch: undefined,
        headers: {}
    }
);

export const SessionContextProvider = ({children}: SessionContextProps) => {
    const [sessionState, sessionDispatch] = useReducer(sessionReducer, REDUCER_SESSION_INITIAL)

    const headers = useMemo(() => ({'Authorization': sessionState.token}), [sessionState.token])
    const {data: sessao} = useGet(`/sessao/${sessionState.token}/usuario?cidade=${sessionState.cidadeSelecionadaSessao}`,
        headers, undefined, sessionState.token || 0)

    useEffect(() => {
        sessao && !Array.isArray(sessao) && sessionDispatch({
            type: REDUCER_SESSION_ACTIONS.SET_SESSAO_INFOS,
            payload: sessao
        })
    }, [sessao]);

    return (
        <SessionContext.Provider value={{sessionState, sessionDispatch, headers}}>
            {children}
        </SessionContext.Provider>
    );
};
