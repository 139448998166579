import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditEndereco = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>
                <UiInputText label="Adquirente" name={"ADQUIRENTE"} value={objeto["ADQUIRENTE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Bairro" name={"BAIRRO"} value={objeto["BAIRRO"]} onChange={handleChangeField}/>
                <UiInputText label="CEP" name={"CEP"} value={objeto["CEP"]} onChange={handleChangeField}/>
                <UiInputText label="Comarca Cidade/UF" name={"COMARCA_CIDADE_UF"} value={objeto["COMARCA_CIDADE_UF"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Complemento do endereço" name={"COMPLEMENTO_ENDERECO"}
                             value={objeto["COMPLEMENTO_ENDERECO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Comunidade" name={"COMUNIDADE"} value={objeto["COMUNIDADE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Estado" name={"ESTADO"} value={objeto["ESTADO"]} onChange={handleChangeField}/>
                <UiInputText label="Logradouro" name={"LOGRADOURO"} value={objeto["LOGRADOURO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Logradouro Terreno" name={"LOGRADOURO_TERRENO"} value={objeto["LOGRADOURO_TERRENO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Municipio" name={"MUNICIPIO"} value={objeto["MUNICIPIO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Nome do bairro" name={"NOME_BAIRRO"} value={objeto["NOME_BAIRRO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Trecho" name={"TRECHO"} value={objeto["TRECHO"]} onChange={handleChangeField}/>
                <UiInputText label="UF" name={"UF"} value={objeto["UF"]} onChange={handleChangeField}/>
                <UiInputText label="Bairro do corresponsável" name={"BAIRRO_CORRESPONSAVEL"}
                             value={objeto["BAIRRO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Cidade do corresponsável" name={"CIDADE_CORRESPONSAVEL"}
                             value={objeto["CIDADE_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="CEP do corresponsável" name={"CEP_CORRESPONSAVEL"}
                             value={objeto["CEP_CORRESPONSAVEL"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Complemento do corresponsável" name={"COMPLEMENTO_CORRESPONSAVEL"}
                             value={objeto["COMPLEMENTO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Descrição do bairro do corresponsável" name={"DESCRICAO_BAIRRO_CORRESPONSAVEL"}
                             value={objeto["DESCRICAO_BAIRRO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Desc. do logradouro do corresponsável" name={"DESCRICAO_LOGRADOURO_CORRESPONSAVEL"}
                             value={objeto["DESCRICAO_LOGRADOURO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Estado do corresponsável" name={"ESTADO_CORRESPONSAVEL"}
                             value={objeto["ESTADO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Numero do imóvel do corresponsável" name={"NUMERO_IMOVEL_CORRESPONSAVEL"}
                             value={objeto["NUMERO_IMOVEL_CORRESPONSAVEL"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditEndereco
