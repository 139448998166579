import UiFlexItem from 'components/UiFlex';
import UiPanel from 'components/UiPanel';
import UiTable from 'components/UiTable';
import UiText from 'components/UiText';
import {FileUploadContext} from 'contexts/FileUploadContext';
import {useContext} from 'react';
import RegisterPage from '../index';

const TabelaEnvios = () => {
  const {fileUploadState} = useContext(FileUploadContext);

  const renderSize = (data: any) => {
    if (data >= 1024 * 1024) {
      data = data / 1024 / 1024;
      return `${data.toFixed(2)} Mb`;
    }

    if (data >= 1024) {
      data = data / 1024;
      return `${data.toFixed(2)} Kb`;
    }

    return `${data} b`;
  };

  const renderPercentual = (data: any, row: any) =>
    `${((row.loaded * 100) / row.total).toFixed(2)} %`;

  const headers = [
    {field: 'loaded', title: 'Enviado', applyRender: renderSize},
    {field: 'total', title: 'Total', applyRender: renderSize},
    {field: 'filename', title: 'Nome do arquivo'},
    {field: 'percentual', title: '%', applyRender: renderPercentual},
  ];

  return (
    <RegisterPage>
      <UiPanel maxHeight="calc(100% - 70px)">
        <UiFlexItem direction="column">
          <UiText bold>Envio de arquivos</UiText>
          <UiFlexItem justifyContent="center">
            <UiTable
              rows={fileUploadState.envios}
              headers={headers}
              noDataText={'Nenhum envio realizado'}
            />
          </UiFlexItem>
        </UiFlexItem>
      </UiPanel>
    </RegisterPage>
  );
};

export default TabelaEnvios;
