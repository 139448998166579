import {UiInfo} from 'components/UiInfo';
import {SessionContext} from 'contexts/SessionContext';
import {useContext} from 'react';
import {renderDocumento} from '../../../utils/utils';

const InfosDocumentosPessoais = ({infos}: any) => {
  const {sessionState} = useContext(SessionContext);

  const isNovaPetropolis = sessionState.cidadeSelecionadaSessao === '5f4e397f4397338c4f8f5f0c';

  return (
    <>
      <UiInfo label="Complemento nome" value={infos['COMPLEMENTO_NOME']} />
      <UiInfo label="Contribuinte" value={infos['CONTRIBUINTE']} isPrivate={isNovaPetropolis} />
      <UiInfo label="CPF/CNPJ" value={renderDocumento(infos['CPF_CNPJ'])} isPrivate />
      <UiInfo label="Corresponsável" value={infos['CORRESPONSAVEL']} isPrivate={isNovaPetropolis} />
      <UiInfo label="CPF/CNPJ" value={infos['CPF_CNPJ_CORRESPONSAVEL']} isPrivate />
    </>
  );
};

export default InfosDocumentosPessoais;
