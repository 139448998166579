import UiFlex from 'components/UiFlex';
import ListaProcessos from 'pages/MinhaCidadePage/MinhaCidadeConfigPage/ListaProcessos';
import ConfigPage from '../index';

const GeralConfigPage = () => (
  <ConfigPage>
    <UiFlex>
      <ListaProcessos />
    </UiFlex>
  </ConfigPage>
);

export default GeralConfigPage;
