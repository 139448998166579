import {Circle as CircleStyle, Fill, Icon as IconStyle, Stroke, Style, Text} from "ol/style";
import TextPlacement from "ol/style/TextPlacement";

const intToHex = (i: any) => {
    let hex = Number(i).toString(16);
    return hex.length < 2 ? "0" + hex : hex;
};

export const getImageForPoint = (layer: any, selected: boolean) => {
    if (!selected) {
        return layer.tipoCamada === "textual"
            ? new CircleStyle({radius: 0})
            : new IconStyle({src: "/static/red-triangle.png"});
    }

    return layer.tipoCamada === "textual"
        ? new CircleStyle({radius: 0})
        : new CircleStyle({
            fill: new Fill({color: "#FF0000AA"}),
            radius: 5, stroke: new Stroke({color: "red", width: layer.espessuraLinha}),
        });
}

const getPointStyle = (layer: any, selected: boolean) => {
    return new Style({
        image: getImageForPoint(layer, selected),
        text: new Text({
            stroke: new Stroke({
                color: selected ? 'black' : layer.corFundo + intToHex(layer.opacidadeFundo), width: 1,
            }),
            fill: new Fill({color: selected ? 'black' : layer.corFundo + intToHex(layer.opacidadeFundo)}),
        }),
    })
}

const getLineStringStyle = (layer: any, selected: boolean) => {
    return new Style({
        stroke: new Stroke({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeLinha) : layer.corLinha + intToHex(layer.opacidadeLinha),
            width: layer.espessuraLinha,
        }),
        text: new Text({
            font: '12px "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: "line",
            stroke: new Stroke({width: 1}),
            fill: new Fill({color: "black"}),
        }),
    })
}

const getMultiLineStringStyle = (layer: any, selected: boolean) => {
    return new Style({
        stroke: new Stroke({
            color: selected ? layer.corLinha + intToHex(layer.opacidadeLinha) : "#FF0000" + intToHex(layer.opacidadeLinha),
            width: layer.espessuraLinha,
        }),
    })
}

const getMultiPolygonStyle = (layer: any, selected: boolean) => {
    return new Style({
        stroke: new Stroke({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeLinha) : layer.corLinha + intToHex(layer.opacidadeLinha),
            width: layer.espessuraLinha,
        }),
        fill: new Fill({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeFundo) : layer.corFundo + intToHex(layer.opacidadeFundo),
        }),
    })
}

const getPerimetroStyle = (layer: any) => {
    return new Style({
        stroke: new Stroke({
            color: layer.corLinha + intToHex(layer.opacidadeLinha),
            width: layer.espessuraLinha,
        }),
        fill: new Fill({color: layer.corFundo + intToHex(layer.opacidadeFundo)}),
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({color: layer.corFundo + intToHex(layer.opacidadeFundo)}),
            stroke: new Stroke({color: layer.corLinha, width: layer.espessuraLinha}),
        }),
        text: new Text({
            font: '12px "Open Sans", "Arial Unicode MS", "sans-serif"',
            stroke: new Stroke({color: layer.corFundo + 'FF', width: 1}),
            fill: new Fill({color: layer.corFundo + 'FF'}),
            placement: layer.alinhamentoTexto || TextPlacement.LINE,
            textBaseline: 'top',
        }),
    })
}

const getGeometryCollectionStyle = (layer: any, selected: boolean) => {
    return new Style({
        stroke: new Stroke({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeLinha) : layer.corLinha + intToHex(layer.opacidadeLinha),
            width: layer.espessuraLinha,
        }),
        fill: new Fill({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeFundo) : layer.corLinha + intToHex(layer.opacidadeLinha),
        }),
        image: new CircleStyle({
            radius: 10,
            stroke: new Stroke({
                color: selected ? "#FF0000" + intToHex(layer.opacidadeLinha) : layer.corLinha + intToHex(layer.opacidadeLinha),
            }),
        }),
    })
}

const getCircleStyle = (layer: any, selected: boolean) => {
    return new Style({
        stroke: new Stroke({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeLinha) : layer.corLinha + intToHex(layer.opacidadeLinha),
            width: layer.espessuraLinha,
        }),
        fill: new Fill({
            color: selected ? "#FF0000" + intToHex(layer.opacidadeFundo) : layer.corLinha + intToHex(layer.opacidadeLinha),
        }),
    })
}

export const getSelectedStyle = (layer: any) => {
    return {
        Point: getPointStyle(layer, true),
        LineString: getLineStringStyle(layer, true),
        MultiLineString: getMultiLineStringStyle(layer, true),
        MultiPoint: new Style({image: getImageForPoint(layer, true)}),
        MultiPolygon: getMultiPolygonStyle(layer, true),
        Polygon: getMultiPolygonStyle(layer, true),
        GeometryCollection: getGeometryCollectionStyle(layer, true),
        Circle: getCircleStyle(layer, true),
    };
};

export const getDefaultStyle = (layer: any) => {
    return {
        Point: getPointStyle(layer, false),
        LineString: getLineStringStyle(layer, false),
        MultiLineString: getMultiLineStringStyle(layer, false),
        MultiPoint: new Style({image: getImageForPoint(layer, true)}),
        MultiPolygon: getMultiPolygonStyle(layer, false),
        Polygon: getMultiPolygonStyle(layer, false),
        Perimetro: getPerimetroStyle(layer),
        GeometryCollection: getGeometryCollectionStyle(layer, false),
        Circle: getCircleStyle(layer, false),
    };
};

export const getNullStyle = (layer: any) => {
    const image = getImageForPoint(layer, false)

    return {
        Point: new Style({
            image: image,
            text: new Text({
                font: '12px "Open Sans", "Arial Unicode MS", "sans-serif"',
                stroke: new Stroke({color: layer.corFundo + '00', width: 1}),
                fill: new Fill({color: layer.corFundo + '00'}),
            }),
        }),
        LineString: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
            text: new Text({
                font: '12px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: "line",
                stroke: new Stroke({width: 1}),
                fill: new Fill({color: "black"}),
            }),
        }),
        MultiLineString: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
        }),
        MultiPoint: new Style({image: image}),
        MultiPolygon: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
            fill: new Fill({color: layer.corFundo + '00'}),
        }),
        Polygon: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
            fill: new Fill({color: layer.corFundo + '00'}),
        }),
        Perimetro: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
            fill: new Fill({color: layer.corFundo + '00'}),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({color: layer.corFundo + '00'}),
                stroke: new Stroke({color: layer.corLinha, width: layer.espessuraLinha}),
            }),
        }),
        GeometryCollection: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
            fill: new Fill({color: layer.corFundo + '00'}),
            image: new CircleStyle({radius: 10, stroke: new Stroke({color: layer.corLinha + '00'})}),
        }),
        Circle: new Style({
            stroke: new Stroke({color: layer.corLinha + '00', width: layer.espessuraLinha}),
            fill: new Fill({color: layer.corFundo + '00'}),
        }),
    };
};

export const getStyleMapaTematico = (valor: any, legenda: any) => {
    return new Style({
        fill: new Fill({color: legenda[valor]}),
        stroke: new Stroke({color: legenda[valor]}),
    })
}
