import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {useContext, useEffect} from 'react';
import {REDUCER_LOADING_ACTIONS} from 'reducers/loading';

import {DateTime} from 'luxon';
import {useGet} from '../../../../hooks/useGet';
import {getDefaultStyle, getNullStyle, getSelectedStyle} from '../utils';

const GeoJsonLayer = ({layer}: any) => {
  const {openLayersState} = useContext(OpenLayersContext);
  const {mapState} = useContext(MapContext);
  const {loadingDispatch, appConfigState} = useContext(AppContext);

  const {data: geojson} = useGet(
    `/drive/download?cidade=${appConfigState.idCidadeSelecionada}&path=/camadas/${layer.id}.geojson`,
    undefined,
    undefined,
    openLayersState.reloadLayers
  );

  useEffect(() => {
    const isFeatureSelected = (feat: any) =>
      mapState.searchSelected.filter((e: any) => e === feat.getId()).length > 0;

    const isFeatureFiltered = (feat: any) =>
      mapState.filteredGeometries.filter((e: any) => e === feat.getId()).length > 0;

    let defaultStyle: any = getDefaultStyle(layer);
    let nullStyle: any = getNullStyle(layer);
    let selectedStyle: any = getSelectedStyle(layer);

    const styleFunction = (feature: any) => {
      const geometry = feature.getGeometry();
      const type = geometry.getType();

      let style;
      if (layer.tipoCamada === 'textual') {
        style = defaultStyle[type];
      } else if (layer.tipoCamada === 'perimetro') {
        style = defaultStyle['Perimetro'];
      } else {
        if (mapState.filteredGeometries.length) {
          style = isFeatureFiltered(feature) ? selectedStyle[type] : nullStyle[type];
        } else {
          style = isFeatureSelected(feature) ? selectedStyle[type] : defaultStyle[type];
        }
      }

      let nomeRua = feature.get('NOME_RUA');
      let texto = feature.get('TEXTO') || feature.get('texto');
      if (nomeRua) style.getText().setText(feature.get('NOME_RUA'));
      if (texto) style.getText().setText(texto);

      return style;
    };

    if (geojson) {
      try {
        let features: any[] = [];
        let selectedFeatures: any[] = [];
        let geoJSON = new GeoJSON();
        geojson.features.forEach((f: any) => {
          try {
            const newFeature = geoJSON.readFeature(f);
            const id = f.properties?.ID || f._id;
            if (layer.tipoCamada !== 'perimetro') newFeature.setId(id);
            if (isFeatureSelected(newFeature)) selectedFeatures.push(newFeature);
            features.push(newFeature);
          } catch (e) {
            console.log(e, f);
          }
        });

        let vectorSource = new VectorSource({features});
        let vectorLayer = new VectorLayer({source: vectorSource});

        let vectorSelectedSource = new VectorSource({features: selectedFeatures});
        let vectorSelectedLayer = new VectorLayer({source: vectorSelectedSource});
        vectorLayer.setStyle(styleFunction);
        vectorLayer.set('ID', layer.id);
        vectorLayer.set('ACTIVATE_TIME', DateTime.now().toMillis());

        if (layer.tipoCamada === 'edificacao') {
          vectorLayer.setZIndex(1200);
        } else if (layer.tipoCamada === 'lote') {
          vectorLayer.setZIndex(1100);
        } else if (layer.tipoCamada === 'logradouro') {
          vectorLayer.setZIndex(1000);
        } else if (layer.tipoCamada === 'geodesico') {
          vectorLayer.setZIndex(1500);
        } else if (layer.tipoCamada === 'textual') {
          vectorLayer.set('NO_SELECTABLE', true);
          vectorLayer.setZIndex(1300);
        } else {
          vectorLayer.set('NO_SELECTABLE', true);
          vectorLayer.setZIndex(1400);
        }
        vectorSelectedLayer.once('postrender', () => {
          if (mapState.shouldCenter) {
            const view = openLayersState.map.getView();
            view.fit(vectorSelectedSource.getExtent(), {duration: 200, padding: [40, 40, 40, 40]});
          }
        });

        openLayersState.map.addLayer(vectorLayer);
        openLayersState.map.addLayer(vectorSelectedLayer);
        loadingDispatch({type: REDUCER_LOADING_ACTIONS.REMOVE_LAYER, payload: layer.id});

        return () => {
          openLayersState.map.removeLayer(vectorLayer);
          openLayersState.map.removeLayer(vectorSelectedLayer);
        };
      } catch (err) {
        console.log(err);
        loadingDispatch({type: REDUCER_LOADING_ACTIONS.REMOVE_LAYER, payload: layer.id});
      }
    }
  }, [
    openLayersState.map,
    geojson,
    layer,
    mapState.searchSelected,
    mapState.filteredGeometries,
    loadingDispatch,
    mapState.shouldCenter,
  ]);

  return null;
};

export default GeoJsonLayer;
