import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {SessionContext} from 'contexts/SessionContext';
import {useContext} from 'react';
import styled from 'styled-components';
import {UiLoading} from '../../../../components/UiLoading';
import {useGet} from '../../../../hooks/useGet';
import {ArquivosSection} from '../ArquivosSection';
import InfosComplementares from '../InfosComplementares';
import InfosDatas from '../InfosDatas';
import InfosDocumentosPessoais from '../InfosDocumentosPessoais';
import InfosEdificacoes from '../InfosEdificacoes';
import InfosEndereco from '../InfosEndereco';
import InfosGenericas from '../InfosGenericas';
import InfosGeodesico from '../InfosGeodesico';
import InfosLogradouros from '../InfosLogradouros';
import InfosPlanoDiretor from '../InfosPlanoDiretor';
import InfosPublicas from '../InfosPublicas';
import InfosTerreno from '../InfosTerreno';
import InfosValores from '../InfosValores';
import {sessoes} from './sessoes';
import {UiSectionButtons} from './UiSectionButtons';
import {UiSectionChooser} from './UiSectionChooser';

const InfosSection = () => {
  const {sessionState} = useContext(SessionContext);
  const {openLayersState} = useContext(OpenLayersContext);
  const {mapState} = useContext(MapContext);
  const {appConfigState} = useContext(AppContext);

  const infos = useGet(
    `/infos/${mapState.selectedGeometry}?cidade=${appConfigState.idCidadeSelecionada}&camada=${mapState.selectedLayerForGeometry}`,
    undefined,
    undefined,
    mapState.selectedGeometry !== undefined && mapState.selectedGeometry !== ''
  );

  if (sessionState.token) {
    sessoes['VALORES'] = 'Valores';
    sessoes['ARQUIVOS'] = 'Arquivos';
  }

  const renderDocumentoPessoais = () => <InfosDocumentosPessoais infos={infos.data.infos} />;
  const renderValores = () => <InfosValores infos={infos.data.infos} />;
  const renderInformacoesTerreno = () => <InfosTerreno infos={infos.data.infos} />;
  const renderInformacoesPublicas = () => <InfosPublicas infos={infos.data.infos} />;
  const renderEndereco = () => <InfosEndereco infos={infos.data.infos} />;
  const renderInformacoesComplementares = () => <InfosComplementares infos={infos.data.infos} />;
  const renderEdificacoes = () => <InfosEdificacoes infos={infos.data.infos} />;
  const renderDatas = () => <InfosDatas infos={infos.data.infos} />;
  const renderLogradouroInfos = () => <InfosLogradouros infos={infos.data.infos} />;
  const renderPlanoDiretor = () => <InfosPlanoDiretor infos={infos.data.infos} />;
  const renderInformacoesGenericas = () => <InfosGenericas infos={infos.data.infos} />;
  const renderGeodesicoInfos = () => <InfosGeodesico infos={infos.data.infos} />;
  const renderArquivosSection = () => <ArquivosSection infos={infos.data.infos} />;

  const renderInfos = () => {
    if (infos.data?.tipoCamada === 'logradouro') {
      return renderLogradouroInfos();
    }

    if (infos.data?.tipoCamada === 'geodesico') {
      return renderGeodesicoInfos();
    }

    if (infos.data?.tipoCamada === 'lote' || infos.data?.tipoCamada === 'edificacao') {
      switch (mapState.selectedInfoSession) {
        case 'DOCUMENTOS_PESSOAIS':
          return renderDocumentoPessoais();
        case 'INFORMACOES_COMPLEMENTARES':
          return renderInformacoesComplementares();
        case 'DATAS':
          return renderDatas();
        case 'EDIFICACAO':
          return renderEdificacoes();
        case 'ENDERECO':
          return renderEndereco();
        case 'INFORMACOES_PUBLICA':
          return renderInformacoesPublicas();
        case 'INFORMACOES_TERRENO':
          return renderInformacoesTerreno();
        case 'INFORMACOES_GENERICA':
          return renderInformacoesGenericas();
        case 'PLANO_DIRETOR':
          return renderPlanoDiretor();
        case 'ARQUIVOS':
          return renderArquivosSection();
        default:
          return renderValores();
      }
    }
  };

  const shoudlOpenInfos = () =>
    mapState.selectedGeometry !== '' &&
    (infos.data?.tipoCamada === 'lote' ||
      infos.data?.tipoCamada === 'logradouro' ||
      infos.data?.tipoCamada === 'geodesico' ||
      infos.data?.tipoCamada === 'edificacao');

  if (!shoudlOpenInfos() || openLayersState.activeDrawTool || openLayersState.deletingGeometry)
    return null;

  if (infos.loading) {
    return (
      <InfosSectionOpened>
        <UiLoading />
      </InfosSectionOpened>
    );
  }

  return (
    <InfosSectionOpened>
      <UiSectionChooser infos={infos} />
      <Infos> {renderInfos()} </Infos>
      <UiSectionButtons infos={infos} />
    </InfosSectionOpened>
  );
};

const InfosSectionOpened: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

const Infos: any = styled.div`
  margin: 0 20px;
  overflow: auto;
  flex-grow: 1;
`;

export default InfosSection;
