import UiButton from 'components/UiButton';
import UiFlex from 'components/UiFlex';
import UiModalYesNo from 'components/UiModalYesNo';
import UiBox from 'components/UiPanel';
import UiTable from 'components/UiTable';
import UiText from 'components/UiText';
import {SessionContext} from 'contexts/SessionContext';
import {useContext, useState} from 'react';
import {MdDelete, MdEdit} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import {API} from 'utils/api';
import {COLOR} from 'utils/colors';
import {UiLoading} from '../../../components/UiLoading';
import {AppContext} from '../../../contexts/AppContext';
import {useGet} from '../../../hooks/useGet';
import {renderDate} from '../../../utils/utils';
import RegisterPage from '../index';

export const UsuariosPage = () => {
  const [update, updateUsers] = useState(1);
  const [deletingUsuario, setDeletingUsuario] = useState<any>();
  const {sessionState, headers: headersReq} = useContext(SessionContext);
  const {appConfigState} = useContext(AppContext);
  const navigate = useNavigate();

  const {data: usuarios} = useGet(
    `/usuarios?cidade=${appConfigState.idCidadeSelecionada}`,
    headersReq,
    undefined,
    update
  );

  const columnEditTable = (e: any) => {
    if (!sessionState.usuario.permissoes?.postUsuarios) return null;

    return (
      <UiFlex justifyContent={'space-around'}>
        <MdEdit
          cursor="pointer"
          color={COLOR.WARNING}
          size="1.3em"
          onClick={() => {
            navigate('/cadastros/usuarios/editar', {state: e});
          }}
        />
        <MdDelete
          cursor="pointer"
          color={COLOR.DANGER}
          size="1.3em"
          onClick={() => {
            setDeletingUsuario(e);
          }}
        />
      </UiFlex>
    );
  };

  const renderNome = (_: string, row: any) => `${row.primeiroNome} ${row.ultimoNome}`;

  const headers: any = [
    {title: 'Usuário', field: 'usuario'},
    {title: 'Nome', field: 'primeiroNome', applyRender: renderNome},
    {title: 'Ultimo acesso', field: 'ultimoAcesso', applyRender: renderDate},
  ];

  if (sessionState.usuario.permissoes?.postUsuarios) {
    headers.push({
      title: '',
      actions: columnEditTable,
      width: '50px',
      align: 'center',
    });
  }

  const renderTable = () =>
    !usuarios ? <UiLoading /> : <UiTable rows={usuarios} headers={headers} />;

  const handleDeleteUsuario = async () => {
    try {
      await API.deleteUsuario(appConfigState.idCidadeSelecionada, deletingUsuario.id, headersReq);
      updateUsers(Math.random());
      setDeletingUsuario(undefined);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <RegisterPage>
      <UiFlex direction={'column'}>
        <UiBox overflow="auto" maxHeight="calc(100% - 177px)">
          <UiFlex direction="column">
            <UiFlex
              alignItems="center"
              justifyContent={'space-between'}
              margin={'6px 10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText bold size={'1.2em'}>
                Usuários
              </UiText>
              {sessionState.usuario.permissoes?.postUsuarios && (
                <UiButton small primary onClick={() => navigate('/cadastros/usuarios/adicionar')}>
                  Adicionar usuário
                </UiButton>
              )}
            </UiFlex>
            {renderTable()}
          </UiFlex>
        </UiBox>
        <UiModalYesNo
          title="Excluir usuário"
          show={deletingUsuario}
          body={`Deseja realmente excluir o usuário ${deletingUsuario?.usuario}?`}
          onNo={() => setDeletingUsuario(undefined)}
          onYes={handleDeleteUsuario}
        />
      </UiFlex>
    </RegisterPage>
  );
};
