import {UiInfo} from "components/UiInfo";

const InfosEdificacoes = ({infos}: any) => (
    <>
        <UiInfo label="Acabamento" value={infos["ACABAMENTO"]}/>
        <UiInfo label="Apartamento terreno" value={infos["APARTAMENTO_TERRENO"]}/>
        <UiInfo label="Apartamento contribuinte" value={infos["APARTAMENTO_CONTRIBUINTE"]}/>
        <UiInfo label="Área Construída da Unidade" value={infos["AREA_CONSTRUIDA_UNIDADE"]} area/>
        <UiInfo label="Área Total Construção Rural" value={infos["AREA_TOTAL_CONSTRUCAO_RURAL"]} area/>
        <UiInfo label="Área Total Construída" value={infos["AREA_TOTAL_CONSTRUIDA"]} area/>
        <UiInfo label="Área tributavel" value={infos["AREA_TRIBUTAVEL"]} area/>
        <UiInfo label="Característica" value={infos["CARACTERISTICA"]}/>
        <UiInfo label="Caracterização" value={infos["CARACTERIZACAO"]}/>
        <UiInfo label="Cobertura" value={infos["COBERTURA"]}/>
        <UiInfo label="Condominio Terreno" value={infos["CONDOMINIO_TERRENO"]}/>
        <UiInfo label="Condominio Contribuinte" value={infos["CONDOMINIO_CONTRIBUINTE"]}/>
        <UiInfo label="COZINHA" value={infos["COZINHA"]}/>
        <UiInfo label="DELIMITAÇÕES" value={infos["DELIMITACOES"]}/>
        <UiInfo label="ESPECIE" value={infos["ESPECIE"]}/>
        <UiInfo label="Esquadrias" value={infos["ESQUADRIAS"]}/>
        <UiInfo label="Estado de Conservação" value={infos["ESTADO_CONSERVACAO"]}/>
        <UiInfo label="Estado de Conservação Pavimento" value={infos["ESTADO_CONSERVACAO_PAVIMENTO"]}/>
        <UiInfo label="Estrutura" value={infos["ESTRUTURA"]}/>
        <UiInfo label="FACE" value={infos["FACE"]}/>
        <UiInfo label="Fator localização" value={infos["FATOR_LOCALIZACAO"]}/>
        <UiInfo label="Forro" value={infos["FORRO"]}/>
        <UiInfo label="FUNDAÇÕES" value={infos["FUNCACOES"]}/>
        <UiInfo label="GABINETE SANITARIO" value={infos["GABINETE_SANITARIO"]}/>
        <UiInfo label="Instalação Elétrica" value={infos["INSTALACAO_ELETRICA"]}/>
        <UiInfo label="Instalação Sanitária" value={infos["INSTALACAO_SANITARIA"]}/>
        <UiInfo label="Intalação Sanitária Ligada" value={infos["INSTALACAO_SANITARIA_LIGADA"]}/>
        <UiInfo label="Laudo" value={infos["LAUDO"]}/>
        <UiInfo label="Limitação" value={infos["LIMITACAO"]}/>
        <UiInfo label="Limite Testada" value={infos["LIMITE_TESTADA"]}/>
        <UiInfo label="Locação" value={infos["LOCACAO"]}/>
        <UiInfo label="Localização" value={infos["LOCALIZACAO"]}/>
        <UiInfo label="Loteamento Terreno" value={infos["LOTEAMENTO_TERRENO"]}/>
        <UiInfo label="Murado" value={infos["MURADO"]}/>
        <UiInfo label="MURO" value={infos["MURO"]}/>
        <UiInfo label="NIVEL DA RUA" value={infos["NIVEL_RUA"]}/>
        <UiInfo label="Nome Condomínio" value={infos["NOME_CONDOMINIO"]}/>
        <UiInfo label="Número Pavimentação" value={infos["NUMER0_PAVIMENTACAO"]}/>
        <UiInfo label="Nro de Pavimentos" value={infos["NUMERO_PAVIMENTOS"]}/>
        <UiInfo label="Número de Testada Convertidas" value={infos["NUMERO_TESTADA_CONVERTIDA"]}/>
        <UiInfo label="Número de Unidades" value={infos["NUMERO_UNIDADES"]}/>
        <UiInfo label="Ocupação" value={infos["OCUPACAO"]}/>
        <UiInfo label="Padrão Construção" value={infos["PADRAO_CONSTRUCAO"]}/>
        <UiInfo label="Paredes" value={infos["PAREDES"]}/>
        <UiInfo label="Patrimônio" value={infos["PATRIMONIO"]}/>
        <UiInfo label="Pintura" value={infos["PINTURA"]}/>
        <UiInfo label="Piscina" value={infos["PISCINA"]}/>
        <UiInfo label="Piso" value={infos["PISO"]}/>
        <UiInfo label="PONTUAÇÃO" value={infos["PONTUACAO"]}/>
        <UiInfo label="Posição" value={infos["POSICAO"]}/>
        <UiInfo label="Posicionamento" value={infos["POSICIONAMENTO"]}/>
        <UiInfo label="REVESTIMENTO" value={infos["REVESTIMENTO"]}/>
        <UiInfo label="Revestimento Externo" value={infos["REVESTIMENTO_EXTERNO"]}/>
        <UiInfo label="Revestimento Interno" value={infos["REVESTIMENTO_INTERNO"]}/>
        <UiInfo label="Situação" value={infos["SITUACAO"]}/>
        <UiInfo label="SITUAÇÃO EDIFICAÇÃO" value={infos["SITUACAO_EDIFICACAO"]}/>
        <UiInfo label="Situação da Unidade" value={infos["SITUACAO_UNIDADE"]}/>
        <UiInfo label="Status" value={infos["STATUS"]}/>
        <UiInfo label="Telefone" value={infos["TELEFONE"]}/>
        <UiInfo label="TIPO EDIFICAÇÃO" value={infos["TIPO_EDIFICACAO"]}/>
        <UiInfo label="Tipo de Ficha" value={infos["TIPO_FICHA"]}/>
        <UiInfo label="TRECHO 1" value={infos["TRECHO1"]}/>
        <UiInfo label="TRIANGULO" value={infos["TRIANGULO"]}/>
        <UiInfo label="Unidade" value={infos["UNIDADE"]}/>
        <UiInfo label="Unidade Medida" value={infos["UNIDADE_MEDIDA"]}/>
        <UiInfo label="UNIDADES CONSTRUÍDAS" value={infos["UNIDADES_CONSTRUIDAS"]}/>
        <UiInfo label="Usa o Imóvel" value={infos["USA_IMOVEL"]}/>
        <UiInfo label="Utilização" value={infos["UTILIZACAO"]}/>
        <UiInfo label="Utilização do Imóvel" value={infos["UTILIZACAO_IMOVEL"]}/>
        <UiInfo label="Vedação / Esquadrias" value={infos["VEDACAO_ESQUADRIAS"]}/>
    </>
)

export default InfosEdificacoes;
