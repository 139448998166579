import styled from 'styled-components';

export const ButtonControl = ({onClick, show, text}: any) => {
  if (!show) return null;

  return (
    <Button primary onClick={onClick}>
      {text}
    </Button>
  );
};

const Button: any = styled.button<any>`
  background-color: DarkOrange;
  border: 1px solid DarkOrange;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px 10px;
`;
