import {useEffect, useState} from "react";

export const useFile = (isPicking: boolean, accept: string, multiple?: boolean) => {
    const [files, setFiles] = useState<any[]>()

    useEffect(() => {
        const onChange = (event: any) => {
            if (!event.target.files.length) {
                return
            }

            setFiles(event.target.files)
        }

        if (isPicking) {
            let filePicker = document.createElement('input')
            filePicker.setAttribute("type", "file")
            filePicker.setAttribute("accept", accept)
            if (multiple) {
                filePicker.setAttribute("multiple", "true")
            }
            filePicker.addEventListener("change", onChange)
            filePicker.click()

            return () => {
                filePicker.remove()
                setFiles([])
            }
        }
    }, [isPicking, accept, multiple])

    return files
}
