import UiFlex from "components/UiFlex";
import {UiIconButton} from "components/UiIconButton";
import UiPaginator from "components/UiPaginator";
import UiBox from "components/UiPanel";
import UiTable from "components/UiTable";
import UiText from "components/UiText";
import React, {useContext, useEffect, useState} from "react";
import {FaEye} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import {SessionContext} from "contexts/SessionContext";
import {AppContext} from "../../../contexts/AppContext";
import {renderArea, renderMoney} from "../../../utils/utils";
import {UiLoading} from "../../../components/UiLoading";
import {useNavigate} from "react-router-dom";
import {useGet} from "../../../hooks/useGet";
import RegisterPage from "../index";

export const EdificacoesPage = () => {
    const [offset, setOffset] = useState(0);
    const [limit] = useState(Math.floor((window.innerHeight - 206) / 34));
    const [pageCount, setPageCount] = useState(0);
    const [actualPage, setActualPage] = useState(0);
    const {sessionState, headers: headersReq} = useContext(SessionContext);
    const {appConfigState} = useContext(AppContext)
    const navigate = useNavigate()

    const {data: pageCountReq} = useGet(`/edificacoes/count?cidade=${appConfigState.idCidadeSelecionada}`,
        headersReq)
    const {data: edificacoes} = useGet(`/edificacoes?cidade=${appConfigState.idCidadeSelecionada}&skip=${offset}&limit=${limit}&filter=`,
        headersReq, undefined, pageCount)

    useEffect(() => {
        if (pageCountReq) setPageCount(Math.ceil(pageCountReq.count / limit));
    }, [pageCountReq, limit]);

    useEffect(() => {
        setOffset(actualPage * limit);
    }, [actualPage, limit]);

    const renderActions = (e: any) => {
        return (
            <>
                <FaEye size="1.2em" color="#099cec" cursor="pointer" onClick={() => {
                    navigate('/', {
                        state: {...e, tipoCamada: "edificacao"},
                    });
                }}/>
                {sessionState.permissoes?.postEdificacoes ? (
                    <UiIconButton icon={MdEdit} size="1.2em" color="warning" onClick={() => {
                        navigate("/cadastros/edificacoes/editar", {
                            state: e,
                        });
                    }}/>
                ) : null}
            </>
        );
    };

    const headers = [
        {title: "Inscrição Imobiliaria", field: "idGeometria"},
        {title: "Area", field: "AREA", applyRender: renderArea},
        {title: "Nome do proprietário", field: "CONTRIBUINTE"},
        {title: "CPF/CNPJ", field: "CPF_CNPJ"},
        {title: "Endereço", field: "LOGRADOURO"},
        {title: "Valor Venal", field: "VALOR_VENAL_IMOVEL", applyRender: renderMoney},
        {actions: renderActions},
    ];

    const handleChangePage = (e: any) => setActualPage(e)
    const renderTable = () => !edificacoes ? <UiLoading/> :
        <UiTable rows={edificacoes} headers={headers} noDataText={"Nenhuma edificação cadastrada"}/>;

    return (
        <RegisterPage>
            <UiFlex direction={"column"}>
                <UiBox>
                    <UiFlex alignItems="center" justifyContent={"space-between"} margin={"5px 10px"}
                            width={"calc(100% - 20px)"}>
                        <UiText bold size={"1.2em"}>Edificações</UiText>
                    </UiFlex>
                    {renderTable()}
                </UiBox>
                {edificacoes?.length > 0 && (
                    <UiPaginator totalCount={pageCount} onChangePage={handleChangePage} actualPage={actualPage}/>
                )}
            </UiFlex>
        </RegisterPage>
    );
};
