import axios from 'axios';
import UiButton from 'components/UiButton';
import UiFlex from 'components/UiFlex';
import UiText from 'components/UiText';
import {useContext, useState} from 'react';
import styled from 'styled-components';
import UiInputText from '../components/UiInputText';
import {AppContext} from '../contexts/AppContext';
import {MapContext} from '../contexts/MapContext';
import {SessionContext} from '../contexts/SessionContext';
import {API_URL} from '../utils/api';

const UiModalYesNo = ({onClose, show, tipo}: any) => {
  const {headers} = useContext(SessionContext);
  const {mapState} = useContext(MapContext);
  const {appConfigState} = useContext(AppContext);
  const [inscricaoExiste, setInscricaoExiste] = useState(false);
  const [novaInscricao, setNovaInscricao] = useState('');
  const [loadingInfos, setLoadingInfos] = useState(false);

  const handleUpdateInscricao = async () => {
    setLoadingInfos(true);

    try {
      await axios.get(
        `${API_URL}/infos/${novaInscricao}?cidade=${appConfigState.idCidadeSelecionada}&camada=${mapState.selectedLayerForGeometry}`
      );
      setInscricaoExiste(true);
    } catch (e) {
      setInscricaoExiste(false);
      try {
        await axios.patch(
          `${API_URL}/${tipo}/${mapState.selectedGeometry}?cidade=${appConfigState.idCidadeSelecionada}`,
          {idGeometria: novaInscricao},
          {headers}
        );
        onClose();
      } catch (e) {
        console.log(e);
      }
    } finally {
      setLoadingInfos(false);
    }
  };

  if (!show) return null;

  return (
    <Modal>
      <Container>
        <UiFlex>
          <UiText bold size="1.1em">
            Atualizar inscrição
          </UiText>
        </UiFlex>
        <UiFlex margin={'20px 0'}>
          <UiText>
            Alterar a inscrição da geometria atualizará também todos os arquivos e dados associados.
          </UiText>
        </UiFlex>
        <UiFlex margin={'20px 0'}>
          <UiInputText
            label={'Nova inscrição'}
            noMargin
            error={inscricaoExiste}
            errorMsg={'A nova inscrição não pode ser igual a uma já existente'}
            value={novaInscricao}
            onChange={(e: any) => setNovaInscricao(e.target.value.replaceAll(' ', ''))}
            onKeyUp={() => setInscricaoExiste(false)}
          />
        </UiFlex>
        <UiFlex justifyContent="flex-end">
          <UiButton secondary onClick={onClose}>
            Cancelar
          </UiButton>
          <Spacer />
          <UiButton warning onClick={handleUpdateInscricao} isLoading={loadingInfos}>
            Atualizar
          </UiButton>
        </UiFlex>
      </Container>
    </Modal>
  );
};

const Spacer: any = styled.div`
  margin: 0 6px;
`;

const Modal: any = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  align-items: center;
  background-color: #f7f8f9cc;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

const Container: any = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  width: 400px;
`;

export default UiModalYesNo;
