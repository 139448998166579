import UiButton from "components/UiButton";
import UiFlexItem from "components/UiFlex";
import UiModalYesNo from "components/UiModalYesNo";
import UiBox from "components/UiPanel";
import UiText from "components/UiText";
import React, {useContext, useEffect, useState} from "react";
import {SessionContext} from "contexts/SessionContext";
import {API} from "utils/api";
import {humanReadableSize} from "utils/utils";
import {AppContext} from "../../../contexts/AppContext";
import {useGet} from "../../../hooks/useGet";

const CacheOrtoimagens = () => {
    const [size, setSize] = useState('');
    const [shouldUpdate, setShouldUpdate] = useState(1);
    const [isCleaningCache, cleanCache] = useState(false);
    const {sessionState, headers: headersReq} = useContext(SessionContext);
    const {appConfigState} = useContext(AppContext)

    const {data} = useGet(`/cidade/${appConfigState.idCidadeSelecionada}/cacheSize`, headersReq,
        undefined, shouldUpdate)

    useEffect(() => {
        if (data?.size) {
            setSize(data.size)
        } else {
            setSize(`-`)
        }
    }, [data]);

    const handleCleanCache = async () => {
        await API.cleanCache(appConfigState.idCidadeSelecionada, headersReq);
        setShouldUpdate(Math.random());
        cleanCache(false);
    };

    return (
        <>
            <UiBox width={"700px"}>
                <UiFlexItem direction="column">
                    <UiText bold>Cache de ortoimagens</UiText>
                    <UiFlexItem justifyContent="center" margin="16px 0">
                        <UiText italic bold color={"gray"} size={"1.7em"}>
                            {size === `-` ? '-' : humanReadableSize(size)}
                        </UiText>
                    </UiFlexItem>
                    <UiFlexItem justifyContent="flex-end" margin="10px 10px 0 0" width={"calc(100% - 10px)"}>
                        <UiButton primary onClick={() => cleanCache(true)} small
                                  disabled={!sessionState.usuario?.permissoes?.clearOrtoCache}>
                            Limpar cache
                        </UiButton>
                    </UiFlexItem>
                </UiFlexItem>
            </UiBox>
            <UiModalYesNo title="Limpar cache" show={isCleaningCache} onNo={() => {
                cleanCache(false);
            }} onYes={handleCleanCache} body="Deseja continuar com a limpeza do cache das ortoimagens?"/>
        </>
    );
};

export default CacheOrtoimagens;
