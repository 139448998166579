import UiFlexItem from 'components/UiFlex';
import UiPanel from 'components/UiPanel';
import UiText from 'components/UiText';
import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorLayer} from 'ol/layer';
import OLMap from 'ol/Map';
import {Vector as VectorSource} from 'ol/source';
import View from 'ol/View';
import {useEffect} from 'react';
import styled from 'styled-components';

const LimitVisualizer = ({geoJson}: any) => {
  useEffect(() => {
    if (geoJson) {
      let vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(JSON.parse(geoJson)),
      });

      let vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      let m: any = new OLMap({
        layers: [vectorLayer],
        target: 'previewMap',
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
      });

      let view = m.getView();
      view.fit(vectorSource.getExtent());

      return () => {
        m.setTarget();
        m = null;
      };
    }
  }, [geoJson]);

  return (
    <UiPanel>
      <UiFlexItem direction="column">
        <UiFlexItem alignItems="center">
          <UiFlexItem>
            <UiText bold>Visualização da camada de limites</UiText>
          </UiFlexItem>
        </UiFlexItem>
        <Map id="previewMap" />
      </UiFlexItem>
    </UiPanel>
  );
};

const Map: any = styled.div`
  height: 50vh;
  width: 100%;
`;

export default LimitVisualizer;
