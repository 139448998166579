import Map from 'ol/Map'
import html2canvas from 'html2canvas'
import {Collection} from "ol";
import {DateTime} from "luxon";

export const getPrintFromMap = async (map: Map): Promise<HTMLCanvasElement> => {
    const myCanvas = document.createElement("canvas")
    const size = map.getSize()
    if (!size) {
        return new Promise<HTMLCanvasElement>(() => {
        })
    }
    myCanvas.width = size[0]
    myCanvas.height = size[1]

    const mapContext = myCanvas.getContext('2d')
    if (!mapContext) {
        return new Promise<HTMLCanvasElement>(() => {
        })
    }

    const elements: HTMLCanvasElement[] = Array.from(document.querySelectorAll('.ol-layer canvas'))
    elements.forEach((canvas) => {
        if (canvas.width > 0) {
            mapContext.drawImage(canvas, 0, 0);
        }
    })

    const legenda: HTMLElement[] = Array.from(document.querySelectorAll('#legenda'))
    const images = legenda.map(el => html2canvas(el, {logging: false}))

    await Promise.all(images).then((res) => {
        res.forEach(canvas => {
            if (canvas.width > 0) {
                mapContext.drawImage(canvas, myCanvas.width - canvas.width - 10, myCanvas.height - canvas.height - 10);
            }
        })
    })

    return myCanvas
}

export const getSourceForLastLayer = (layers: Collection<any>) => {
    let source: any = layers.item(0).getSource()
    let now = DateTime.now().toMillis()
    layers.forEach((e) => {
        let activateTime = e.get('ACTIVATE_TIME')
        if (activateTime < now) {
            source = e.getSource()
        }
    })
    return source
}
