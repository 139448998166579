import UiBox from "../../../components/UiPanel";
import UiFlex from "../../../components/UiFlex";
import UiText from "../../../components/UiText";
import {UiListFiles} from "../../../components/UiListFiles";
import {FaFolderPlus, FaUpload} from "react-icons/fa";
import React, {useContext, useEffect, useState} from "react";
import {useFile} from "../../../hooks/useFile";
import {API} from "../../../utils/api";
import {AppContext} from "../../../contexts/AppContext";
import {REDUCER_FILE_UPLOAD_ACTIONS} from "../../../reducers/fileupload";
import {FileUploadContext} from "../../../contexts/FileUploadContext";
import {SessionContext} from "../../../contexts/SessionContext";
import {ModalAskInfo} from "../../../modals/ModalAskInfo";
import RegisterPage from "../index";

export const ArquivosPage = () => {

    const {appConfigState} = useContext(AppContext)
    const {fileUploadDispatch} = useContext(FileUploadContext)
    const {headers: headersReq} = useContext(SessionContext)
    const [path, setPath] = useState('/')
    const [update, setUpdate] = useState(Math.random())
    const [showModalFolderName, askFolderName] = useState(false)
    const [folderName, setFolderName] = useState('')

    const [pickingImages, setPickingImages] = useState(false)
    const files = useFile(pickingImages, ".jpg,.pdf", true)

    useEffect(() => {
        const handleUploadProgress = (id: string, loaded: number, total: number) => {
            fileUploadDispatch({type: REDUCER_FILE_UPLOAD_ACTIONS.UPDATE_PROGRESS, payload: {id, loaded, total}})
        };

        const uploadFiles = async () => {
            if (files?.length) {
                const filesToSend = [...files]

                filesToSend.forEach((f) => {
                    try {
                        const id = Math.random()
                        fileUploadDispatch({
                            type: REDUCER_FILE_UPLOAD_ACTIONS.ADD_TO_ENVIO, payload: {
                                id: `${id}`,
                                loaded: 0,
                                total: f.size,
                                status: "progress",
                                filename: f.name,
                            }
                        })
                        const fd = new FormData()
                        fd.append("file", f)
                        API.overwriteFile(fd, `${path}/${f.name}`, appConfigState.idCidadeSelecionada,
                            ({loaded, total}: any) => handleUploadProgress(`${id}`, loaded, total),
                            headersReq).then(() => {
                            setUpdate(Math.random())
                        })
                    } catch (e) {
                        console.log(e)
                    }
                })

                setPickingImages(false)
            }
        }

        uploadFiles().then()
    }, [files, appConfigState.idCidadeSelecionada, fileUploadDispatch, headersReq, path])

    const createFolder = async () => {
        await API.createFolder(`${path}/${folderName}`, appConfigState.idCidadeSelecionada, headersReq)
        setUpdate(Math.random())
        askFolderName(false)
        setFolderName('')
    }


    return (
        <RegisterPage>
            <UiFlex direction={"column"}>
                <UiBox>
                    <UiFlex alignItems="center" justifyContent={"space-between"} margin={"6px 10px"}
                            width={"calc(100% - 20px)"}>
                        <UiText bold size={"1.2em"}>Arquivos</UiText>
                        <UiFlex justifyContent={"space-between"} width={"50px"} grow={'0'}>
                            <FaFolderPlus onClick={() => askFolderName(true)} title={"Criar pasta"} size={"17px"}
                                          color={'#099cec'} cursor={"pointer"}/>
                            <FaUpload onClick={() => setPickingImages(true)} title={"Importar arquivos"} size={"17px"}
                                      color={'#099cec'} cursor={"pointer"}/>
                        </UiFlex>
                    </UiFlex>
                    <UiFlex margin={"5px 10px"} width={"calc(100% - 20px)"}>
                        <UiListFiles path={path} onChangePath={setPath} shouldUpdate={update}/>
                    </UiFlex>
                </UiBox>
                <ModalAskInfo show={showModalFolderName} onClose={() => askFolderName(false)} data={folderName}
                              onChangeData={setFolderName} title={"Nome da pasta"} continueLabel={"Criar pasta"}
                              cancelLabel={"Cancelar"} continueAction={createFolder}/>
            </UiFlex>
        </RegisterPage>
    )
}
