import styled from 'styled-components';

const UiInputPassword = ({
  label,
  value,
  onChange,
  disabled,
  autoFocus,
  name,
  placeholder,
  onKeyUp,
  error,
  errorMsg,
}: any) => {
  return (
    <Container
      onClick={(ev: any) => {
        ev.stopPropagation();
      }}
    >
      {label && <Label>{label}</Label>}
      <Input
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        name={name}
        placeholder={placeholder}
        autocomplete="off"
        onKeyUp={onKeyUp}
      />
      {error && <Error>{errorMsg}</Error>}
    </Container>
  );
};

const Container: any = styled.div`
  color: #222;
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: calc(100% - 20px);
`;

const Label: any = styled.label`
  font-size: 0.8em;
  margin-bottom: 4px;
`;

const Error: any = styled.div`
  font-size: 0.7em;
  margin-top: 4px;
  color: red;
`;

const Input: any = styled.input.attrs({type: 'password'})<any>`
  border: 1px solid #ddd;
  padding: 8px;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'normal')};

  &:focus {
    border: 1px solid #099cec;
  }
`;

export default UiInputPassword;

