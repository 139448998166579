import {useEffect} from "react";

const useOutsideClick = (ref: any, callback: any, needCreate: boolean) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback && callback();
            }
        };

        if (needCreate) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, callback, needCreate]);
};

export default useOutsideClick;
