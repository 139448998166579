import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {useContext, useRef} from 'react';
import {FaEye} from 'react-icons/fa';
import {Transition} from 'react-transition-group';
import styled from 'styled-components';
import useOutsideClick from 'utils/useOutsideClick';
import {REDUCER_MAP_ACTIONS} from '../../../../reducers/map';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../../reducers/openlayers';

const duration = 200;

const ThematicMapToolbarModal = () => {
  const {mapState, mapDispatch} = useContext(MapContext);
  const {openLayersState, openLayersDispatch} = useContext(OpenLayersContext);
  const component = useRef();

  useOutsideClick(
    component,
    () => openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_THEMATIC_MAP_SECTION}),
    openLayersState.showThematicMapSection
  );

  const selectMapaTematico = (e: any) => {
    e.id !== mapState.selectedThematicMap?.id &&
      openLayersDispatch({
        type: REDUCER_OPENLAYERS_ACTIONS.ADD_LAYER_LOADING,
        payload: e,
      });
    e.id === mapState.selectedThematicMap?.id
      ? mapDispatch({type: REDUCER_MAP_ACTIONS.CLEAR_THEMATIC_MAP})
      : mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_THEMATIC_MAP, payload: e});
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_THEMATIC_MAP_SECTION});
  };

  return (
    <Transition in={openLayersState.showThematicMapSection} timeout={duration}>
      {state => (
        <Container ref={component} type={mapState.sidebarType} state={state}>
          {mapState.thematicMaps?.map((e: any) => (
            <Layer key={e.id} onClick={() => selectMapaTematico(e)}>
              <EyeIcon>
                <FaEye color={e.id === mapState.selectedThematicMap?.id ? '#099cec' : 'gray'} />
              </EyeIcon>
              <LayerName>{e.nome}</LayerName>
            </Layer>
          ))}
        </Container>
      )}
    </Transition>
  );
};

const Container: any = styled.div<any>`
  display: flex;
  position: absolute;
  left: ${({type}) => (type === 'out' ? '43px' : '68px')};
  top: ${({type}) => (type === 'out' ? '250px' : '263px')};
  max-height: calc(100% - 100px);
  z-index: 1000000;

  flex-direction: column;
  background-color: #ffffffcc;
  backdrop-filter: blur(5px);
  border: 1px solid silver;
  box-shadow: 0 0 2px silver;

  transform: ${({state}) => (state === 'entered' ? 'scaleX(1)' : 'scaleX(0)')};
  opacity: ${({state}) => (state === 'entered' ? '1' : '0')};
  transition: transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out;
  transform-origin: left;
  overflow: auto;
`;

const Layer: any = styled.div`
  display: flex;
  padding: 10px;
  width: 250px;
  align-items: center;
  cursor: pointer;
`;

const EyeIcon: any = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
`;
const LayerName: any = styled.div``;

export default ThematicMapToolbarModal;
