import UiButton from 'components/UiButton';
import {default as UiFlex, default as UiFlexItem} from 'components/UiFlex';
import UiBox from 'components/UiPanel';
import UiSelect from 'components/UiSelect';
import {AppContext} from 'contexts/AppContext';
import {useContext, useState} from 'react';
import {REDUCER_APP_CONFIG_ACTIONS} from 'reducers/appconfig';
import styled from 'styled-components';
import {GlobalStyle} from '../../GlobalStyle';

export const SelecaoCidadePage = () => {
  const {appConfigState, appConfigDispatch} = useContext(AppContext);
  const [cidade, setCidade] = useState('');

  const transformCidades = () => {
    const enabledCities = appConfigState.cidades?.filter(cid => cid.enabled);
    return enabledCities?.map((c: any) => ({key: c.id, value: c.nome}));
  };

  const handleSelectCidade = () =>
    appConfigDispatch({
      type: REDUCER_APP_CONFIG_ACTIONS.SET_ID_CIDADE_SELECIONADA,
      payload: cidade,
    });

  return (
    <Container>
      <UiFlex minHeight={'100%'} justifyContent={'center'} alignItems={'center'}>
        <UiBox width={'400px'}>
          <UiSelect
            label={'Selecione a cidade'}
            options={transformCidades()}
            placeholder="Selecione a cidade"
            value={cidade}
            onChange={(e: any) => setCidade(e.target.value)}
            selectText={'Selecione a cidade'}
          />
          <UiFlexItem margin="10px 10px 0 0" width="calc(100% - 10px)" justifyContent="flex-end">
            <UiButton disabled={cidade === ''} primary onClick={handleSelectCidade}>
              Avançar
            </UiButton>
          </UiFlexItem>
        </UiBox>
      </UiFlex>
      <GlobalStyle />
    </Container>
  );
};

const Container: any = styled.div`
  background-color: #eee;
  height: 100%;
`;
