import styled from 'styled-components';

const UiFlex = ({children, hidden, ...props}: any) => {
  if (hidden) return null;

  return <Flex {...props}>{children}</Flex>;
};

const Flex: any = styled.div<any>`
  align-items: ${({alignItems}) => alignItems || 'flex-start'};
  display: flex;
  flex-direction: ${({direction}) => direction || 'row'};
  flex-grow: ${({grow}) => grow || '1'};
  flex-wrap: ${({wrap}) => wrap || 'unset'};
  gap: ${({gap}) => gap || '0'};
  justify-content: ${({justifyContent}) => justifyContent || 'flex-start'};
  margin: ${({margin}) => margin || '0'};
  max-height: ${({maxHeight}) => maxHeight || '100%'};
  min-height: ${({minHeight}) => minHeight || 'auto'};
  max-width: ${({maxWidth}) => maxWidth || '100%'};
  min-width: ${({minWidth}) => minWidth || 'auto'};
  padding: ${({padding}) => padding || '0'};
  cursor: ${({cursor}) => cursor || 'default'};
  overflow: ${({overflow}) => overflow || 'none'};
  width: ${({width}) => width || '100%'};

  :hover {
    background-color: ${({hoverColor}) => hoverColor || 'transparent'};
  }

  @media (max-width: ${({breakpoint}) => breakpoint || '300px'}) {
    flex-direction: ${({direction}) => direction || 'column'};
    justify-content: ${({alignItems}) => alignItems || 'flex-start'};
    align-items: ${({justifyContent}) => justifyContent || 'flex-start'};
  }
`;

export default UiFlex;
