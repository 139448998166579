import styled from 'styled-components';

export const UiIconButton: any = ({icon, ...props}: any) => <Button {...props}>{icon()}</Button>;

const getColor = (props: any) => {
  if (props.disabled) return 'gray';
  if (props.color === 'danger') return 'DarkRed';
  if (props.color === 'warning') return 'DarkOrange';
  if (props.color === 'primary') return '#099cec';
  return props.color;
};

const Button: any = styled.button<any>`
  background-color: transparent;
  border: none;
  color: ${getColor};
  font-size: ${props => props.size || '1em'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: ${({margin}) => margin || '0'};
  padding: ${({noPadding}) => (noPadding ? '0px' : '5px')};
  outline: none;
`;
