import {IOpenLayersState, IReducerAction} from './IReducers';
import {Map, View} from 'ol';
import {Type} from 'ol/geom/Geometry';

export const REDUCER_OPENLAYERS_INITIAL: IOpenLayersState = {
  map: new Map({
    controls: [],
    view: new View({
      projection: 'EPSG:4326',
      center: [0, 0],
      zoom: 2,
      constrainResolution: true,
      enableRotation: false,
    }),
  }),
  showLayerSection: false,
  showBackgroundSection: false,
  showOrtoimageSection: false,
  showThematicMapSection: false,
  showMeasureSection: false,
  showDrawSection: false,
  showInfosAboutDraw: false,

  activeDrawTool: '' as Type,
  activeMeasureTool: '',

  coordenadas: {latitude: 0, longitude: 0},

  isRenderFinished: 0,
  isExportingMap: false,
  deletingGeometry: false,
  reloadLayers: 1,
  zoom: 2,
  deletedGeometries: [],
  loadingLayers: [],
};

export const REDUCER_OPENLAYERS_ACTIONS = {
  CLOSE_LAYERS_SECTION: 'CLOSE_LAYERS_SECTION',
  OPEN_LAYERS_SECTION: 'OPEN_LAYERS_SECTION',
  TOGGLE_LAYERS_SECTION: 'TOGGLE_LAYERS_SECTION',

  CLOSE_BACKGROUND_SECTION: 'CLOSE_BACKGROUND_SECTION',
  OPEN_BACKGROUND_SECTION: 'OPEN_BACKGROUND_SECTION',
  TOGGLE_BACKGROUND_SECTION: 'TOGGLE_BACKGROUND_SECTION',

  CLOSE_ORTOIMAGEM_SECTION: 'CLOSE_ORTOIMAGEM_SECTION',
  OPEN_ORTOIMAGEM_SECTION: 'OPEN_ORTOIMAGEM_SECTION',
  TOGGLE_ORTOIMAGEM_SECTION: 'TOGGLE_ORTOIMAGEM_SECTION',

  OPEN_THEMATIC_MAP_SECTION: 'OPEN_THEMATIC_MAP_SECTION',
  CLOSE_THEMATIC_MAP_SECTION: 'CLOSE_THEMATIC_MAP_SECTION',
  TOGGLE_THEMATIC_MAP_SECTION: 'TOGGLE_THEMATIC_MAP_SECTION',

  CLOSE_MEASURE_SECTION: 'CLOSE_MEASURE_SECTION',
  OPEN_MEASURE_SECTION: 'OPEN_MEASURE_SECTION',
  TOGGLE_MEASURE_SECTION: 'TOGGLE_MEASURE_SECTION',

  CLOSE_DRAW_SECTION: 'CLOSE_DRAW_SECTION',
  OPEN_DRAW_SECTION: 'OPEN_DRAW_SECTION',
  TOGGLE_DRAW_SECTION: 'TOGGLE_DRAW_SECTION',

  SHOW_INFOS_ABOUT_DRAW: 'SHOW_INFOS_ABOUT_DRAW',
  HIDE_INFOS_ABOUT_DRAW: 'HIDE_INFOS_ABOUT_DRAW',

  SET_DRAW_TOOL: 'SET_DRAW_TOOL',
  CLEAR_DRAW_TOOL: 'CLEAR_DRAW_TOOL',

  SET_MEASURE_TOOL: 'SET_MEASURE_TOOL',
  CLEAR_MEASURE_TOOL: 'CLEAR_MEASURE_TOOL',

  SET_DELETE_GEOMETRY: 'SET_DELETE_GEOMETRY',
  UNSET_DELETE_GEOMETRY: 'UNSET_DELETE_GEOMETRY',
  RESET_DELETE_GEOMETRY: 'RESET_DELETE_GEOMETRY',

  SET_COORDENADAS: 'SET_COORDENADAS',

  ADD_LAYER_LOADING: 'ADD_LAYER_LOADING',
  REMOVE_LAYER_LOADING: 'REMOVE_LAYER_LOADING',

  UPDATE_RENDER_FINISH: 'UPDATE_RENDER_FINISH',
  UPDATE_RELOAD_LAYERS: 'UPDATE_RELOAD_LAYERS',
  UPDATE_ZOOM: 'UPDATE_ZOOM',

  SET_EXPORT_MAP: 'SET_EXPORT_MAP',
  UNSET_EXPORT_MAP: 'UNSET_EXPORT_MAP',

  DELETE_GEOMETRY: 'DELETE_GEOMETRY',
  CLEAR_DELETED_GEOMETRY: 'CLEAR_DELETED_GEOMETRY',

  DISABLE_DRAW: 'DISABLE_DRAW',
};

export const openlayerReducer = (state: IOpenLayersState, action: IReducerAction) => {
  switch (action.type) {
    case REDUCER_OPENLAYERS_ACTIONS.CLOSE_LAYERS_SECTION:
      return {...state, showLayerSection: false};
    case REDUCER_OPENLAYERS_ACTIONS.OPEN_LAYERS_SECTION:
      return {...state, showLayerSection: true};
    case REDUCER_OPENLAYERS_ACTIONS.TOGGLE_LAYERS_SECTION:
      return {...state, showLayerSection: !state.showLayerSection};

    case REDUCER_OPENLAYERS_ACTIONS.CLOSE_BACKGROUND_SECTION:
      return {...state, showBackgroundSection: false};
    case REDUCER_OPENLAYERS_ACTIONS.OPEN_BACKGROUND_SECTION:
      return {...state, showBackgroundSection: true};
    case REDUCER_OPENLAYERS_ACTIONS.TOGGLE_BACKGROUND_SECTION:
      return {...state, showBackgroundSection: !state.showBackgroundSection};

    case REDUCER_OPENLAYERS_ACTIONS.CLOSE_ORTOIMAGEM_SECTION:
      return {...state, showOrtoimageSection: false};
    case REDUCER_OPENLAYERS_ACTIONS.OPEN_ORTOIMAGEM_SECTION:
      return {...state, showOrtoimageSection: true};
    case REDUCER_OPENLAYERS_ACTIONS.TOGGLE_ORTOIMAGEM_SECTION:
      return {...state, showOrtoimageSection: !state.showOrtoimageSection};

    case REDUCER_OPENLAYERS_ACTIONS.CLOSE_THEMATIC_MAP_SECTION:
      return {...state, showThematicMapSection: false};
    case REDUCER_OPENLAYERS_ACTIONS.OPEN_THEMATIC_MAP_SECTION:
      return {...state, showThematicMapSection: true};
    case REDUCER_OPENLAYERS_ACTIONS.TOGGLE_THEMATIC_MAP_SECTION:
      return {...state, showThematicMapSection: !state.showThematicMapSection};

    case REDUCER_OPENLAYERS_ACTIONS.CLOSE_MEASURE_SECTION:
      return {...state, showMeasureSection: false};
    case REDUCER_OPENLAYERS_ACTIONS.OPEN_MEASURE_SECTION:
      return {...state, showMeasureSection: true};
    case REDUCER_OPENLAYERS_ACTIONS.TOGGLE_MEASURE_SECTION:
      return {...state, showMeasureSection: !state.showMeasureSection};

    case REDUCER_OPENLAYERS_ACTIONS.CLOSE_DRAW_SECTION:
      return {...state, showDrawSection: false};
    case REDUCER_OPENLAYERS_ACTIONS.OPEN_DRAW_SECTION:
      return {...state, showDrawSection: true};
    case REDUCER_OPENLAYERS_ACTIONS.TOGGLE_DRAW_SECTION:
      return {...state, showDrawSection: !state.showDrawSection};

    case REDUCER_OPENLAYERS_ACTIONS.SET_DRAW_TOOL:
      return {...state, activeDrawTool: action.payload};
    case REDUCER_OPENLAYERS_ACTIONS.CLEAR_DRAW_TOOL:
      return {...state, activeDrawTool: ''};

    case REDUCER_OPENLAYERS_ACTIONS.ADD_LAYER_LOADING:
      return {...state, loadingLayers: [...state.loadingLayers, action.payload]};
    case REDUCER_OPENLAYERS_ACTIONS.REMOVE_LAYER_LOADING:
      return {...state, loadingLayers: state.loadingLayers.filter(e => e !== action.payload)};

    case REDUCER_OPENLAYERS_ACTIONS.SET_MEASURE_TOOL:
      return {...state, activeMeasureTool: action.payload};
    case REDUCER_OPENLAYERS_ACTIONS.CLEAR_MEASURE_TOOL:
      return {...state, activeMeasureTool: ''};

    case REDUCER_OPENLAYERS_ACTIONS.SET_DELETE_GEOMETRY:
      return {...state, deletingGeometry: true};
    case REDUCER_OPENLAYERS_ACTIONS.UNSET_DELETE_GEOMETRY:
      return {...state, deletingGeometry: false};
    case REDUCER_OPENLAYERS_ACTIONS.RESET_DELETE_GEOMETRY:
      return {
        ...state,
        deletingGeometry: false,
        deletedGeometries: [],
        reloadLayers: Math.random(),
      };

    case REDUCER_OPENLAYERS_ACTIONS.SET_COORDENADAS:
      return {...state, coordenadas: action.payload};

    case REDUCER_OPENLAYERS_ACTIONS.UPDATE_RENDER_FINISH:
      return {...state, isRenderFinished: Math.random()};
    case REDUCER_OPENLAYERS_ACTIONS.UPDATE_RELOAD_LAYERS:
      return {...state, reloadLayers: Math.random()};
    case REDUCER_OPENLAYERS_ACTIONS.UPDATE_ZOOM:
      return {...state, zoom: action.payload};

    case REDUCER_OPENLAYERS_ACTIONS.SET_EXPORT_MAP:
      return {...state, isExportingMap: true};
    case REDUCER_OPENLAYERS_ACTIONS.UNSET_EXPORT_MAP:
      return {...state, isExportingMap: false};
    case REDUCER_OPENLAYERS_ACTIONS.DELETE_GEOMETRY:
      return {...state, deletedGeometries: [...state.deletedGeometries, action.payload]};
    case REDUCER_OPENLAYERS_ACTIONS.CLEAR_DELETED_GEOMETRY:
      return {...state, deletedGeometries: []};

    case REDUCER_OPENLAYERS_ACTIONS.SHOW_INFOS_ABOUT_DRAW:
      return {...state, showInfosAboutDraw: true};
    case REDUCER_OPENLAYERS_ACTIONS.HIDE_INFOS_ABOUT_DRAW:
      return {...state, showInfosAboutDraw: false};

    case REDUCER_OPENLAYERS_ACTIONS.DISABLE_DRAW:
      return {
        ...state,
        activeDrawTool: '',
        activeMeasureTool: '',
        deletingGeometry: false,
        reloadLayers: Math.random(),
      };

    default:
      return {...state};
  }
};
