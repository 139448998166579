import {useEffect, useState} from "react";
import {useGet} from "./useGet";

export const useIntervalGet = (path: string, interval: number, headers?: any,baseURL?: string,) => {
    const [trigger, setTrigger] = useState(1)

    const r = useGet(path, headers, baseURL, trigger)

    useEffect(() => {
        const t = setInterval(() => {
            setTrigger(Math.random)
        }, interval)
        return () => {
            clearInterval(t)
        }
    }, [interval]);

    return r
}
