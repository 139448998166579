export const REDUCER_TRIGGER_INITIAL_STATE = {
    reloadLogo: 1,
    reloadCidades: 1
}

export const REDUCER_TRIGGER_ACTIONS = {
    RELOAD_LOGO: 'RELOAD_LOGO',
    RELOAD_CIDADES: 'RELOAD_CIDADES'
}

export const triggerReducer = (state: any, action: any) => {
    switch (action.type) {
        case REDUCER_TRIGGER_ACTIONS.RELOAD_LOGO:
            return {...state, reloadLogo: Math.random()}
        case REDUCER_TRIGGER_ACTIONS.RELOAD_CIDADES:
            return {...state, reloadCidades: Math.random()}
        default:
            return {...state}
    }
}
