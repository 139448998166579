import styled from 'styled-components';

const UiContent = ({children}: any) => {
  return <Content>{children}</Content>;
};

const Content: any = styled.div`
  background-color: #f7f8f9;
  display: flex;
  height: calc(100% - 52px);
  width: 100%;
`;

export default UiContent;
