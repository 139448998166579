import {IReducerAction, ISessionState} from "./IReducers";

export const REDUCER_SESSION_INITIAL: ISessionState = {
    usuario: undefined,
    sessao: undefined,
    permissoes: undefined,
    token: localStorage.getItem('token') || '',
    cidadeSelecionadaSessao: '',
}

export const REDUCER_SESSION_ACTIONS = {
    SET_USUARIO: 'SET_USUARIO',
    CLEAR_USUARIO: 'CLEAR_USUARIO',
    SET_SESSAO: 'SET_SESSAO',
    CLEAR_SESSAO: 'CLEAR_SESSAO',
    SET_TOKEN: 'SET_TOKEN',
    CLEAR_TOKEN: 'CLEAR_TOKEN',
    SET_PERMISSOES: 'SET_PERMISSOES',
    CLEAR_PERMISSOES: 'CLEAR_PERMISSOES',
    INVALIDATE_SESSION: 'INVALIDATE_SESSION',
    SELECIONA_CIDADE: 'SELECIONA_CIDADE',
    SET_SESSAO_INFOS: 'SET_SESSAO_INFOS'
}

export const sessionReducer = (state: ISessionState, action: IReducerAction) => {
    switch (action.type) {
        case REDUCER_SESSION_ACTIONS.SET_USUARIO:
            return {...state, usuario: action.payload}
        case REDUCER_SESSION_ACTIONS.CLEAR_USUARIO:
            return {...state, usuario: undefined}
        case REDUCER_SESSION_ACTIONS.SET_SESSAO:
            return {...state, sessao: action.payload}
        case REDUCER_SESSION_ACTIONS.CLEAR_SESSAO:
            return {...state, sessao: undefined}
        case REDUCER_SESSION_ACTIONS.SET_TOKEN:
            return {...state, token: action.payload}
        case REDUCER_SESSION_ACTIONS.CLEAR_TOKEN:
            return {...state, token: undefined}
        case REDUCER_SESSION_ACTIONS.SET_PERMISSOES:
            return {...state, permissoes: action.payload}
        case REDUCER_SESSION_ACTIONS.CLEAR_PERMISSOES:
            return {...state, permissoes: undefined}
        case REDUCER_SESSION_ACTIONS.SELECIONA_CIDADE:
            return {...state, cidadeSelecionadaSessao: action.payload, usuario: undefined}
        case REDUCER_SESSION_ACTIONS.INVALIDATE_SESSION:
            return {...state, token: '', usuario: undefined}
        case REDUCER_SESSION_ACTIONS.SET_SESSAO_INFOS:
            return {...state, usuario: action.payload, permissoes: action.payload.permissoes}
        default:
            return {...state}
    }
}
