import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {MapContext} from 'contexts/MapContext';

export const GoogleStreetView = () => {
  const [containerEl, setContainerEl] = useState();
  const [panorama, setPanorama] = useState();
  const [svNotFound, setSVNotFound] = useState(false);
  const {mapState} = useContext(MapContext);

  useEffect(() => {
    if (containerEl && window.google?.maps)
      setPanorama(new window.google.maps.StreetViewPanorama(containerEl));
  }, [containerEl]);

  useEffect(() => {
    const coords = mapState.selectedGeometryCoords;
    const isValidCoords = coords.lng && coords.lat;

    const handleSVData = ({data}) => {
      setSVNotFound(false);
      panorama.setPano(data.location.pano);
      panorama.setPov({
        heading: 270,
        pitch: 0,
      });
      panorama.setVisible(true);
    };

    const handleSVError = () => {
      panorama.setPano(null);
      panorama.setVisible(false);
      setSVNotFound(true);
    };

    if (panorama && isValidCoords && window.google?.maps) {
      const sv = new window.google.maps.StreetViewService();
      sv.getPanorama({location: coords, radius: 10}).then(handleSVData).catch(handleSVError);
    }
  }, [panorama, mapState.selectedGeometryCoords]);

  return (
    <ContainerMap ref={setContainerEl}>
      {svNotFound && <Center>Não há dados para a coordenada selecionada!</Center>}
    </ContainerMap>
  );
};

const ContainerMap = styled.div`
  width: 100%;
  height: 80vh;
`;

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
