import AppContextProvider from "contexts/AppContext";
import {SessionContextProvider} from "contexts/SessionContext";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {UiNotificationsProvider} from 'components/UiNotifications'
import {FileUploadContextProvider} from "./contexts/FileUploadContext";
import {CatchError} from "./components/CatchError";

ReactDOM.render(
    <CatchError>
        <BrowserRouter>
            <UiNotificationsProvider>
                <SessionContextProvider>
                    <AppContextProvider>
                        <FileUploadContextProvider>
                            <App/>
                        </FileUploadContextProvider>
                    </AppContextProvider>
                </SessionContextProvider>
            </UiNotificationsProvider>
        </BrowserRouter>
    </CatchError>,
    document.getElementById("root"),
);
