import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditInformacoesPublicas = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>
                <UiInputText label="Água estadual" name={"AGUA_ESTADUAL"} value={objeto["AGUA_ESTADUAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Coleta de lixo" name={"COLETA_LIXO"} value={objeto["COLETA_LIXO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Conservacao do calçamento" name={"CONSERVACAO_CALCAMENTO"}
                             value={objeto["CONSERVACAO_CALCAMENTO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Encerrado" name={"ENCERRADO"} value={objeto["ENCERRADO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Endereço planta" name={"ENDERECO_PLANTA"} value={objeto["ENDERECO_PLANTA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Engloba com" name={"ENGLOBA_COM"} value={objeto["ENGLOBA_COM"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Englobado" name={"ENGLOBADO"} value={objeto["ENGLOBADO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Esgoto" name={"ESGOTO"} value={objeto["ESGOTO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Esgoto cloacal" name={"ESGOTO_CLOACAL"} value={objeto["ESGOTO_CLOACAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Esgoto pluvial" name={"ESGOTO_PLUVIAL"} value={objeto["ESGOTO_PLUVIAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Fase obra" name={"FASE_OBRA"} value={objeto["FASE_OBRA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Iluminação pública" name={"ILUMINACAO_PUBLICA"} value={objeto["ILUMINACAO_PUBLICA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Imune/Isento" name={"IMUNE_ISENTO"} value={objeto["IMUNE_ISENTO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="INCRA" name={"INCRA"} value={objeto["INCRA"]} onChange={handleChangeField}/>
                <UiInputText label="Inscrição anterior" name={"INSCRICAO_ANTERIOR"} value={objeto["INSCRICAO_ANTERIOR"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Isento TSU" name={"ISENTO_TSU"} value={objeto["ISENTO_TSU"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ITR/NIRF" name={"ITR_NIRF"} value={objeto["ITR_NIRF"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Limpeza publica" name={"LIMPEZA_PUBLICA"} value={objeto["LIMPEZA_PUBLICA"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Número do livro" name={"NUMERO_LIVRO"} value={objeto["NUMERO_LIVRO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número de matricula do imóvel" name={"NUMERO_MATRICULA_IMOVEL"}
                             value={objeto["NUMERO_MATRICULA_IMOVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Número de registro imóvel" name={"NUMERO_REGISTRO_IMOVEL"}
                             value={objeto["NUMERO_REGISTRO_IMOVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Possui débito" name={"POSSUI_DEBITO"} value={objeto["POSSUI_DEBITO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Rede de água no logradouro" name={"REDE_AGUA_LOGRADOURO"}
                             value={objeto["REDE_AGUA_LOGRADOURO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Rede de esgoto pluvial" name={"REDE_ESGOTO_PLUVIAL"}
                             value={objeto["REDE_ESGOTO_PLUVIAL"]} onChange={handleChangeField}/>
                <UiInputText label="Redução telefonia" name={"REDUCAO_TELEFONICA"} value={objeto["REDUCAO_TELEFONICA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Redução sem luz" name={"REDUCAO_LUZ"} value={objeto["REDUCAO_LUZ"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Redução sem pavimentação" name={"REDUCAO_PAVIMENTACAO"}
                             value={objeto["REDUCAO_PAVIMENTACAO"]} onChange={handleChangeField}/>
                <UiInputText label="Redução sem rede de luz" name={"REDUCAO_REDE_LUZ"}
                             value={objeto["REDUCAO_REDE_LUZ"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Redução sem rede hidráulica" name={"REDUCAO_REDE_HIDRAULICA"}
                             value={objeto["REDUCAO_REDE_HIDRAULICA"]} onChange={handleChangeField}/>
                <UiInputText label="Serviços urbanos" name={"SERVICOS_URBANOS"} value={objeto["SERVICOS_URBANOS"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Tipo de imposto" name={"TIPO_IMPOSTO"} value={objeto["TIPO_IMPOSTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Tipo de tributo" name={"TIPO_ATRIBUTO"} value={objeto["TIPO_ATRIBUTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Vencimento" name={"VENCIMENTO"} value={objeto["VENCIMENTO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditInformacoesPublicas
