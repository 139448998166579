import {MapContextProvider} from "contexts/MapContext";
import ModalFilterOnMap from "modals/ModalFilterOnMap";
import ModalImagemFaixada from "modals/ModalImagemFaixada";
import ModalMapaTematico from "modals/ModalMapaTematico";
import ModalPrintMap from "modals/ModalPrintMap";
import ModalSearchOnMap from "modals/ModalSearchOnMap";
import {OpenLayersWrapper} from "pages/MapPage/openlayers/Map";
import {Template} from "pages/Template";
import {ModalStreetView} from "../../modals/ModalStreetView";

const MapPage = () => {
    return (
        <Template>
            <MapContextProvider>
                <OpenLayersWrapper/>

                <ModalImagemFaixada/>
                <ModalSearchOnMap/>
                <ModalFilterOnMap/>
                <ModalMapaTematico/>
                <ModalPrintMap/>
                <ModalStreetView />
            </MapContextProvider>
        </Template>
    );
};

export default MapPage;
