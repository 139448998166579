import {MdDelete} from 'react-icons/md';
import styled from 'styled-components';

export const UiFiltrosBox = ({filtros, onRemove}: any) => {
  if (!filtros.length) {
    return null;
  }

  return (
    <Box>
      <FiltroHeader>
        <tr>
          <th>Filtrar em</th>
          <th>Campo</th>
          <th>Tipo de filtro</th>
          <th>Valor</th>
          <th />
        </tr>
      </FiltroHeader>
      <tbody>
        {filtros.map((f: any, i: any) => (
          <FiltroRow key={i}>
            <FiltroCell>{f.filtrarEm}</FiltroCell>
            <FiltroCell>{f.campo}</FiltroCell>
            <FiltroCell>{f.tipo}</FiltroCell>
            <FiltroCell>{f.valor}</FiltroCell>
            <FiltroCell>
              <MdDelete
                color="DarkRed"
                cursor="pointer"
                onClick={() => {
                  onRemove(i);
                }}
              />
            </FiltroCell>
          </FiltroRow>
        ))}
      </tbody>
    </Box>
  );
};

const Box: any = styled.table`
  border: 1px solid #ddd;
  border-collapse: collapse;
  margin: 10px;
  width: calc(100% - 20px);
`;

const FiltroHeader: any = styled.thead`
  font-size: 0.8em;
  height: 40px;
`;
const FiltroRow: any = styled.tr`
  font-size: 0.9em;
  height: 40px;
  cursor: default;

  &:hover {
    background-color: #efefef;
  }
`;
const FiltroCell: any = styled.td`
  text-align: center;
`;
