import {IAppConfigState, IReducerAction} from "./IReducers";

const {location} = window;
const reg = location.pathname.match(/\/cidade\/(.+$)/);
const idCidade = reg && reg.length > 1 ? reg[1] : null;

export const REDUCER_APP_CONFIG_INITIAL: IAppConfigState = {
    globalConfig: {},
    cidadeConfig: {},
    idCidadeSelecionada: idCidade || localStorage.getItem("cidadeSelecionada") || '',
    cidadeSelecionada: {},
    perimetro: {},
    cidades: [],
}

export const REDUCER_APP_CONFIG_ACTIONS = {
    SET_CIDADES: 'SET_CIDADES',
    CLEAR_CIDADES: 'CLEAR_CIDADES',

    SET_GLOBAL_CONFIG: 'SET_GLOBAL_CONFIG',
    CLEAR_GLOBAL_CONFIG: 'CLEAR_GLOBAL_CONFIG',

    SET_CIDADE_CONFIG: 'SET_CIDADE_CONFIG',
    CLEAR_CIDADE_CONFIG: 'CLEAR_CIDADE_CONFIG',

    SET_CIDADE_SELECIONADA: 'SET_CIDADE_SELECIONADA',
    CLEAR_CIDADE_SELECIONADA: 'SET_CIDADE_SELECIONADA',

    SET_ID_CIDADE_SELECIONADA: 'SET_ID_CIDADE_SELECIONADA',
    CLEAR_ID_CIDADE_SELECIONADA: 'CLEAR_ID_CIDADE_SELECIONADA',

    SET_PERIMETRO: 'SET_PERIMETRO'
}

export const appConfigReducer = (state: IAppConfigState, action: IReducerAction) => {
    switch (action.type) {
        case REDUCER_APP_CONFIG_ACTIONS.SET_CIDADES:
            return {...state, cidades: action.payload}
        case REDUCER_APP_CONFIG_ACTIONS.CLEAR_CIDADES:
            return {...state, cidades: []}

        case REDUCER_APP_CONFIG_ACTIONS.SET_GLOBAL_CONFIG:
            return {...state, globalConfig: action.payload}
        case REDUCER_APP_CONFIG_ACTIONS.CLEAR_GLOBAL_CONFIG:
            return {...state, globalConfig: {}}

        case REDUCER_APP_CONFIG_ACTIONS.SET_CIDADE_CONFIG:
            return {...state, cidadeConfig: action.payload}
        case REDUCER_APP_CONFIG_ACTIONS.CLEAR_CIDADE_CONFIG:
            return {...state, cidadeConfig: {}}

        case REDUCER_APP_CONFIG_ACTIONS.SET_CIDADE_SELECIONADA:
            return {...state, cidadeSelecionada: action.payload}
        case REDUCER_APP_CONFIG_ACTIONS.CLEAR_CIDADE_SELECIONADA:
            return {...state, cidadeSelecionada: ''}

        case REDUCER_APP_CONFIG_ACTIONS.SET_ID_CIDADE_SELECIONADA:
            return {...state, idCidadeSelecionada: action.payload}
        case REDUCER_APP_CONFIG_ACTIONS.CLEAR_ID_CIDADE_SELECIONADA:
            localStorage.removeItem("cidadeSelecionada")
            return {...state, idCidadeSelecionada: ''}

        case REDUCER_APP_CONFIG_ACTIONS.SET_PERIMETRO:
            return {...state, perimetro: action.payload}

        default:
            return {...state}
    }
}
