import UiButton from 'components/UiButton'
import UiCheckbox from 'components/UiCheckbox'
import UiFlex from "components/UiFlex";
import UiFlexItem from "components/UiFlex";
import UiInputText from 'components/UiInputText'
import UiTabs from 'components/UiTabs'
import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {API} from 'utils/api'
import {AppContext} from "../../../contexts/AppContext";
import {SessionContext} from "../../../contexts/SessionContext";
import RegisterPage from "../index";

const EditLogradouro = () => {
    const {appConfigState} = useContext(AppContext)
    const {headers: headersReq} = useContext(SessionContext)
    const [selectedTab, setSelectedTab] = useState(1)

    const [logradouro, setLogradouro] = useState<any>({
        NOME_RUA: '', TIPO_PAVIM: '', AGUA: '',
        ENERGIA: '', ESGOTO: '', TRAT_ESGOTO: '',
    })

    const navigate = useNavigate()
    const location: any = useLocation()

    useEffect(() => {
        if (location.state) {
            setLogradouro(location.state)
        }
    }, [location])

    const tabs = [{id: 1, name: 'Logradouro'}]

    const handleChangeCheckbox = (field: any) => {
        return () => setLogradouro({...logradouro, [field]: logradouro[field] === 'SIM' ? '' : 'SIM'})
    }

    const handleSaveLogradouro = async () => {
        await API.saveLogradouro(logradouro._id, logradouro, appConfigState.idCidadeSelecionada, headersReq)
        navigate(-1)
    }

    const renderLogradouroInfos = () => {
        return (
            <UiFlexItem direction="column">
                <UiFlexItem>
                    <UiInputText label="Nome da rua" value={logradouro.NOME_RUA} onChange={(e: any) => {
                        setLogradouro({...logradouro, NOME_RUA: e.target.value})
                    }}/>
                    <UiInputText label="Tipo de pavimentação" value={logradouro.TIPO_PAVIM} onChange={(e: any) => {
                        setLogradouro({...logradouro, TIPO_PAVIM: e.target.value})
                    }}/>
                </UiFlexItem>
                <UiFlexItem justifyContent={"space-between"}>
                    <UiCheckbox label="Água" value={logradouro.AGUA === 'SIM'} onChange={handleChangeCheckbox('AGUA')}/>
                    <UiCheckbox label="Energia" value={logradouro.ENERGIA === 'SIM'}
                                onChange={handleChangeCheckbox('ENERGIA')}/>
                    <UiCheckbox label="Esgoto" value={logradouro.ESGOTO === 'SIM'}
                                onChange={handleChangeCheckbox('ESGOTO')}/>
                    <UiCheckbox label="Tratamento de esgoto" value={logradouro.TRAT_ESGOTO === 'SIM'}
                                onChange={handleChangeCheckbox('TRAT_ESGOTO')}/>
                </UiFlexItem>
                <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                    <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                        Cancelar
                    </UiButton>
                    <UiButton primary onClick={handleSaveLogradouro}>
                        Salvar
                    </UiButton>
                </UiFlexItem>
            </UiFlexItem>
        )
    }

    const renderTabs = () => {
        switch (selectedTab) {
            case 1:
                return renderLogradouroInfos()
            default:
                return null
        }
    }

    return (
        <RegisterPage>
            <UiFlex>
                <UiTabs tabs={tabs} selectedTab={selectedTab} onSelectTab={(id: any) => {
                    setSelectedTab(id)
                }}>
                    {renderTabs()}
                </UiTabs>
            </UiFlex>
        </RegisterPage>
    )
}

export default EditLogradouro
