import {UiInfo} from 'components/UiInfo';

const InfosPlanoDiretor = ({infos}: any) => {
  if (!infos) return null;
  return (
    <>
      <UiInfo label="Zoneamento" value={infos['PD_ZONEAMENTO']} />
      <UiInfo label="I.A.¹ MÍNIMO" value={infos['PD_I_A_MINIMO']} />
      <UiInfo label="I.A.¹ BÁSICO" value={infos['PD_I_A_BASICO']} />
      <UiInfo label="I.A.¹ MÁXIMO" value={infos['PD_I_A_MAXIMO']} />
      <UiInfo label="T.O. MÁXIMA" value={infos['PD_T_O_MAXIMO']} />
      <UiInfo label="Recuos (m) MÍNIMOS FRENTE" value={infos['PD_RECUO_MINIMO_FRENTE']} />
      <UiInfo label="Recuos (m) MÍNIMOS LATERAL" value={infos['PD_RECUO_MINIMO_LATERAL']} />
      <UiInfo label="Recuos (m) MÍNIMOS FUNDOS" value={infos['PD_RECUO_MINIMO_FUNDOS']} />
      <UiInfo label="Gabarito de Altura Máximo (m)" value={infos['PD_GABARITO_ALTURA_MAXIMO']} />
      <UiInfo label="T.I.Mínima" value={infos['PD_T_I_MINIMA']} />
      <UiInfo label="Vagas" value={infos['PD_VAGAS']} />
      <UiInfo label="HIERARQUIA VIÁRIA" value={infos['PD_HIERARQUIA_VIARIA']} />
      <UiInfo label="USOS RESIDENCIAIS" value={infos['PD_USOS_RESIDENCIAIS']} />
      <UiInfo label="USOS NÃO RESIDENCIAIS" value={infos['PD_USOS_NAO_RESIDENCIAIS']} />
    </>
  );
};

export default InfosPlanoDiretor;
