import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {useContext, useRef} from 'react';
import {FaExternalLinkSquareAlt} from 'react-icons/fa';
import {GiPathDistance} from 'react-icons/gi';
import {Transition} from 'react-transition-group';
import styled from 'styled-components';
import useOutsideClick from 'utils/useOutsideClick';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../../reducers/openlayers';

const duration = 200;

const MeasureToolbarModal = () => {
  const {openLayersState, openLayersDispatch} = useContext(OpenLayersContext);
  const {mapState} = useContext(MapContext);

  const component = useRef();

  useOutsideClick(
    component,
    () => openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_MEASURE_SECTION}),
    openLayersState.showMeasureSection
  );

  const components = [
    {
      id: 'distancia',
      label: 'Distância',
      icon: GiPathDistance,
      type: 'distancia',
    },
    {id: 'area', label: 'Área', icon: FaExternalLinkSquareAlt, type: 'area'},
  ];

  return (
    <Transition in={openLayersState.showMeasureSection} timeout={duration}>
      {state => (
        <Container ref={component} type={mapState.sidebarType} state={state}>
          {components.map((e: any) => (
            <Component
              key={e.id}
              onClick={() => {
                openLayersDispatch({
                  type: REDUCER_OPENLAYERS_ACTIONS.SET_MEASURE_TOOL,
                  payload: e.type,
                });
                openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLEAR_DRAW_TOOL});
                openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_MEASURE_SECTION});
              }}
            >
              <Icon>{e.icon({size: e?.size})}</Icon>
              <Label>{e.label}</Label>
            </Component>
          ))}
        </Container>
      )}
    </Transition>
  );
};
const Container: any = styled.div<any>`
  display: flex;
  position: absolute;
  left: ${({type}) => (type === 'out' ? '43px' : '68px')};
  top: ${({type}) => (type === 'out' ? '290px' : '343px')};
  z-index: 1000000;

  flex-direction: column;
  background-color: #ffffffcc;
  backdrop-filter: blur(5px);
  border: 1px solid silver;
  box-shadow: 0 0 2px silver;

  transform: ${({state}) => (state === 'entered' ? 'scaleX(1)' : 'scaleX(0)')};
  opacity: ${({state}) => (state === 'entered' ? '1' : '0')};
  transition: transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out;
  transform-origin: left;
`;

const Component: any = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 10px;
  width: 130px;

  &:hover {
    background-color: #bbb;
  }
`;

const Icon: any = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px;
`;
const Label: any = styled.div``;

export default MeasureToolbarModal;
