import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {useContext, useRef} from 'react';
import {FaEye} from 'react-icons/fa';
import {Transition} from 'react-transition-group';
import {REDUCER_LOADING_ACTIONS} from 'reducers/loading';
import styled from 'styled-components';
import useOutsideClick from 'utils/useOutsideClick';
import {REDUCER_MAP_ACTIONS} from '../../../../reducers/map';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../../reducers/openlayers';

const duration = 200;

const LayersToolbarModal = () => {
  const {mapState, mapDispatch} = useContext(MapContext);
  const {loadingDispatch} = useContext(AppContext);
  const {openLayersState, openLayersDispatch} = useContext(OpenLayersContext);

  const component = useRef();

  useOutsideClick(
    component,
    () => openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_LAYERS_SECTION}),
    openLayersState.showLayerSection
  );

  const isSelected = (id: any) => {
    let isSelected = false;

    mapState.selectedLayers.forEach((e: any) => {
      if (e.id === id) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const toggleCamada = (layer: any) => {
    mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_LAYER, payload: layer});
    isSelected(layer.id)
      ? loadingDispatch({type: REDUCER_LOADING_ACTIONS.REMOVE_LAYER, payload: layer.id})
      : loadingDispatch({type: REDUCER_LOADING_ACTIONS.ADD_LAYER, payload: layer.id});
  };

  return (
    <Transition in={openLayersState.showLayerSection} timeout={duration}>
      {state => (
        <Container ref={component} type={mapState.sidebarType} state={state}>
          {mapState.layers?.map((e: any) => (
            <Layer key={e.id} onClick={() => toggleCamada(e)}>
              <EyeIcon>
                <FaEye color={isSelected(e.id) ? '#099cec' : 'gray'} />
              </EyeIcon>
              <LayerName>{e.nome}</LayerName>
            </Layer>
          ))}
        </Container>
      )}
    </Transition>
  );
};

const Container: any = styled.div<any>`
  display: flex;
  position: absolute;
  left: ${({type}) => (type === 'out' ? '43px' : '68px')};
  top: ${({type}) => (type === 'out' ? '53px' : '63px')};
  max-height: calc(100% - 100px);
  z-index: 1000000;

  flex-direction: column;
  background-color: #ffffffcc;
  backdrop-filter: blur(5px);
  border: 1px solid silver;
  box-shadow: 0 0 2px silver;

  transform: ${({state}) => (state === 'entered' ? 'scaleX(1)' : 'scaleX(0)')};
  opacity: ${({state}) => (state === 'entered' ? '1' : '0')};
  transition: transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out;
  transform-origin: left;
  overflow: auto;
  width: 340px;
`;

const Layer: any = styled.div`
  display: flex;
  padding: 10px;
  width: 320px;
  align-items: center;
  cursor: pointer;
`;

const EyeIcon: any = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
`;
const LayerName: any = styled.div``;

export default LayersToolbarModal;
