import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditValores = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>
                <UiInputText label="Aliquota" name={"ALIQUOTA_IMPOSTO"} value={objeto["ALIQUOTA_IMPOSTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Aliquota calculo" name={"ALIQUOTA_CALCULO"} value={objeto["ALIQUOTA_CALCULO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Débito automático" name={"DEBITO_AUTOMATICO"} value={objeto["DEBITO_AUTOMATICO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Valor de coleta de lixo" name={"VALOR_COLETA_LIXO"}
                             value={objeto["VALOR_COLETA_LIXO"]} onChange={handleChangeField}/>
                <UiInputText label="Valor do habite-se" name={"VALOR_HABITESE"} value={objeto["VALOR_HABITESE"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Valor do IPTU" name={"VALOR_IPTU"} value={objeto["VALOR_IPTU"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Valor venal" name={"VALOR_VENAL"} value={objeto["VALOR_VENAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Valor venal da edificação" name={"VALOR_VENAL_EDIFICACAO"}
                             value={objeto["VALOR_VENAL_EDIFICACAO"]} onChange={handleChangeField}/>
                <UiInputText label="Valor venal do prédio" name={"VALOR_VENAL_PREDIO"}
                             value={objeto["VALOR_VENAL_PREDIO"]} onChange={handleChangeField}/>
                <UiInputText label="Valor venal do terreno" name={"VALOR_VENAL_TERRENO"}
                             value={objeto["VALOR_VENAL_TERRENO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Valor venal territorial" name={"VALOR_VENAL_TERRITORIAL"}
                             value={objeto["VALOR_VENAL_TERRITORIAL"]} onChange={handleChangeField}/>
                <UiInputText label="Valor venal total" name={"VALOR_VENAL_TOTAL"} value={objeto["VALOR_VENAL_TOTAL"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditValores
