import {UiInfo} from "components/UiInfo";

const InfosPublicas = ({infos}: any) => (
    <>
        <UiInfo label="Água estadual" value={infos["AGUA_ESTADUAL"]}/>
        <UiInfo label="Coleta de Lixo" value={infos["COLETA_LIXO"]}/>
        <UiInfo label="Conervação do calçamento" value={infos["CONSERVACAO_CALCAMENTO"]}/>
        <UiInfo label="Encerreado" value={infos["ENCERRADO"]}/>
        <UiInfo label="Endereço Planta" value={infos["ENDERECO_PLANTA"]}/>
        <UiInfo label="Engloba Com" value={infos["ENGLOBA_COM"]}/>
        <UiInfo label="Englobado" value={infos["ENGLOBADO"]}/>
        <UiInfo label="Esgoto" value={infos["ESGOTO"]}/>
        <UiInfo label="Esgoto Cloacal" value={infos["ESGOTO_CLOACAL"]}/>
        <UiInfo label="Esgoto pluvial" value={infos["ESGOTO_PLUVIAL"]}/>
        <UiInfo label="Fase da Obra" value={infos["FASE_OBRA"]}/>
        <UiInfo label="Iluminação Pública" value={infos["ILUMINACAO_PUBLICA"]}/>
        <UiInfo label="Imune ou Isento" value={infos["IMUNE_ISENTO"]}/>
        <UiInfo label="INCRA" value={infos["INCRA"]}/>
        <UiInfo label="Inscrição anterior" value={infos["INSCRICAO_ANTERIOR"]}/>
        <UiInfo label="Isento TSU" value={infos["ISENTO_TSU"]}/>
        <UiInfo label="ITR / NIRF" value={infos["ITR_NIRF"]}/>
        <UiInfo label="Limpeza Publica" value={infos["LIMPEZA_PUBLICA"]}/>
        <UiInfo label="Número Livro" value={infos["NUMERO_LIVRO"]}/>
        <UiInfo label="Número Matrícula Imóvel" value={infos["NUMERO_MATRICULA_IMOVEL"]}/>
        <UiInfo label="Nº Registro Imóvel" value={infos["NUMERO_REGISTRO_IMOVEL"]}/>
        <UiInfo label="Possui Débito" value={infos["POSSUI_DEBITO"]}/>
        <UiInfo label="Rede de Água do Logradouro" value={infos["REDE_AGUA_LOGRADOURO"]}/>
        <UiInfo label="Rede Esgoto Pluvial" value={infos["REDE_ESGOTO_PLUVIAL"]}/>
        <UiInfo label="Rede de Luz do Logradouro" value={infos["REDE_LUZ_LOGRADOURO"]}/>
        <UiInfo label="Rede Telefônica" value={infos["REDUCAO_TELEFONIA"]}/>
        <UiInfo label="Redução / Luz" value={infos["REDUCAO_LUZ"]}/>
        <UiInfo label="Redução / Sem pavimentação" value={infos["REDUCAO_SEM_PAVIMENTOS"]}/>
        <UiInfo label="Redução / Sem rede de luz" value={infos["REDUCAO_SEM_REDE_LUZ"]}/>
        <UiInfo label="Redução / Sem rede hidráulica" value={infos["REDUCAO_SEM_REDE_HIDRAULICA"]}/>
        <UiInfo label="Serviços Urbanos" value={infos["SERVICOS_URBANOS"]}/>
        <UiInfo label="Tipo de Imposto " value={infos["TIPO_IMPOSTO"]}/>
        <UiInfo label="Tipo de tributo" value={infos["TIPO_TRIBUTO"]}/>
        <UiInfo label="Vencimento" value={infos["VENCIMENTO"]}/>
    </>
)

export default InfosPublicas;
