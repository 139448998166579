import {UiInfo} from "components/UiInfo";
import {useContext} from "react";
import {SessionContext} from "contexts/SessionContext";

const InfosEndereco = ({infos}: any) => {
    const {sessionState} = useContext(SessionContext);

    return (
        <>
            <UiInfo label="Bairro  do terreno" value={infos["BAIRRO_TERRENO"]}/>
            {sessionState.token && <UiInfo label="Bairro do contribuinte" value={infos["BAIRRO_CONTRIBUINTE"]}/>}
            <UiInfo label="Bairro (correspondência)" value={infos["BAIRRO_CORRESPONSAVEL"]}/>
            <UiInfo label="CEP do terreno" value={infos["CEP_TERRENO"]}/>
            {sessionState.token && <UiInfo label="CEP do contribuinte" value={infos["CEP_CONTRIBUINTE"]}/>}
            <UiInfo label="CEP do corresponsável" value={infos["CEP_CORRESPONSAVEL"]}/>
            <UiInfo label="Cidade do corresponsável" value={infos["CIDADE_CORRESPONSAVEL"]}/>
            <UiInfo label="Comarca Cidade/UF" value={infos["COMARCA_CIDADE_UF"]}/>
            <UiInfo label="Complemento do corresponsável" value={infos["COMPLEMENTO_CORRESPONSAVEL"]}/>
            {sessionState.token &&
                <UiInfo label="Complemento do contribuinte" value={infos["COMPLEMENTO_CONTRIBUINTE"]}/>}
            <UiInfo label="Complemento do terreno" value={infos["COMPLEMENTO_TERRENO"]}/>
            <UiInfo label="Complemento endereço" value={infos["COMPLEMENTO_ENDERECO"]}/>
            <UiInfo label="Comunidade" value={infos["COMUNIDADE"]}/>
            <UiInfo label="Descrição bairro corresponsável" value={infos["DESCRICAO_BAIRRO_CORRESPONSAVEL"]}/>
            <UiInfo label="Descrição logradouro corresponsável" value={infos["DESCRICAO_LOGRADOURO_CORRESPONSAVEL"]}/>
            <UiInfo label="Estado" value={infos["ESTADO"]}/>
            <UiInfo label="Estado corresponsável" value={infos["ESTADO_CRRESPONSAVEL"]}/>
            <UiInfo label="Logradouro Terreno" value={infos["LOGRADOURO_TERRENO"]}/>
            {sessionState.token && <UiInfo label="Logradouro Contribuinte" value={infos["LOGRADOURO_CONTRIBUINTE"]}/>}
            <UiInfo label="Logradouro Corresponsavel" value={infos["LOGRADOURO_CORRESPONSAVEL"]}/>
            <UiInfo label="Municipio do terreno" value={infos["MUNICIPIO_TERRENO"]}/>
            {sessionState.token && <UiInfo label="Municipio do contribuinte" value={infos["MUNICIPIO_CONTRIBUINTE"]}/>}
            <UiInfo label="Nome do bairro" value={infos["NOME_BAIRRO"]}/>
            <UiInfo label="Número imóvel corresponsável" value={infos["NUMERO_IMOVEL_CORRESPONSAVEL"]}/>
            <UiInfo label="Número terreno" value={infos["NUMERO_TERRENO"]}/>
            <UiInfo label="Trecho" value={infos["TRECHO"]}/>
            <UiInfo label="UF" value={infos["UF"]}/>
        </>
    );
};

export default InfosEndereco;
