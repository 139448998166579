import UiButton from 'components/UiButton';
import UiFlex from 'components/UiFlex';
import {UiIconButton} from 'components/UiIconButton';
import UiInputText from 'components/UiInputText';
import UiModal from 'components/UiModal';
import UiBox from 'components/UiPanel';
import UiSelect from 'components/UiSelect';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import {useContext, useEffect, useState} from 'react';
import {MdAdd, MdClose} from 'react-icons/md';
import {Transition} from 'react-transition-group';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import {API} from 'utils/api';
import {SessionContext} from '../contexts/SessionContext';
import {useGet} from '../hooks/useGet';
import {REDUCER_MAP_ACTIONS} from '../reducers/map';
import {UiFiltrosBox} from './UiFiltrosBox';

const duration = 100;

const opcoesFiltro = [
  {key: 'edificacao', value: 'Edificações'},
  {key: 'logradouro', value: 'Logradouros'},
  {key: 'lote', value: 'Lotes'},
];

const tipos = [
  {key: 'igual', value: 'É igual a'},
  {key: 'diferente', value: 'É diferente de'},
  {key: 'contem', value: 'Contém'},
];

const ModalFilterOnMap = () => {
  const {modalDispatch, modalState, appConfigState} = useContext(AppContext);

  const {mapDispatch} = useContext(MapContext);
  const {headers: headersReq} = useContext(SessionContext);

  const [caracteristicas, setCaracteristicas] = useState<any[]>([]);
  const [valores, setValores] = useState<any[]>([]);

  const [filtros, setFiltros] = useState<any[]>([]);
  const [noData, setNoData] = useState(false);

  const [form, setForm] = useState({
    filtrarEm: '',
    campo: '',
    tipo: 'igual',
    valor: '',
  });

  const {data: caracteristicasReq, loading: loadingCarac} = useGet(
    `/commons/caracteristicas?tipo=${form.filtrarEm}&cidade=${appConfigState.idCidadeSelecionada}`,
    headersReq,
    undefined,
    form.filtrarEm
  );
  useEffect(() => {
    setForm(f => ({...f, campo: '', tipo: 'igual', valor: ''}));
    if (Array.isArray(caracteristicasReq)) {
      setCaracteristicas(caracteristicasReq.sort().map((e: any) => ({key: e, value: e})));
    }
  }, [caracteristicasReq]);

  const {data: valoresReq, loading: loadingValores} = useGet(
    `/commons/valoresCaracteristicas?tipo=${form.filtrarEm}&cidade=${appConfigState.idCidadeSelecionada}&campo=${form.campo}&tipoAgrupamento=`,
    headersReq,
    undefined,
    form.campo
  );
  useEffect(() => {
    setForm(f => ({...f, tipo: 'igual', valor: ''}));
    if (Array.isArray(valoresReq)) {
      setValores(valoresReq.sort().map(e => ({key: e, value: e})));
    }
  }, [valoresReq]);

  const handleFilter = async () => {
    try {
      setNoData(false);
      let {data}: any = await API.executaFiltro(appConfigState.idCidadeSelecionada, filtros);
      if (!data?.length) {
        setNoData(true);
        return;
      }

      mapDispatch({type: REDUCER_MAP_ACTIONS.SET_FILTERED_GEOMETRIES, payload: data});
      closeFiltroNoMapa();
    } catch (e) {
      console.log(e);
    }
  };

  const addToFiltro = () => {
    setFiltros([...filtros, form]);
  };

  const handleRemove = (i: any) => {
    filtros.splice(i, 1);
    setFiltros([...filtros]);
  };

  const closeFiltroNoMapa = () => {
    modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_FILTRO_MAPA});
  };

  return (
    <Transition in={modalState.filtroNoMapa} timeout={duration}>
      {state => (
        <UiModal state={state} duration={duration}>
          <UiBox width={'90%'} maxWidth={'1000px'}>
            <UiFlex
              alignItems={'center'}
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText size="1.2em">Procurar</UiText>
              <MdClose cursor="pointer" onClick={closeFiltroNoMapa} size={'1.3em'} />
            </UiFlex>
            <UiFlex breakpoint={'500px'} justifyContent={'center'}>
              <UiSelect
                label={'Filtrar em'}
                options={opcoesFiltro}
                selectText={'Filtro'}
                value={form.filtrarEm}
                onChange={(e: any) => setForm({...form, filtrarEm: e.target.value})}
                disabled={filtros.length}
              />
              <UiSelect
                label={'Campo'}
                options={caracteristicas}
                loading={loadingCarac}
                disabled={!caracteristicas || caracteristicas.length === 0}
                selectText={'Selecione o campo'}
                value={form.campo}
                onChange={(e: any) => setForm({...form, campo: e.target.value})}
              />
              <UiSelect
                label={'Tipo de filtro'}
                name="Tipo"
                options={tipos}
                disabled={!valores || valores.length === 0}
                value={form.tipo}
                onChange={(e: any) => setForm({...form, tipo: e.target.value})}
              />
              {form.tipo === 'contem' ? (
                <UiInputText
                  label={'Valor'}
                  disabled={!valores || valores.length === 0}
                  value={form.valor}
                  onChange={(e: any) => setForm({...form, valor: e.target.value})}
                />
              ) : (
                <UiSelect
                  label={'Valor'}
                  options={valores}
                  disabled={!valores || valores.length === 0}
                  value={form.valor}
                  onChange={(e: any) => setForm({...form, valor: e.target.value})}
                  loading={loadingValores}
                />
              )}
              <UiIconButton
                icon={MdAdd}
                size={'1.5em'}
                margin={'30px 0 0 0'}
                onClick={addToFiltro}
                disabled={!form.campo}
              />
            </UiFlex>
            <UiFlex>
              <UiFiltrosBox filtros={filtros} onRemove={handleRemove} />
            </UiFlex>
            {noData ? (
              <UiFlex alignItems="center" justifyContent="center" margin="20px 0 0 0">
                <Text>Não há dados com o filtro utilizado</Text>
              </UiFlex>
            ) : null}
            <UiFlex
              justifyContent={'flex-end'}
              margin={'10px 10px 0 0'}
              width={'calc(100% - 10px)'}
            >
              <UiButton primary disabled={!filtros.length} onClick={handleFilter}>
                Filtrar
              </UiButton>
            </UiFlex>
          </UiBox>
        </UiModal>
      )}
    </Transition>
  );
};

const Text: any = styled.div`
  color: red;
  font-size: 0.9em;
`;

export default ModalFilterOnMap;
