import {Control} from 'ol/control';
import {useContext, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {OpenLayersContext} from '../../../../contexts/OpenLayersContext';

export const TopRightMapContainer = ({children, show}: any) => {
  const {openLayersState} = useContext(OpenLayersContext);
  const componentRef = useRef<any>();

  useEffect(() => {
    if (openLayersState.map && componentRef.current && show) {
      const c = new Control({element: componentRef.current});
      openLayersState.map.addControl(c);

      return () => {
        openLayersState.map.removeControl(c);
      };
    }
  }, [openLayersState.map, show]);

  return <Box ref={componentRef}>{children}</Box>;
};

const Box: any = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
`;
