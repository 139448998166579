import UiButton from 'components/UiButton';
import UiCheckbox from 'components/UiCheckbox';
import UiInputText from 'components/UiInputText';
import UiLoadingFullScreen from 'components/UiLoadingFullScreen';
import UiModal from 'components/UiModal';
import UiBox from 'components/UiPanel';
import UiTable from 'components/UiTable';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import {useContext, useState} from 'react';
import {MdClose, MdMap} from 'react-icons/md';
import {Transition} from 'react-transition-group';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import {API} from 'utils/api';
import UiFlex from '../components/UiFlex';
import {REDUCER_MAP_ACTIONS} from '../reducers/map';

const duration = 100;

const ModalSearchOnMap = () => {
  const {modalState, modalDispatch, appConfigState} = useContext(AppContext);
  const {mapState, mapDispatch} = useContext(MapContext);

  const [results, setResults] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');
  const [searchIn, setSearchIn] = useState({
    documento: true,
    nomeContribuinte: true,
    logradouro: true,
    inscricaoImobiliaria: true,
  });

  const renderTipoData = (d: any) => {
    switch (d) {
      case 'logradouro':
        return 'Logradouro';
      case 'edificacao':
        return 'Edificacao';
      case 'lote':
        return 'Lote';
      default:
        return 'Desconhecido';
    }
  };

  const handleKeyUp = (e: any) =>
    e.nativeEvent.keyCode === 13 && busca.length > 0 && handleSearch().then();
  const renderInscricao = (_: any, row: any) =>
    row.tipo === 'edificacao' || row.tipo === 'lote' ? row.valor['ID'] : '-';
  const getTipoCamadasBusca = () => new Set(results.map((e: any) => e.tipo));
  const renderField = (field: any) => (data: any, row: any) =>
    row.tipo === 'logradouro' && field === 'logradouro' ? data['nomeRua'] : data[field] || '-';

  const enableLayers = (enableAll: any, tipo: any) => {
    if (enableAll) {
      const ts = getTipoCamadasBusca();
      let layersToEnable: any[] = [];
      mapState.layers.forEach(
        l => Array.from(ts).some(tipo => l.tipoCamada === tipo) && layersToEnable.push(l)
      );
      mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_LAYERS, payload: layersToEnable});
    } else {
      let l = mapState.layers.filter(layer => layer.tipoCamada === tipo);
      mapDispatch({
        type: REDUCER_MAP_ACTIONS.SELECT_LAYERS,
        payload: [...Array.from(new Set([...mapState.selectedLayers, ...l]))],
      });
    }
  };

  const handleShowOnMap = (e: any) => {
    enableLayers(false, e.tipo);
    modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_BUSCA_MAPA});
    mapDispatch({
      type: REDUCER_MAP_ACTIONS.SELECT_SEARCH_AND_CENTER,
      payload: [e.valor.ID || e.valor.idGeometria || e.valor._id],
    });
  };

  const handleSelectAll = () => {
    const r = results?.map((e: any) => e.valor.ID || e.valor.idGeometria || e.valor._id);
    enableLayers(true, '');
    modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_BUSCA_MAPA});
    mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_SEARCH_AND_CENTER, payload: r});
  };

  const renderActions = (e: any) => {
    return (
      <UiFlex margin={'0 3px 0 0'}>
        <MdMap
          cursor="pointer"
          size="15px"
          title="Ver no mapa"
          color="green"
          onClick={() => handleShowOnMap(e)}
        />
      </UiFlex>
    );
  };

  const headers = [
    {title: 'Tipo', field: 'tipo', applyRender: renderTipoData},
    {title: 'Inscrição', field: 'idGeometria', applyRender: renderInscricao},
    {title: 'Contribuinte', field: 'valor', applyRender: renderField('CONTRIBUINTE')},
    {title: 'CPF/CNPJ', field: 'valor', applyRender: renderField('CPF_CNPJ')},
    {title: 'Logradouro', field: 'valor', applyRender: renderField('NOME_RUA')},
    {title: 'Bairro', field: 'valor', applyRender: renderField('BAIRRO')},
    {actions: renderActions},
  ];

  const renderResults = () => {
    if (isLoading) return <UiLoadingFullScreen>Carregando</UiLoadingFullScreen>;
    if (!results)
      return (
        <UiText color="gray" size=".9em" italic>
          Seus resultados aparecerão aqui
        </UiText>
      );

    return results.length === 0 ? (
      <UiText color="gray" size=".9em" italic>
        {' '}
        Nenhuma entrada encontrada{' '}
      </UiText>
    ) : (
      <UiTable headers={headers} rows={results} />
    );
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      let response = await API.searchInfo(appConfigState.idCidadeSelecionada, busca, searchIn);
      setResults(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition in={modalState.buscaNoMapa} timeout={duration}>
      {state => (
        <UiModal state={state} duration={duration}>
          <UiBox width={'90%'} maxWidth={'1000px'}>
            <UiFlex
              alignItems={'center'}
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText size="1.2em">Procurar</UiText>
              <MdClose
                cursor="pointer"
                size={'1.3em'}
                onClick={() => modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_BUSCA_MAPA})}
              />
            </UiFlex>
            <UiFlex alignItems="center" margin={'10px 0 0 0'}>
              <UiInputText
                placeholder="Busca"
                value={busca}
                onChange={(e: any) => {
                  setBusca(e.target.value);
                }}
                onKeyUp={handleKeyUp}
              />
              <UiButton primary disabled={busca.length < 1} onClick={handleSearch}>
                Procurar
              </UiButton>
            </UiFlex>
            <UiFlex alignItems={'center'} breakpoint={'500px'}>
              <UiFlex justifyContent={'center'}>
                <UiText size=".8em">Pesquisar em:</UiText>
              </UiFlex>
              <UiFlex>
                <UiCheckbox
                  label="Nome do contribuinte"
                  value={searchIn.nomeContribuinte}
                  onChange={() => {
                    setSearchIn({
                      ...searchIn,
                      nomeContribuinte: !searchIn.nomeContribuinte,
                    });
                  }}
                />
              </UiFlex>
              <UiFlex>
                <UiCheckbox
                  label="CPF/CNPJ"
                  value={searchIn.documento}
                  onChange={() => {
                    setSearchIn({
                      ...searchIn,
                      documento: !searchIn.documento,
                    });
                  }}
                />
              </UiFlex>
              <UiFlex>
                <UiCheckbox
                  label="Logradouro"
                  value={searchIn.logradouro}
                  onChange={() => {
                    setSearchIn({
                      ...searchIn,
                      logradouro: !searchIn.logradouro,
                    });
                  }}
                />
              </UiFlex>
              <UiFlex>
                <UiCheckbox
                  label="Inscrição imobiliária"
                  value={searchIn.inscricaoImobiliaria}
                  onChange={() => {
                    setSearchIn({
                      ...searchIn,
                      inscricaoImobiliaria: !searchIn.inscricaoImobiliaria,
                    });
                  }}
                />
              </UiFlex>
            </UiFlex>
            <ContainerTabela align={!results || results.length === 0 ? 'center' : 'flex-start'}>
              {renderResults()}
            </ContainerTabela>
            <UiFlex
              alignItems="center"
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              {results && (
                <>
                  <UiText color="gray" size=".8em">
                    {results.length} resultados encontrados
                  </UiText>
                  <UiButton
                    primary
                    small
                    disabled={!results || results.length === 0}
                    onClick={handleSelectAll}
                  >
                    Mostrar no mapa
                  </UiButton>
                </>
              )}
            </UiFlex>
          </UiBox>
        </UiModal>
      )}
    </Transition>
  );
};

const ContainerTabela: any = styled.div<any>`
  align-items: ${({align}) => align};
  display: flex;
  height: 400px;
  justify-content: center;
  overflow: auto;
`;

export default ModalSearchOnMap;
