import {useContext} from 'react';
import {IoMdArrowDropleft, IoMdArrowDropright} from 'react-icons/io';
import styled from 'styled-components';
import {MapContext} from '../../../../contexts/MapContext';
import {REDUCER_MAP_ACTIONS} from '../../../../reducers/map';
import {sessoes} from './sessoes';

export const UiSectionChooser = ({infos}: any) => {
  const {mapState, mapDispatch} = useContext(MapContext);

  const sessoesKeys = Object.keys(sessoes);

  const nextSessao = () => {
    let index = -1;
    sessoesKeys.forEach((e, i) => {
      if (e === mapState.selectedInfoSession) {
        index = i + 1;
      }
    });
    mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_INFO_SESSION, payload: sessoesKeys[index]});
  };

  const previousSessao = () => {
    let index = -1;
    sessoesKeys.forEach((e, i) => {
      if (e === mapState.selectedInfoSession) {
        index = i - 1;
      }
    });
    mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_INFO_SESSION, payload: sessoesKeys[index]});
  };

  const rendeSessaoNome = () => {
    if (infos.data?.tipoCamada === 'logradouro') {
      return (
        <Titulo bold size=".8em">
          Logradouro
        </Titulo>
      );
    }

    if (infos.data?.tipoCamada === 'geodesico') {
      return (
        <Titulo bold size=".8em">
          Marco geodésico
        </Titulo>
      );
    }
    return (
      <Titulo bold size=".8em">
        {sessoes[mapState.selectedInfoSession]}
      </Titulo>
    );
  };

  const renderPreviousButton = () => {
    if (infos.data.tipoCamada === 'logradouro' || infos.data.tipoCamada === 'geodesico') {
      return null;
    }

    if (sessoesKeys[0] !== mapState.selectedInfoSession) {
      return <IoMdArrowDropleft onClick={previousSessao} />;
    }

    return <div />;
  };

  const renderNextButton = () => {
    if (infos.data.tipoCamada === 'logradouro' || infos.data.tipoCamada === 'geodesico') {
      return null;
    }

    if (sessoesKeys[sessoesKeys.length - 1] !== mapState.selectedInfoSession) {
      return <IoMdArrowDropright onClick={nextSessao} />;
    }

    return <div />;
  };

  return (
    <SectionChooser
      align={
        infos.data.tipoCamada === 'logradouro' || infos.data.tipoCamada === 'geodesico'
          ? 'center'
          : 'space-between'
      }
    >
      {renderPreviousButton()} {rendeSessaoNome()} {renderNextButton()}
    </SectionChooser>
  );
};

const SectionChooser: any = styled.div<any>`
  align-items: center;
  display: flex;
  font-size: 1.2em;
  justify-content: ${({align}) => align};
  margin: 30px 20px 30px 20px;

  & > svg {
    cursor: pointer;
  }
`;

const Titulo: any = styled.div<any>`
  color: #333;
  font-size: 0.85em;
  font-weight: bold;
  user-select: none;
`;
