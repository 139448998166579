import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {configMenu} from 'utils/configMenus';
import {Template} from '../Template';

const ConfigPage = ({children}: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Template>
      <ContentPage>
        <SuperiorMenuBar>
          {configMenu?.map(s => (
            <SuperiorMenu
              key={s.id}
              onClick={() => navigate(s.url)}
              selected={location.pathname === s.url}
            >
              {s.title}
            </SuperiorMenu>
          ))}
        </SuperiorMenuBar>
        {children}
      </ContentPage>
    </Template>
  );
};

const SuperiorMenuBar: any = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e0e4e7;
  box-shadow: 1px 0 2px silver;
  display: flex;
  height: 40px;
  min-height: 40px;
  padding: 0 20px;
`;

const SuperiorMenu: any = styled.div<any>`
  align-items: center;
  border-bottom: ${({selected}) => (selected ? '2px solid #099cec' : '2px solid transparent')};
  color: ${({selected}) => (selected ? '#099cec' : '#555')};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 20px;
`;

const ContentPage: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default ConfigPage;
