export const registerMenu = [
    {
        id: 1,
        title: "Camadas",
        urls: [
            "/cadastros/camadas",
            "/cadastros/camadas/adicionar",
            "/cadastros/camadas/editar",
        ],
    },
    {
        id: 2,
        title: "Edificações",
        urls: ["/cadastros/edificacoes", "/cadastros/edificacoes/editar"],
    },
    {
        id: 3,
        title: "Logradouros",
        urls: ["/cadastros/logradouros", "/cadastros/logradouros/editar"],
    },
    {
        id: 4,
        title: "Lotes",
        urls: ["/cadastros/lotes", "/cadastros/lotes/editar"],
    },
    {
        id: 5,
        title: "Usuários",
        urls: [
            "/cadastros/usuarios",
            "/cadastros/usuarios/adicionar",
            "/cadastros/usuarios/editar",
        ],
    },
    {id: 6, title: "Arquivos", urls: ["/cadastros/arquivos"]},
    {id: 7, title: "Configurações", urls: ["/cadastros/configuracoes"]},
    {id: 8, title: "Auditoria", urls: ["/cadastros/audit"]},
    {id: 9, title: "Envios", urls: ["/cadastros/envios"]},
];
