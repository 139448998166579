import styled from 'styled-components';

const UiText: any = styled.span<any>`
  color: ${({color}) => color || 'black'};
  font-size: ${({size}) => size || '1em'};
  font-style: ${({italic}) => (italic ? 'italic' : 'normal')};
  font-weight: ${({bold}) => (bold ? '600' : 'normal')};
  margin: ${({margin}) => margin || '0'};
  text-align: ${({align}) => align || 'left'};
  user-select: none;
`;

export default UiText;
