import UiButton from 'components/UiButton';
import UiFlexItem from 'components/UiFlex';
import {SessionContext} from 'contexts/SessionContext';
import {useContext, useRef} from 'react';
import {REDUCER_SESSION_ACTIONS} from 'reducers/session';
import styled from 'styled-components';
import useOutsideAlerter from 'utils/useOutsideClick';

const UserBox = ({onClose}: any) => {
  const containerRef = useRef();
  useOutsideAlerter(containerRef, onClose, true);

  const {sessionDispatch} = useContext(SessionContext);

  const handleLogout = () => {
    localStorage.removeItem('token');
    sessionDispatch({type: REDUCER_SESSION_ACTIONS.INVALIDATE_SESSION});
    onClose();
    window.location.pathname = '/';
  };

  return (
    <Container ref={containerRef}>
      <UiFlexItem alignItems="center" justifyContent={'center'}>
        <UiButton onClick={handleLogout} primary>
          Sair
        </UiButton>
      </UiFlexItem>
    </Container>
  );
};

const Container: any = styled.div<any>`
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 6px;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 53px;
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 20;
`;

export default UserBox;
