import {MdCheck} from 'react-icons/md';
import styled from 'styled-components';

const UiCheckbox = ({label, value, onChange, height}: any) => {
  return (
    <Container onClick={onChange} height={height}>
      <Square value={value}>{value && <MdCheck />}</Square>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

const Container: any = styled.div<any>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${({height}) => height || 'auto'};
  justify-content: center;
  margin: 10px;
`;

const Square: any = styled.div<any>`
  align-items: center;
  border: 1px solid #099cec;
  color: #099cec;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
  min-width: 16px;
`;

const Label: any = styled.label`
  cursor: pointer;
  font-size: 0.8em;
  margin-left: 8px;
`;

export default UiCheckbox;

