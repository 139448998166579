import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

export const SelectCidadeComponent = () => {
    const {id} = useParams<any>();
    const navigate = useNavigate()
    useEffect(() => {
        id && navigate('/')
    }, [id, navigate]);
    return null;
};
