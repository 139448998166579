import UiFlexItem from 'components/UiFlex';
import UiBox from 'components/UiPanel';
import UiTable from 'components/UiTable';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import {useContext} from 'react';
import {SessionContext} from '../../../contexts/SessionContext';
import {useIntervalGet} from '../../../hooks/useIntervalGet';
import {renderDate} from '../../../utils/utils';

const ListaProcessos = () => {
  const {appConfigState} = useContext(AppContext);
  const {headers: headersReq} = useContext(SessionContext);

  const {data} = useIntervalGet(
    `/processos?cidade=${appConfigState.idCidadeSelecionada}`,
    5000,
    headersReq
  );

  const renderCidade = (id: string) => {
    let cs = appConfigState?.cidades.filter((e: any) => e.id === id);
    if (cs.length > 0) {
      return cs[0].nome;
    }
    return '';
  };

  const renderStatus = (status: string): string => {
    let s = status.toLowerCase();
    switch (s) {
      case 'success':
        return 'Sucesso';
      case 'error':
        return 'Erro';
      case 'processing':
        return 'Em execução';
      default:
        return '-';
    }
  };

  const headers = [
    {title: 'Descrição', field: 'descricao'},
    {title: 'Data de inicio', field: 'dataInicio', applyRender: renderDate},
    {
      title: 'Data de término',
      field: 'dataTermino',
      applyRender: renderDate,
    },
    {title: 'Status', field: 'status', applyRender: renderStatus},
    {title: 'Cidade', field: 'cidade', applyRender: renderCidade},
  ];

  return (
    <UiBox>
      <UiFlexItem direction="column" overflow={'auto'} maxHeight={'calc(100% - 150px)'}>
        <UiText bold>Processos</UiText>
        <UiTable headers={headers} rows={data} noDataText={'Nenhum processo recente encontrado'} />
      </UiFlexItem>
    </UiBox>
  );
};

export default ListaProcessos;
