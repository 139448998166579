import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {registerMenu} from 'utils/registerMenus';
import {Template} from '../Template';

const RegisterPage = ({children}: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Template>
      <ContentPage>
        <SuperiorMenuBar>
          {registerMenu?.map(s => (
            <SuperiorMenu
              key={s.id}
              onClick={() => {
                navigate(s.urls[0]);
              }}
              selected={s.urls.includes(location.pathname)}
            >
              {s.title}
            </SuperiorMenu>
          ))}
        </SuperiorMenuBar>
        {children}
      </ContentPage>
    </Template>
  );
};

const SuperiorMenuBar: any = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #e0e4e7;
  box-shadow: 1px 0 2px silver;
  display: flex;
  height: 40px;
  min-height: 40px;
  padding: 0 20px;
`;

const SuperiorMenu: any = styled.div<any>`
  align-items: center;
  border-bottom: ${({selected}) => (selected ? '2px solid #099cec' : '2px solid transparent')};
  color: ${({selected}) => (selected ? '#099cec' : '#555')};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 20px;
`;

const ContentPage: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default RegisterPage;
