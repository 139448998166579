import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditInformacoesComplementares = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>
                <UiInputText label="Adquirente" name={"ADQUIRENTE"} value={objeto["ADQUIRENTE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Beneficio lei" name={"BENEFICIO_LEI"} value={objeto["BENEFICIO_LEI"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Bloco" name={"BLOCO"} value={objeto["BLOCO"]} onChange={handleChangeField}/>
                <UiInputText label="Educação de menores" name={"EDUCACAO_MENORES"} value={objeto["EDUCACAO_MENORES"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Mensagem" name={"MENSAGEM"} value={objeto["MENSAGEM"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Nome cartório" name={"NOME_CARTORIO"} value={objeto["NOME_CARTORIO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número da conta corrente" name={"NUMERO_CONTA_CORRENTE"}
                             value={objeto["NUMERO_CONTA_CORRENTE"]} onChange={handleChangeField}/>
                <UiInputText label="Observação" name={"OBSERVACAO"} value={objeto["OBSERVACAO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditInformacoesComplementares
