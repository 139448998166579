import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body {
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }

  div {
    box-sizing: border-box;
  }

  .ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
    font-size: 12px;
  }

  .ol-tooltip-measure {
    opacity: 1;
    font-weight: bold;
  }

  .ol-tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
  }

  .ol-tooltip-measure:before,
  .ol-tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, 0.5);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
  }

  .ol-tooltip-static:before {
    border-top-color: #ffcc33;
  }

  .save-draw {
    display: flex;
    justify-content: flex-end;
    margin: 10px 10px 0 0;
  }

  .save-draw button {
    background-color: #099cec;
    border: 1px solid #099cec;
    border-radius: 3px;
    color: white;
    padding: 8px 20px;
  }

  .tiles {
    border: 1px solid green;
  }
  
  *{
    outline: none;
  }
`;
