import {AppContext} from "contexts/AppContext";
import {OpenLayersContext} from "contexts/OpenLayersContext";
import GeoJSON from "ol/format/GeoJSON";
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import {getStyleMapaTematico} from "pages/MapPage/openlayers/utils";
import {useCallback, useContext, useEffect} from "react";
import {REDUCER_OPENLAYERS_ACTIONS} from "../../../../reducers/openlayers";
import {useGet} from "../../../../hooks/useGet";
import {SessionContext} from "../../../../contexts/SessionContext";

const MapaTematicoLayer = () => {
    const {mapaTematicoState, appConfigState} = useContext(AppContext)
    const {openLayersState, openLayersDispatch} = useContext(OpenLayersContext);
    const {headers: headersReq} = useContext(SessionContext)

    const {data: geojson} = useGet(`/mapatematico?tipo=${mapaTematicoState.tipoCamada}&campo=${mapaTematicoState.campo}&cidade=${appConfigState.idCidadeSelecionada}`,
        headersReq, undefined, mapaTematicoState.tipoCamada && mapaTematicoState.campo && mapaTematicoState.show)

    const styleFunction = useCallback((e) => {
        if (mapaTematicoState.formaGeracaoDados === "faixa_valores") {
            let valor = e?.get(mapaTematicoState.campo)?.replace(/\D/g, '')
            let valorAjustado = +valor / 100
            let keys = Object.keys(mapaTematicoState.legenda).map(e => +e).sort((a, b) => a - b).reverse()

            let valoresComparadosLegenda = keys.filter(e => e <= valorAjustado)
            valoresComparadosLegenda = valoresComparadosLegenda.sort((a, b) => b - a)
            return getStyleMapaTematico(valoresComparadosLegenda[0], mapaTematicoState.legenda)
        }

        let valor = e.get(mapaTematicoState.campo)
        return getStyleMapaTematico(valor, mapaTematicoState.legenda)
    }, [mapaTematicoState.campo, mapaTematicoState.legenda, mapaTematicoState.formaGeracaoDados])

    useEffect(() => {
        if (openLayersState.map && geojson && mapaTematicoState.show) {
            try {
                let features: any = [];
                let geoJSON = new GeoJSON();
                geojson.features?.forEach((f: any) => {
                    try {
                        features.push(geoJSON.readFeature(f))
                    } catch (e) {
                        console.error(e);
                    }
                });

                let vectorSource = new VectorSource({features})
                let vectorLayer = new VectorLayer({
                    source: vectorSource,
                    style: styleFunction,
                });
                vectorLayer.setZIndex(10000)
                vectorLayer.set("NO_SELECTABLE", true)

                openLayersState.map.addLayer(vectorLayer);
                openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.REMOVE_LAYER_LOADING, payload: 'MAPA_TEMATICO'})

                return () => {
                    openLayersState.map.removeLayer(vectorLayer);
                };
            } catch (err) {
                console.error(err);
            }
        }
    }, [geojson, openLayersState.map, openLayersDispatch, styleFunction, mapaTematicoState.show]);

    return null;
};

export default MapaTematicoLayer;
