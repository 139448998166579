import React, {useContext, useState} from "react";
import {useGet} from "../../../hooks/useGet";
import {AppContext} from "../../../contexts/AppContext";
import {SessionContext} from "../../../contexts/SessionContext";
import {API} from "../../../utils/api";
import {useNavigate} from "react-router-dom";

export const AddLayerContext = React.createContext({});

const AddLayerProvider = ({children}: any) => {
    const {appConfigState} = useContext(AppContext)
    const {headers} = useContext(SessionContext)
    const navigate = useNavigate()

    const {data: layers} = useGet(`/cidade/${appConfigState.idCidadeSelecionada}/camadas`, headers)

    const [askToMerge, setAskToMerge] = useState(false)
    const [form, setForm] = useState<any>({
        nome: '',
        tipoCamada: '',
        espessuraLinha: 1,
        corLinha: '#FF0000',
        opacidadeLinha: 255,
        corFundo: '#d0ad2a',
        opacidadeFundo: 255,
    })

    const [idTmpFile, setIdTmpFile] = useState('')

    const tiposCamadas = [
        {key: "edificacao", value: "Edificação"},
        {key: "logradouro", value: "Logradouros"},
        {key: "lote", value: "Lote"},
        {key: "geodesico", value: "Marcos geodésicos"},
        {key: "perimetro", value: "Perimetro"},
        {key: "textual", value: "Textual"},
    ];

    const mergeLayer = async (mergeIn: string, keepIDs: string) => {
        try {
            await API.mergeLayer(idTmpFile, appConfigState.idCidadeSelecionada, mergeIn, keepIDs, headers);
            navigate("/cadastros/camadas");
        } catch (err) {
            console.error(err);
        }
    }

    const createNewLayer = async () => {
        try {
            await API.postLayer(form, idTmpFile, appConfigState.idCidadeSelecionada, headers);
            navigate("/cadastros/camadas");
        } catch (err) {
            console.error(err);
        }
    }

    const shouldAskToMerge = (): boolean => layers?.filter((l: any) => (l.tipoCamada === form.tipoCamada)
        && (l.tipoCamada === 'edificacao' || l.tipoCamada === 'logradouro' || l.tipoCamada === 'lote')).length > 0
    const getLayersSameType = (): any[] => layers?.filter((l: any) => l.tipoCamada === form.tipoCamada) || []

    return (
        <AddLayerContext.Provider value={{
            tiposCamadas, setForm, form, idTmpFile, setIdTmpFile, shouldAskToMerge,
            askToMerge, setAskToMerge, getLayersSameType, createNewLayer, mergeLayer
        }}>
            {children}
        </AddLayerContext.Provider>
    );
};

export default AddLayerProvider;
