import UiButton from 'components/UiButton'
import UiColorPicker from 'components/UiColorPicker'
import UiFlex from "components/UiFlex";
import UiInputText from 'components/UiInputText'
import UiBox from "components/UiPanel";
import UiRange from 'components/UiRange'
import UiSelect from 'components/UiSelect'
import UiText from 'components/UiText'
import {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import AddLayerProvider, {AddLayerContext} from './AddLayerContext'
import PreviewLayer from './PreviewLayer'
import {useFile} from "../../../hooks/useFile";
import {API} from "../../../utils/api";
import {AppContext} from "../../../contexts/AppContext";
import {SessionContext} from "../../../contexts/SessionContext";
import {ModalAskMergeLayer} from "../../../modals/ModalAskMergeLayer";
import RegisterPage from "../index";

const AddLayerPage = () => {
    const {
        tiposCamadas,
        setForm,
        form,
        setIdTmpFile, askToMerge, setAskToMerge
    } = useContext<any>(AddLayerContext)
    const {appConfigState} = useContext(AppContext)
    const {headers: headersReq} = useContext(SessionContext)

    const location: any = useLocation()
    const navigate = useNavigate()
    const [pickingFiles, setPickingFiles] = useState(false)
    const [loading, setLoading]=useState(false)
    const files = useFile(pickingFiles, ".shp,.shx,.dbf,.qpj,.prj", true)

    const getButtonText = () => form.id ? "Salvar" : "Enviar"

    useEffect(() => {
        if (location.state) {
            const {state} = location
            setForm(state)
        }
    }, [location, setForm])

    const handleChangeForm = (event: any) => {
        const {name, value} = event.target
        if (name === "espessuraLinha" || name === "opacidadeLinha" || name === "opacidadeFundo") {
            setForm({...form, [name]: +value})
            return
        }

        setForm({...form, [name]: value})
    }

    const uploadFiles = async () => {
        setLoading(true)
        try {
            if (form.id) {
                await API.updateLayer(appConfigState.idCidadeSelecionada, form.id, form, headersReq)
                navigate("/cadastros/camadas")
            } else {
                let id = ''
                if (files?.length) {
                    for (let i = 0; i < files.length; i++) {
                        const fd = new FormData()
                        fd.append("file", files[i])
                        let resp: any = await API.uploadTmpFile(fd, id, appConfigState.idCidadeSelecionada, headersReq)
                        id = resp.data.id
                    }

                    setPickingFiles(false)
                }

                await API.convertShapefileToGeoJSON(id, appConfigState.idCidadeSelecionada, headersReq)
                setIdTmpFile(id)
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
        }finally {
            setLoading(false)
        }
    }

    const renderFilesName = () => {
        if (files?.length) {
            return (
                <UiFlex direction={"column"} cursor={"pointer"}>
                    {Array.from(files).map((f) => (
                        <UiFlex margin={"4px 0"} key={f.name}>
                            <UiText size={".9em"}>
                                {f.name}
                            </UiText>
                        </UiFlex>
                    ))}
                </UiFlex>
            )
        }
    }

    const isSendDisabled = () => form.id ? !form.nome || !form.tipoCamada : !files?.length || !form.nome || !form.tipoCamada

    return (
        <UiFlex>
            <UiBox>
                <UiText bold>
                    {form.id ? 'Editar' : 'Adicionar'} camada
                </UiText>
                <UiFlex>
                    <UiInputText label="Nome" value={form.nome} onChange={handleChangeForm} placeholder='Nome da camada'
                                 name={"nome"}/>
                    <UiSelect options={tiposCamadas} onChange={handleChangeForm} value={form.tipoCamada}
                              label="Tipo de camada" placeholder='Selecione o tipo da camada'
                              selectText={"Selecione o tipo da camada"} name={"tipoCamada"}
                              disabled={form.id}/>
                </UiFlex>
                <UiFlex>
                    <UiRange label="Espessura da linha" min={0} max={10} value={form.espessuraLinha}
                             onChange={handleChangeForm} name={"espessuraLinha"}/>
                    <UiColorPicker label="Cor da linha" value={form.corLinha} onChange={handleChangeForm}
                                   name={"corLinha"}/>
                    <UiRange label="Opacidade da linha" min={0} max={255} value={form.opacidadeLinha}
                             onChange={handleChangeForm} name={"opacidadeLinha"}/>
                </UiFlex>
                <UiFlex>
                    <UiColorPicker label="Cor do fundo" value={form.corFundo} onChange={handleChangeForm}
                                   name={"corFundo"}/>
                    <UiRange label="Opacidade do fundo" min={0} max={255} value={form.opacidadeFundo}
                             onChange={handleChangeForm} name={"opacidadeFundo"}/>
                </UiFlex>
                <UiFlex hidden={form.id}>
                    <UiBox onClick={() => setPickingFiles(true)}>
                        {
                            files?.length ? renderFilesName() : (
                                <UiFlex justifyContent={"center"} cursor={"pointer"}>
                                    <UiText size={".9em"}>
                                        Clique aqui para enviar seus arquivos
                                    </UiText>
                                </UiFlex>
                            )
                        }
                    </UiBox>
                </UiFlex>
                <UiFlex margin="20px 0 0 0" justifyContent='flex-end'>
                    <UiButton primary onClick={uploadFiles} disabled={isSendDisabled()}
                              loading={loading}>
                        {getButtonText()}
                    </UiButton>
                </UiFlex>
            </UiBox>

            <PreviewLayer editing={form.id}/>
            <ModalAskMergeLayer show={askToMerge} onClose={() => setAskToMerge(false)}/>
        </UiFlex>
    )
}

const AddLayerPageContainer = () => {
    return (
        <RegisterPage>
            <AddLayerProvider>
                <AddLayerPage/>
            </AddLayerProvider>
        </RegisterPage>
    )
}

export default AddLayerPageContainer
