import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import OpenLayersProvider, {OpenLayersContext} from 'contexts/OpenLayersContext';
import InfosSection from 'pages/MapPage/infos/InfosSection';
import {ButtonControl} from 'pages/MapPage/openlayers/controls/ButtonControl';
import ButtonIconControl from 'pages/MapPage/openlayers/controls/ButtonIconControl';
import ThematicMapLayer from 'pages/MapPage/openlayers/layers/MapaTematicoLayer';
import {VantImageLayer} from 'pages/MapPage/openlayers/layers/VantImageLayer';
import BGLayersToolbarModal from 'pages/MapPage/openlayers/mapmodals/BGLayersToolbarModal';
import DrawToolbarModal from 'pages/MapPage/openlayers/mapmodals/DrawToolbarModal';
import LayersToolbarModal from 'pages/MapPage/openlayers/mapmodals/LayersToolbarModal';
import LegendaMapaTematicoModal from 'pages/MapPage/openlayers/mapmodals/LegendaMapaTematicoModal';
import MeasureToolbarModal from 'pages/MapPage/openlayers/mapmodals/MeasureToolbarModal';
import OrtoimagemLayersModal from 'pages/MapPage/openlayers/mapmodals/OrtoimagemLayersModal';
import ThematicMapToolbarModal from 'pages/MapPage/openlayers/mapmodals/ThematicMapToolbarModal';
import {SideToolbar} from 'pages/MapPage/openlayers/SideToolbar';
import {useContext} from 'react';
import {FaDrawPolygon, FaMap} from 'react-icons/fa';
import {FiFilter} from 'react-icons/fi';
import {MdDashboard, MdLayers, MdPhoto, MdPrint, MdSearch} from 'react-icons/md';
import {RiPinDistanceLine} from 'react-icons/ri';
import {REDUCER_MAPA_TEMATICO_ACTIONS} from 'reducers/mapatematico';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import {UiLoading} from '../../../components/UiLoading';
import {IOpenLayersContext} from '../../../contexts/IContexts';
import {SessionContext} from '../../../contexts/SessionContext';
import {REDUCER_MAP_ACTIONS} from '../../../reducers/map';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../reducers/openlayers';
import ButtonChangeSidebarType from './controls/ButtonChangeSidebarType';
import SaveControl from './controls/SaveControl';
import {TopRightMapContainer} from './controls/TopRightMapContainer';
import InfoBar from './InfoBar';
import BackgroundLayer from './layers/BackgroundLayer';
import {DeleteGeometry} from './layers/DeleteGeometry';
import {DrawLayer} from './layers/DrawLayer';
import GeoJsonLayer from './layers/GeoJsonLayer';
import LimiteCidadeLayer from './layers/LimiteCidadeLayer';
import MeasureLayer from './layers/MeasureLayer';
import SelectLayer from './layers/SelectLayer';

const Map = () => {
  const {openLayersState, openLayersDispatch} = useContext<IOpenLayersContext>(OpenLayersContext);
  const {appConfigState, loadingState, modalDispatch, mapaTematicoDispatch, mapaTematicoState} =
    useContext(AppContext);
  const {sessionState} = useContext(SessionContext);

  const {mapState, mapDispatch} = useContext(MapContext);

  const toggleLayersSection = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_LAYERS_SECTION});
  const openBackgroundSection = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.OPEN_BACKGROUND_SECTION});
  const toggleOrtoimagemSection = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_ORTOIMAGEM_SECTION});
  const openBuscaMapa = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_BUSCA_MAPA});
  const openFiltroMapa = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_FILTRO_MAPA});
  const openMapaTematico = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_MAPA_TEMATICO});
  const desativarMapaTematico = () =>
    mapaTematicoDispatch({type: REDUCER_MAPA_TEMATICO_ACTIONS.DISABLE_MAPA_TEMATICO});
  const desativarDesenho = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.DISABLE_DRAW});

  const limparFiltro = () => mapDispatch({type: REDUCER_MAP_ACTIONS.CLEAR_FILTRO});

  const isCancelarAtivo = () =>
    openLayersState.activeDrawTool ||
    openLayersState.activeMeasureTool ||
    openLayersState.deletingGeometry;

  return (
    <Container>
      {(loadingState.consultaPrevia || loadingState.layers.length > 0) && (
        <Loading>
          <UiLoading />
        </Loading>
      )}

      <MapContainer id="mapElement">
        <ButtonIconControl
          onClick={toggleLayersSection}
          title={'Camadas'}
          icon={MdLayers}
          position={1}
          disabled={!mapState.layers?.length}
          iconSize={'1.7em'}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
        />
        <ButtonIconControl
          onClick={openBackgroundSection}
          title={'Camada de fundo'}
          icon={FaMap}
          position={2}
          iconSize={'1.4em'}
          disabled={false}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
        />
        <ButtonIconControl
          onClick={toggleOrtoimagemSection}
          title={'Ortoimagem'}
          icon={MdPhoto}
          position={3}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
          disabled={
            !appConfigState.cidadeConfig?.hasMDE && !appConfigState.cidadeConfig?.hasOrtoimage
          }
          iconSize={'1.7em'}
        />
        <ButtonIconControl
          onClick={openBuscaMapa}
          title={'Procurar'}
          icon={MdSearch}
          position={4}
          disabled={false}
          iconSize={'1.7em'}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
        />
        <ButtonIconControl
          onClick={openFiltroMapa}
          title={'Filtrar'}
          icon={FiFilter}
          position={5}
          disabled={false}
          iconSize={'1.7em'}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
        />
        <ButtonIconControl
          onClick={openMapaTematico}
          title={'Mapa temático'}
          icon={MdDashboard}
          position={6}
          disabled={false}
          iconSize={'1.7em'}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
        />
        <ButtonIconControl
          onClick={() => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_PRINT_MAP})}
          title={'Imprimir'}
          icon={MdPrint}
          position={7}
          disabled={false}
          iconSize={'1.7em'}
          show={
            !openLayersState.activeMeasureTool &&
            !openLayersState.activeDrawTool &&
            !openLayersState.deletingGeometry
          }
        />
        <ButtonIconControl
          onClick={() =>
            openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.OPEN_MEASURE_SECTION})
          }
          disabled={false}
          iconSize={'1.7em'}
          title={'Medição'}
          icon={RiPinDistanceLine}
          position={8}
          show
        />
        <ButtonIconControl
          onClick={() => openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.OPEN_DRAW_SECTION})}
          title={'Desenhar'}
          icon={FaDrawPolygon}
          position={9}
          disabled={mapState.selectedLayers.length === 0}
          iconSize={'1.7em'}
        />

        <VantImageLayer />
        <ButtonChangeSidebarType />

        <TopRightMapContainer show={isCancelarAtivo()}>
          <ButtonControl
            onClick={limparFiltro}
            show={mapState.filteredGeometries.length}
            text={'Limpar filtro'}
          />
          <ButtonControl
            onClick={desativarMapaTematico}
            show={Object.keys(mapaTematicoState.legenda).length}
            text={'Desativar mapa temático'}
          />
          <ButtonControl onClick={desativarDesenho} show={isCancelarAtivo()} text={'Cancelar'} />
          <SaveControl
            show={
              sessionState.usuario &&
              (isCancelarAtivo() ||
                Object.keys(mapaTematicoState.legenda).length ||
                mapState.filteredGeometries.length)
            }
          />
        </TopRightMapContainer>

        <DeleteGeometry />

        <BackgroundLayer />
        <MeasureLayer />

        <LimiteCidadeLayer geojson={appConfigState.perimetro} />
        <ThematicMapLayer />
        {!openLayersState.activeDrawTool && <SelectLayer />}
        {openLayersState.activeDrawTool && <DrawLayer />}
        {mapState.selectedLayers.map((c: any) => (
          <GeoJsonLayer layer={c} key={c.id} />
        ))}
      </MapContainer>
      <InfoBar />
    </Container>
  );
};

export const OpenLayersWrapper = () => {
  return (
    <OpenLayersProvider>
      <SideToolbar />
      <LayersToolbarModal />
      <BGLayersToolbarModal />
      <MeasureToolbarModal />
      <DrawToolbarModal />
      <OrtoimagemLayersModal />
      <ThematicMapToolbarModal />
      <LegendaMapaTematicoModal />

      <Map />

      <InfosSection />
    </OpenLayersProvider>
  );
};

const MapContainer: any = styled.div`
  border: 1px solid #88a9bc;
  height: 100%;
`;

const Container: any = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 4px);
  margin: 1px;
  position: relative;
`;

const Loading: any = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background-color: white;
  opacity: 0.7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
