import styled from 'styled-components';

const UiModal = ({children, state, duration}: any) => {
  return (
    <Modal state={state}>
      <Container state={state} duration={duration}>
        {children}
      </Container>
    </Modal>
  );
};

const Modal: any = styled.div<any>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: ${({state}) => (state === 'entering' || state === 'entered' ? 'flex' : 'none')};
  align-items: center;
  background-color: #f7f8f9cc;
  justify-content: center;
  z-index: 10000;
`;

const Container: any = styled.div<any>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  transform: ${({state}) => (state === 'entered' ? 'translateY(0)' : 'translateY(100%)')};
  transition: ${({duration}) => `transform ${duration}ms ease-out`};
`;

export default UiModal;

