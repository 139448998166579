import UiModal from 'components/UiModal';
import UiBox from 'components/UiPanel';
import {MdClose} from 'react-icons/md';
import {Transition} from 'react-transition-group';
import UiButton from '../components/UiButton';
import UiFlex from '../components/UiFlex';
import UiInputText from '../components/UiInputText';
import UiText from '../components/UiText';

const duration = 100;

export const ModalAskInfo = ({
  show,
  onClose,
  title,
  continueLabel,
  cancelLabel,
  continueAction,
  infoLabel,
  data,
  onChangeData,
}: any) => {
  return (
    <Transition in={show} timeout={duration}>
      {state => (
        <UiModal state={state} duration={duration}>
          <UiBox width={'90%'} maxWidth={'400px'}>
            <UiFlex
              alignItems={'center'}
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText size="1.2em">{title}</UiText>
              <MdClose cursor="pointer" onClick={onClose} size={'1.3em'} />
            </UiFlex>
            <UiFlex padding={'10px 0 0 0'}>
              <UiInputText
                value={data}
                onChange={(e: any) => onChangeData(e.target.value)}
                label={infoLabel}
              />
            </UiFlex>
            <UiFlex justifyContent={'flex-end'}>
              <UiFlex maxWidth={'fit-content'} padding={'0 0 0 5px'}>
                <UiButton secondary color={'orange'} onClick={onClose}>
                  {cancelLabel}
                </UiButton>
              </UiFlex>
              <UiFlex maxWidth={'fit-content'} padding={'0 0 0 5px'}>
                <UiButton primary onClick={continueAction} disabled={data.trim() === ''}>
                  {continueLabel}
                </UiButton>
              </UiFlex>
            </UiFlex>
          </UiBox>
        </UiModal>
      )}
    </Transition>
  );
};
