import UiButton from 'components/UiButton';
import UiFlex from 'components/UiFlex';
import UiModal from 'components/UiModal';
import UiBox from 'components/UiPanel';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import html2canvas from 'html2canvas';
import {useContext, useEffect, useRef, useState} from 'react';
import {MdClose} from 'react-icons/md';
import {Transition} from 'react-transition-group';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import {OpenLayersContext} from '../contexts/OpenLayersContext';
import {getPrintFromMap} from '../utils/openlayers';

const duration = 100;

const ModalPrintMap = () => {
  const {modalState, modalDispatch} = useContext(AppContext);
  const {openLayersState} = useContext(OpenLayersContext);

  const [prancha, setPrancha] = useState('');
  const [img, setImg] = useState('');

  const inputFile = useRef<any>();
  const imageRef = useRef<any>();

  useEffect(() => {
    const getContent = async () => {
      const t = await getPrintFromMap(openLayersState.map);
      if (t) {
        setImg(t.toDataURL());
      }
    };

    if (modalState.impressao) {
      getContent().then();
    }

    return () => {
      setImg('');
    };
  }, [openLayersState.map, modalState.impressao, prancha]);

  const handleLoadedFile = (e: any) => {
    html2canvas(e.target, {logging: false}).then(async canvas => {
      const t = await getPrintFromMap(openLayersState.map);
      if (t) {
        const mapContext = t.getContext('2d');
        if (mapContext) {
          mapContext.drawImage(canvas, 10, 10);
        }
        setImg(t.toDataURL());
      }
    });
  };

  const handleOpenDialog = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleChooseFile = (e: any) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setPrancha(reader.result as string);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePrint = () => {
    if (imageRef.current) {
      const myWindow = window.open('', 'mywindow');
      if (myWindow) {
        myWindow.document.write('<html lang="pt-BR"><head><title>Impressão</title></head>');
        myWindow.document.write('<body>');
        myWindow.document.write(`<img alt="arquivo a ser impresso" src="${img}"/>`);
        myWindow.document.write('</body></html>');
        myWindow.print();
        myWindow.close();
      }
    }
  };

  return (
    <Transition in={modalState.impressao} timeout={duration}>
      {state => (
        <UiModal state={state} duration={duration}>
          <UiBox width={'90%'} maxWidth={'1000px'}>
            <UiFlex
              alignItems={'center'}
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText size="1.2em">Imprimir mapa</UiText>
              <MdClose
                cursor="pointer"
                onClick={() => modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_PRINT_MAP})}
                size={'1.3em'}
              />
            </UiFlex>
            <UiFlex alignItems={'center'} gap={'10px'} direction={'column'}>
              <UiFlex width={'70%'}>
                <Imagem src={img} alt={'Imagem que será impressa'} ref={imageRef} />
              </UiFlex>
              <UiFlex justifyContent={'center'} width={'auto'}>
                <ContainerPrancha onClick={handleOpenDialog}>
                  Clique aqui para enviar seu logo
                </ContainerPrancha>
                <ImagemPrancha src={prancha} onLoad={handleLoadedFile} />
              </UiFlex>
            </UiFlex>
            <UiFlex
              justifyContent={'flex-end'}
              margin={'10px 10px 0 0'}
              width={'calc(100% - 10px)'}
            >
              <UiButton primary onClick={handlePrint}>
                Imprimir
              </UiButton>
            </UiFlex>
          </UiBox>
          <input
            type={'file'}
            ref={inputFile}
            style={{display: 'none'}}
            accept={'image/*'}
            onChange={handleChooseFile}
          />
        </UiModal>
      )}
    </Transition>
  );
};

const ContainerPrancha: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;

  border: 1px solid silver;
  font-size: 0.9em;
  margin: 0 10px;
  padding: 20px;
  width: 200px;
`;

const ImagemPrancha: any = styled.img`
  border: 1px solid silver;
  height: 100px;
  width: auto;
  display: flex;
  position: absolute;
  left: -2000px;
`;

const Imagem: any = styled.img<any>`
  border: 1px solid silver;
  width: calc(100%);
`;

export default ModalPrintMap;
