import {UiInfo} from 'components/UiInfo'

const InfosValores = ({infos}: any) => (
    <>
        <UiInfo label="Alíquota" value={infos["ALIQUOTA"]}/>
        <UiInfo label="Alíquota calculo" value={infos["ALIQUOTA_CALCULO"]}/>
        <UiInfo label="Alíquota do imposto" value={infos["ALIQUOTA_IMPOSTO"]}/>
        <UiInfo label="Débito automático" value={infos["DEBITO_AUTOMATICO"]}/>
        <UiInfo label="Valor da coleta de lixo" value={infos["VALOR_COLETA_LIXO"]} dinheiro/>
        <UiInfo label="Valor habite-se" value={infos["VALOR_HABITESSE"]} dinheiro/>
        <UiInfo label="Valor do IPTU" value={infos["VALOR_IPTU"]} dinheiro/>
        <UiInfo label="Valor Venal" value={infos["VALOR_VENAL"]} dinheiro/>
        <UiInfo label="VALOR VENAL EDIFICAÇÃO" value={infos["VALOR_VENAL_EDIFICACAO"]} dinheiro/>
        <UiInfo label="Valor Venal do Imóvel" value={infos["VALOR_VENAL_IMOVEL"]} dinheiro/>
        <UiInfo label="Valor Venal do Prédio" value={infos["VALOR_VENAL_PREDIO"]} dinheiro/>
        <UiInfo label="Valor Venal Terreno" value={infos["VALOR_VENAL_TERRENO"]} dinheiro/>
        <UiInfo label="VALOR VENAL TERRITORIAL" value={infos["VALOR_VENAL_TERRITORIAL"]} dinheiro/>
        <UiInfo label="Valor Venal Total" value={infos["VALOR_VENAL_TOTAL"]} dinheiro/>
    </>
)

export default InfosValores
