import UiButton from 'components/UiButton';
import UiFlex from 'components/UiFlex';
import UiInputPassword from 'components/UiInputPassword';
import UiInputText from 'components/UiInputText';
import UiText from 'components/UiText';
import {SessionContext} from 'contexts/SessionContext';
import {useContext, useRef, useState} from 'react';
import {REDUCER_SESSION_ACTIONS} from 'reducers/session';
import styled from 'styled-components';
import {API} from 'utils/api';
import useOutsideAlerter from 'utils/useOutsideClick';
import {AppContext} from '../../contexts/AppContext';

const LoginBox = ({onClose, state}: any) => {
  const [form, setForm] = useState({usuario: '', senha: ''});
  const [error, setError] = useState(false);
  const {sessionDispatch} = useContext(SessionContext);
  const {appConfigState} = useContext(AppContext);

  const containerRef = useRef();
  useOutsideAlerter(containerRef, onClose, true);

  const handleLogin = async () => {
    try {
      const resp: any = await API.login(form, appConfigState.idCidadeSelecionada);
      sessionDispatch({type: REDUCER_SESSION_ACTIONS.SET_TOKEN, payload: resp.data.id});
      localStorage.setItem('token', resp.data.id);
      onClose();
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  const handleKeyUp = (e: any) => {
    if (e.nativeEvent.keyCode === 13) {
      if (form.usuario.trim() !== '' && form.senha.trim() !== '') {
        handleLogin().then();
      }
    } else {
      setError(false);
    }
  };

  return (
    <Container ref={containerRef} state={state}>
      <UiInputText
        label="Usuário"
        value={form.usuario}
        onChange={(e: any) => {
          setForm({...form, usuario: e.target.value});
        }}
        onKeyUp={handleKeyUp}
        autoFocus
      />
      <UiInputPassword
        label="Senha"
        value={form.senha}
        onChange={(e: any) => {
          setForm({...form, senha: e.target.value});
        }}
        onKeyUp={handleKeyUp}
      />

      {error && (
        <UiFlex justifyContent={'center'}>
          <UiText color="red" size=".8em">
            Usuário ou senha invalido
          </UiText>
        </UiFlex>
      )}
      <UiFlex justifyContent="center" margin="20px 0 0 0">
        <UiButton
          primary
          onClick={handleLogin}
          disabled={form.usuario.trim() === '' || form.senha.trim() === ''}
        >
          Entrar
        </UiButton>
      </UiFlex>
    </Container>
  );
};

const Container: any = styled.div<any>`
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 4px silver;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 53px;
  width: 350px;
  z-index: 20;

  opacity: ${({state}) => (state === 'entered' ? '1' : '0')};
  transform: ${({state}) => (state === 'entered' ? 'scaleY(1)' : 'scaleY(0)')};
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  transform-origin: top;
`;

export default LoginBox;
