import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditInformacoesGenericas = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>
                <UiInputText label="Código" name={"CODIGO"} value={objeto["CODIGO"]} onChange={handleChangeField}/>
                <UiInputText label="Código da agência" name={"CODIGO_AGENCIA"} value={objeto["CODIGO_AGENCIA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Código do bairro" name={"CODIGO_BAIRRO"} value={objeto["CODIGO_BAIRRO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Código do bairro do corresponsável" name={"CODIGO_BAIRRO_CORRESPONSAVEL"}
                             value={objeto["CODIGO_BAIRRO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Código do banco" name={"CODIGO_BANCO"} value={objeto["CODIGO_BANCO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Código do contribuinte" name={"CODIGO_CONTRIBUINTE"}
                             value={objeto["CODIGO_CONTRIBUINTE"]} onChange={handleChangeField}/>
                <UiInputText label="Código do corresponsável" name={"CODIGO_CORRESPONSAVEL"}
                             value={objeto["CODIGO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Código do distrito" name={"CODIGO_DISTRITO"} value={objeto["CODIGO_DISTRITO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Código do imóvel" name={"CODIGO_IMOVEL"} value={objeto["CODIGO_IMOVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Código do logradouro" name={"CODIGO_LOGRADOURO"} value={objeto["CODIGO_LOGRADOURO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Código do logradouro do corresponsável" name={"CODIGO_LOGRADOURO_CORRESPONSAVEL"}
                             value={objeto["CODIGO_LOGRADOURO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="Ficha mãe" name={"FICHA_MAE"} value={objeto["FICHA_MAE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Fração ideal M" name={"FRACAO_IDEAL_M"} value={objeto["FRACAO_IDEAL_M"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Fração ideal" name={"FRACAO_IDEAL"} value={objeto["FRACAO_IDEAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Fração ideal P" name={"FRACAO_IDEAL_P"} value={objeto["FRACAO_IDEAL_P"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Fração do terreno" name={"FRACAO_TERRENO"} value={objeto["FRACAO_TERRENO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ID bairro" name={"ID_BAIRRO"} value={objeto["ID_BAIRRO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ID bairro corresponsável" name={"ID_BAIRRO_CORRESPONSAVEL"}
                             value={objeto["ID_BAIRRO_CORRESPONSAVEL"]} onChange={handleChangeField}/>
                <UiInputText label="ID base englobado" name={"ID_BASE_ENGLOBADO"} value={objeto["ID_BASE_ENGLOBADO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ID Rua" name={"ID_RUA"} value={objeto["ID_RUA"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="ID rua correspondecia" name={"ID_RUA_CORRESPONDENCIA"}
                             value={objeto["ID_RUA_CORRESPONDENCIA"]} onChange={handleChangeField}/>
                <UiInputText label="ID setor" name={"ID_SETOR"} value={objeto["ID_SETOR"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ID trecho" name={"ID_TRECHO"} value={objeto["ID_TRECHO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ID único" name={"ID_UNICO"} value={objeto["ID_UNICO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="ID único responsável" name={"ID_UNICO_REPONSAVEL"}
                             value={objeto["ID_UNICO_REPONSAVEL"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="ID zona" name={"ID_ZONA"} value={objeto["ID_ZONA"]} onChange={handleChangeField}/>
                <UiInputText label="Info adicional" name={"INFO_ADICIONAL"} value={objeto["INFO_ADICIONAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Sublote" name={"SUBLOTE"} value={objeto["SUBLOTE"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditInformacoesGenericas
