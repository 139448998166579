import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditInformacoesEdificacoes = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>
                <UiInputText label="Acabamento" name={"ACABAMENTO"} value={objeto["ACABAMENTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Apartamento" name={"APARTAMENTO"} value={objeto["APARTAMENTO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Área construida unidade" name={"AREA_CONSTRUIDA_UNIDADE"}
                             value={objeto["AREA_CONSTRUIDA_UNIDADE"]} onChange={handleChangeField}/>
                <UiInputText label="Área total de construção rural" name={"AREA_TOTAL_CONSTRUCAO_RURAL"}
                             value={objeto["AREA_TOTAL_CONSTRUCAO_RURAL"]} onChange={handleChangeField}/>
                <UiInputText label="Área total construida" name={"AREA_TOTAL_CONSTRUIDA"}
                             value={objeto["AREA_TOTAL_CONSTRUIDA"]} onChange={handleChangeField}/>
                <UiInputText label="Área tributável" name={"AREA_TRIBUTAVEL"} value={objeto["AREA_TRIBUTAVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Caracteristica" name={"CARACTERISTICAS"} value={objeto["CARACTERISTICAS"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>

                <UiInputText label="Caracterização" name={"CARACTERIZACAO"} value={objeto["CARACTERIZACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Cobertura" name={"COBERTURA"} value={objeto["COBERTURA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Cozinha" name={"COZINHA"} value={objeto["COZINHA"]} onChange={handleChangeField}/>
                <UiInputText label="Delimitações" name={"DELIMITACOES"} value={objeto["DELIMITACOES"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Especie" name={"ESPECIE"} value={objeto["ESPECIE"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Esquadrias" name={"ESQUADRIAS"} value={objeto["ESQUADRIAS"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Estado de conservação" name={"ESTADO_CONSERVACAO"}
                             value={objeto["ESTADO_CONSERVACAO"]} onChange={handleChangeField}/>
                <UiInputText label="Estado de conservação do pavimento" name={"ESTADO_CONSERVACAO_PAVIMENTO"}
                             value={objeto["ESTADO_CONSERVACAO_PAVIMENTO"]} onChange={handleChangeField}/>
                <UiInputText label="Estrutura" name={"ESTRUTURA"} value={objeto["ESTRUTURA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Face" name={"FACE"} value={objeto["FACE"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Fator localização" name={"FATOR_LOCALIZACAO"} value={objeto["FATOR_LOCALIZACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Forro" name={"FORRO"} value={objeto["FORRO"]} onChange={handleChangeField}/>
                <UiInputText label="Fundações" name={"FUNDACOES"} value={objeto["FUNDACOES"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Gabinete sanitário" name={"GABINETE_SANITARIO"} value={objeto["GABINETE_SANITARIO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Instalação elétrica" name={"INSTALACAO_ELETRICA"}
                             value={objeto["INSTALACAO_ELETRICA"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Instalação sanitária ligada" name={"INSTALACAO_SANITARIA_LIGADA"}
                             value={objeto["INSTALACAO_SANITARIA_LIGADA"]} onChange={handleChangeField}/>
                <UiInputText label="Laudo" name={"LAUDO"} value={objeto["LAUDO"]} onChange={handleChangeField}/>
                <UiInputText label="Limitação" name={"LIMITACAO"} value={objeto["LIMITACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Limite da testada" name={"LIMITE_TESTADA"} value={objeto["LIMITE_TESTADA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Locação" name={"LOCACAO"} value={objeto["LOCACAO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Localização" name={"LOCALIZACAO"} value={objeto["LOCALIZACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Loteamento" name={"LOTEAMENTO"} value={objeto["LOTEAMENTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Murado" name={"MURADO"} value={objeto["MURADO"]} onChange={handleChangeField}/>
                <UiInputText label="Muro" name={"MURO"} value={objeto["MURO"]} onChange={handleChangeField}/>
                <UiInputText label="Nível da rua" name={"NIVEL_RUA"} value={objeto["NIVEL_RUA"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Nome do condominio" name={"NOME_CONDOMINIO"} value={objeto["NOME_CONDOMINIO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número pavimentação" name={"NUMERO_PAVIMENTACAO"}
                             value={objeto["NUMERO_PAVIMENTACAO"]} onChange={handleChangeField}/>
                <UiInputText label="Padrão de construção" name={"PADRAO_CONSTRUCAO"} value={objeto["PADRAO_CONSTRUCAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número de pavimentos" name={"NUMERO_PAVIMENTOS"} value={objeto["NUMERO_PAVIMENTOS"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número de testada convertida" name={"NUMERO_TESTADA_CONVERTIDA"}
                             value={objeto["NUMERO_TESTADA_CONVERTIDA"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Numero de unidades" name={"NUMERO_UNIDADES"} value={objeto["NUMERO_UNIDADES"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Ocupação" name={"OCUPACAO"} value={objeto["OCUPACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Padrão de construção" name={"PADRAO_CONSTRUCAO"} value={objeto["PADRAO_CONSTRUCAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Paredes" name={"PAREDES"} value={objeto["PAREDES"]} onChange={handleChangeField}/>
                <UiInputText label="Patrimonio" name={"PAVIMENTACAO"} value={objeto["PAVIMENTACAO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Pintura" name={"PINTURA"} value={objeto["PINTURA"]} onChange={handleChangeField}/>
                <UiInputText label="Piscina" name={"PISCINA"} value={objeto["PISCINA"]} onChange={handleChangeField}/>
                <UiInputText label="Piso" name={"PISO"} value={objeto["PISO"]} onChange={handleChangeField}/>
                <UiInputText label="Pontuação" name={"PONTUACAO"} value={objeto["PONTUACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Posicao" name={"POSICAO"} value={objeto["POSICAO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Posicionamento" name={"POSICIONAMENTO"} value={objeto["POSICIONAMENTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Revestimento" name={"REVESTIMENTO"} value={objeto["REVESTIMENTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Revestimento externo" name={"REVESTIMENTO_EXTERNO"}
                             value={objeto["REVESTIMENTO_EXTERNO"]} onChange={handleChangeField}/>
                <UiInputText label="Revestimento interno" name={"REVESTIMENTO_INTERNO"}
                             value={objeto["REVESTIMENTO_INTERNO"]} onChange={handleChangeField}/>
                <UiInputText label="Situação" name={"SITUACAO"} value={objeto["SITUACAO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Situação edificada" name={"SITUACAO_EDIFICACAO"}
                             value={objeto["SITUACAO_EDIFICACAO"]} onChange={handleChangeField}/>
                <UiInputText label="Situação da unidade" name={"SITUACAO_UNIDADE"} value={objeto["SITUACAO_UNIDADE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Status" name={"STATUS"} value={objeto["STATUS"]} onChange={handleChangeField}/>
                <UiInputText label="Telefone" name={"TELEFONE"} value={objeto["TELEFONE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Tipo de edificação" name={"TIPO_EDIFICACAO"} value={objeto["TIPO_EDIFICACAO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Tipo de ficha" name={"TIPO_FICHA"} value={objeto["TIPO_FICHA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Trecho 1" name={"TRECHO1"} value={objeto["TRECHO1"]} onChange={handleChangeField}/>
                <UiInputText label="Triangulo" name={"TRIANGULO"} value={objeto["TRIANGULO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Unidade" name={"UNIDADE"} value={objeto["UNIDADE"]} onChange={handleChangeField}/>
                <UiInputText label="Unidade de medida" name={"UNIDADE_MEDIDA"} value={objeto["UNIDADE_MEDIDA"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Unidades Construidas" name={"UNIDADES_CONSTRUIDAS"}
                             value={objeto["UNIDADES_CONSTRUIDAS"]} onChange={handleChangeField}/>
                <UiInputText label="Usa imóvel" name={"USA_IMOVEL"} value={objeto["USA_IMOVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Utilização" name={"UTILIZACAO"} value={objeto["UTILIZACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Utilização do imóvel" name={"UTILIZACAO_IMOVEL"} value={objeto["UTILIZACAO_IMOVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Vedação/Esquadrias" name={"VEDACAO_ESQUADRIAS"} value={objeto["VEDACAO_ESQUADRIAS"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditInformacoesEdificacoes
