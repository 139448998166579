import {UiNotifications} from "components/UiNotifications";
import React, {useContext, useReducer} from "react";
import {API} from "utils/api";
import {IEnvio, IFileUploadContext} from "./IContexts";
import {fileUploadReducer, REDUCER_FILE_UPLOAD_ACTIONS, REDUCER_FILE_UPLOAD_INITIAL} from "../reducers/fileupload";
import {SessionContext} from "./SessionContext";

interface IFileUploadContextProps {
    children: React.ReactNode
}

export const FileUploadContext = React.createContext<IFileUploadContext>({
    fileUploadState: REDUCER_FILE_UPLOAD_INITIAL, fileUploadDispatch: () => {
    },
    uploadFile: () => {
    }
});

export const FileUploadContextProvider = ({children}: IFileUploadContextProps) => {
    const {addNotification} = useContext(UiNotifications);
    const [fileUploadState, fileUploadDispatch] = useReducer(fileUploadReducer, REDUCER_FILE_UPLOAD_INITIAL)
    const {headers: headersReq} = useContext(SessionContext)

    const handleUploadProgress = (id: string, loaded: number, total: number) => {
        fileUploadDispatch({type: REDUCER_FILE_UPLOAD_ACTIONS.UPDATE_PROGRESS, payload: {id, loaded, total}})
    };

    const uploadFile = (file: any, onFinish: any) => {
        const id = `${Math.random() * 20}`;

        let fd = new FormData();
        fd.append("file", file);
        API.uploadFileV2(fd, onFinish, ({
                                            loaded,
                                            total
                                        }: IEnvio) => handleUploadProgress(id, loaded, total), headersReq).then();

        addNotification({
            id: Math.random(),
            type: "success",
            text: "Seu arquivo está sendo enviado em background",
            subtext: "Não saia da aplicação enquanto o envio não for concluído",
            timeout: 10000,
        });

        fileUploadDispatch({
            type: REDUCER_FILE_UPLOAD_ACTIONS.ADD_TO_ENVIO, payload: {
                id,
                loaded: 0,
                total: file.size,
                status: "progress",
                filename: file.name,
            }
        })
    };

    return (
        <FileUploadContext.Provider value={{fileUploadState, fileUploadDispatch, uploadFile}}>
            {children}
        </FileUploadContext.Provider>
    );
};
