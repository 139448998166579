import {SessionContext} from 'contexts/SessionContext';
import {useContext} from 'react';
import styled from 'styled-components';
import {renderMoney} from '../../utils/utils';

type UiInfoProps = {
  label: string;
  value: string;
  area?: boolean;
  distancia?: boolean;
  dinheiro?: boolean;
  isPrivate?: boolean;
};

export const UiInfo = ({label, value, area, distancia, dinheiro, isPrivate}: UiInfoProps) => {
  const {sessionState} = useContext(SessionContext);
  if (!value || value.trim() === '') return null;

  const renderValue = () => {
    if (isPrivate && !sessionState.token) return '-';
    if (area) return `${value}m²`;
    if (dinheiro) return renderMoney(value);
    if (distancia) return `${value}m`;
    return value;
  };

  return (
    <Container>
      <Label>{label}</Label>
      <Value>{renderValue()}</Value>
    </Container>
  );
};

const Container: any = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const Label: any = styled.div`
  color: gray;
  font-size: 0.8em;
  margin: 5px 0;
`;
const Value: any = styled.div`
  color: black;
  font-size: 0.9em;
  margin: 5px 0;
`;
