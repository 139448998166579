import {SessionContext} from 'contexts/SessionContext';
import React, {useContext, useEffect, useReducer} from 'react';
import {
  appConfigReducer,
  REDUCER_APP_CONFIG_ACTIONS,
  REDUCER_APP_CONFIG_INITIAL,
} from 'reducers/appconfig';
import {loadingReducer, REDUCER_LOADING_INITIAL} from 'reducers/loading';
import {
  mapaTematicoReducer,
  REDUCER_MAPA_TEMATICO_ACTIONS,
  REDUCER_MAPA_TEMATICO_INITIAL,
} from 'reducers/mapatematico';
import {modalReducer, REDUCER_MODAL_INITIAL} from 'reducers/modals';
import {REDUCER_SESSION_ACTIONS} from 'reducers/session';
import {IAppContextProps, IAppContextValue} from './IContexts';
import {REDUCER_TRIGGER_INITIAL_STATE, triggerReducer} from '../reducers/triggers';
import {useGet} from '../hooks/useGet';

export const AppContext = React.createContext<IAppContextValue>({
  triggerState: REDUCER_TRIGGER_INITIAL_STATE,
  triggerDispatch: undefined,
  modalState: REDUCER_MODAL_INITIAL,
  appConfigState: REDUCER_APP_CONFIG_INITIAL,
  loadingState: REDUCER_LOADING_INITIAL,
  mapaTematicoState: REDUCER_MAPA_TEMATICO_INITIAL,
  modalDispatch: undefined,
  appConfigDispatch: undefined,
  loadingDispatch: undefined,
  mapaTematicoDispatch: undefined,
});

const AppContextProvider = ({children}: IAppContextProps) => {
  const {sessionDispatch} = useContext(SessionContext);

  const [modalState, modalDispatch] = useReducer(modalReducer, REDUCER_MODAL_INITIAL);
  const [mapaTematicoState, mapaTematicoDispatch] = useReducer(
    mapaTematicoReducer,
    REDUCER_MAPA_TEMATICO_INITIAL
  );
  const [appConfigState, appConfigDispatch] = useReducer(
    appConfigReducer,
    REDUCER_APP_CONFIG_INITIAL
  );
  const [loadingState, loadingDispatch] = useReducer(loadingReducer, REDUCER_LOADING_INITIAL);
  const [triggerState, triggerDispatch] = useReducer(triggerReducer, REDUCER_TRIGGER_INITIAL_STATE);

  const {data: perimetro} = useGet(`/cidade/${appConfigState.idCidadeSelecionada}/perimetro`);
  const {data: cidadesResumidas} = useGet(`/cidades/resumidas`);
  const {data: cidadeConfig} = useGet(
    `/cidadeconfig/${appConfigState.idCidadeSelecionada}`,
    undefined,
    undefined,
    appConfigState.idCidadeSelecionada
  );

  useEffect(() => {
    appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.SET_CIDADES, payload: cidadesResumidas});
    return () => appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.CLEAR_CIDADES});
  }, [triggerState.reloadCidades, cidadesResumidas]);

  useEffect(() => {
    appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.SET_PERIMETRO, payload: perimetro});
  }, [perimetro]);

  useEffect(() => {
    appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.SET_CIDADE_CONFIG, payload: cidadeConfig});

    let script: any;
    const head = document.getElementsByTagName('head')[0];
    if (cidadeConfig?.chaveGoogle) {
      script = document.createElement('script');
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${cidadeConfig.chaveGoogle}`;
      head.appendChild(script);
    }
    return () => {
      appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.CLEAR_CIDADE_CONFIG});
      if (script) {
        head.removeChild(script);
      }
    };
  }, [cidadeConfig]);

  useEffect(() => {
    sessionDispatch({
      type: REDUCER_SESSION_ACTIONS.SELECIONA_CIDADE,
      payload: appConfigState.idCidadeSelecionada,
    });
    mapaTematicoDispatch({type: REDUCER_MAPA_TEMATICO_ACTIONS.CLEAR_PARAMS});
  }, [appConfigState.idCidadeSelecionada, sessionDispatch]);

  useEffect(() => {
    if (appConfigState.idCidadeSelecionada) {
      localStorage.setItem('cidadeSelecionada', appConfigState.idCidadeSelecionada);
    } else {
      sessionDispatch({type: REDUCER_SESSION_ACTIONS.INVALIDATE_SESSION});
    }

    return () => {
      appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.CLEAR_CIDADE_SELECIONADA});
    };
  }, [appConfigState.idCidadeSelecionada, sessionDispatch]);

  return (
    <AppContext.Provider
      value={{
        triggerState,
        triggerDispatch,
        modalState,
        modalDispatch,
        mapaTematicoState,
        mapaTematicoDispatch,
        appConfigState,
        appConfigDispatch,
        loadingState,
        loadingDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
