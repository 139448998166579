import {OpenLayersContext} from "contexts/OpenLayersContext";
import {Draw, Snap} from "ol/interaction";
import {useContext, useEffect} from "react";
import {getSourceForLastLayer} from "../../../../utils/openlayers";

export const DrawLayer = () => {
    const {openLayersState} = useContext(OpenLayersContext);

    useEffect(() => {
        const source = getSourceForLastLayer(openLayersState.map.getLayers())

        if (source.getFeatures) {
            const draw = new Draw({source: source, type: openLayersState.activeDrawTool});
            let snap = new Snap({source: source});
            openLayersState.map.addInteraction(draw);
            openLayersState.map.addInteraction(snap);

            return () => {
                openLayersState.map.removeInteraction(draw);
                openLayersState.map.removeInteraction(snap);
            };
        }
    }, [openLayersState.map, openLayersState.activeDrawTool]);
    return null;
};
