import UiContent from "components/UiContent";
import {UiHeader} from "components/UiHeader";
import {GlobalStyle} from "GlobalStyle";

export const Template = ({children}: any) => (
    <>
        <GlobalStyle/>
        <UiHeader/>
        <UiContent>
            {children}
        </UiContent>
    </>
)
