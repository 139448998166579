import UiFlexItem from 'components/UiFlex';
import UiPaginator from 'components/UiPaginator';
import UiBox from 'components/UiPanel';
import UiTable from 'components/UiTable';
import UiText from 'components/UiText';
import {DateTime} from 'luxon';
import {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../contexts/AppContext';
import {SessionContext} from '../../../contexts/SessionContext';
import {useGet} from '../../../hooks/useGet';
import {useIntervalGet} from '../../../hooks/useIntervalGet';
import RegisterPage from '../index';

const TabelaAudit = () => {
  const {headers: headersReq} = useContext(SessionContext);
  const {appConfigState} = useContext(AppContext);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(Math.floor((window.innerHeight - 206) / 34));
  const [actualPage, setActualPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const {data: totalCountReq} = useIntervalGet(
    `/audit/count?cidade=${appConfigState.idCidadeSelecionada}`,
    15000,
    headersReq
  );
  const {data: audits} = useGet(
    `/audit?skip=${offset}&limit=${limit}&cidade=${appConfigState.idCidadeSelecionada}`,
    headersReq,
    undefined,
    limit && totalCountReq
  );

  useEffect(() => {
    setTotalCount(Math.ceil(totalCountReq?.count / limit));
  }, [totalCountReq, limit]);

  useEffect(() => {
    setOffset(actualPage * limit);
  }, [actualPage, limit]);

  const renderTable = () => {
    if (!audits) {
      return (
        <UiText size=".9em" italic color="gray">
          Carregando..
        </UiText>
      );
    }

    if (audits.length === 0) {
      return (
        <UiText size=".9em" italic color="gray">
          Nenhum evento encontrado
        </UiText>
      );
    }

    return <UiTable rows={audits} headers={headers} />;
  };

  const handleChangePage = (e: any) => setActualPage(e);
  const renderTipoAcesso = (e: any) => (e === '' ? 'Publico' : 'Autenticado');
  const renderData = (e: any) => DateTime.fromISO(e).toFormat('dd/MM/yyyy HH:mm:ss');

  const renderTipoTransacao = (e: any) => {
    switch (e) {
      case 'GET':
        return 'Consulta';
      case 'POST':
        return 'Novo item';
      case 'PUT':
        return 'Atualização de item';
      default:
        return e;
    }
  };

  const renderRecurso = (e: any) => {
    if (!e) {
      return `-`;
    }
    if (e.startsWith('/api/audit/count')) {
      return 'Auditoria - Quantidade de registros';
    } else if (e.startsWith('/api/audit')) {
      return 'Auditoria';
    } else if (e.startsWith('/api/cidade/')) {
      return 'Cidade';
    } else if (e.startsWith('/api/sessao/')) {
      return 'Sessão de usuário';
    } else if (e.startsWith('/api/cidadeconfig')) {
      return 'Configurações de cidade';
    } else if (e.startsWith('/api/globalconfig')) {
      return 'Configurações global';
    } else if (e.startsWith('/api/layer')) {
      return 'Camadas';
    } else if (e.startsWith('/api/login')) {
      return 'Login';
    } else if (e.startsWith('/api/edificacoes')) {
      return 'Edificações';
    } else if (e.startsWith('/api/lotes')) {
      return 'Lotes';
    } else if (e.startsWith('/api/logradouros')) {
      return 'Logradouros';
    } else if (e.startsWith('/api/processos')) {
      return 'Execução de processos';
    } else if (e.startsWith('/api/usuarios')) {
      return 'Usuários';
    } else if (e.startsWith('/api/cache/size')) {
      return 'Tamanho do cache - Ortoimagens';
    } else if (e.startsWith('/api/geojson')) {
      return 'Camada GeoJSON';
    } else if (e.startsWith('/api/search')) {
      return 'Busca';
    } else if (e.startsWith('/api/cidades')) {
      return 'Cidades';
    } else if (e.startsWith('/api/infos/')) {
      return 'Informações sobre feição';
    } else if (e.startsWith('/api/consultaPrevia/')) {
      return 'Geração de consulta previa';
    } else if (e.startsWith('/api/imagemBatch')) {
      return 'Importação em massa de imagens';
    }

    return e;
  };

  const renderResultado = (e: any) => {
    if (e >= 200 && e <= 299) {
      return 'Sucesso';
    }

    if (e >= 400 && e <= 499) {
      return 'Requisição inválida';
    }

    if (e >= 500) {
      return 'Erro no servidor';
    }
  };

  const headers = [
    {title: 'Data', field: 'startTime', applyRender: renderData},
    {title: 'Tipo de acesso', field: 'token', applyRender: renderTipoAcesso},
    {title: 'Usuário', field: 'user'},
    {
      title: 'Tipo de transação',
      field: 'httpMethod',
      applyRender: renderTipoTransacao,
    },
    {title: 'IP', field: 'ip'},
    {title: 'Recurso', field: 'path', applyRender: renderRecurso},
    {title: 'Resultado', field: 'status', applyRender: renderResultado},
    //    { actions: renderActions },
  ];

  return (
    <RegisterPage>
      <UiBox maxHeight="calc(100% - 177px)">
        <UiFlexItem direction="column">
          <UiText bold>Auditoria</UiText>
          <UiFlexItem justifyContent="center">{renderTable()}</UiFlexItem>
        </UiFlexItem>
      </UiBox>
      {audits?.length > 0 && (
        <UiPaginator
          totalCount={totalCount}
          actualPage={actualPage}
          onChangePage={handleChangePage}
        />
      )}
    </RegisterPage>
  );
};

export default TabelaAudit;
