import ListaProcessos from "pages/MinhaCidadePage/MinhaCidadeConfigPage/ListaProcessos";
import UiFlex from "components/UiFlex";
import CacheOrtoimagens from "./CacheOrtoimagens";
import LogoConfig from "./LogoConfig";
import RegisterPage from "../index";

const MinhaCidadeConfigPage = () => (
    <RegisterPage>
        <UiFlex>
            <LogoConfig/>
            <CacheOrtoimagens/>
            <ListaProcessos/>
        </UiFlex>
    </RegisterPage>
)

export default MinhaCidadeConfigPage;
