import {MdBlock, MdCheck} from 'react-icons/md';
import styled from 'styled-components';

const UiTable = ({headers, rows, onRowClick, noDataText}: any) => {
  const getCellValue = (row: any, header: any) => {
    let value = row[header.field];

    let applyRender = header.applyRender;
    if (typeof applyRender === 'function') {
      return applyRender(value, row);
    }

    if (typeof value === 'boolean') {
      return value ? <MdCheck /> : <MdBlock />;
    } else {
      return value;
    }
  };

  if (!rows?.length) return <Center>{noDataText}</Center>;

  return (
    <Table>
      <Header>
        <tr>
          {headers &&
            headers.map((h: any, i: number) => <Cell key={`${h.field}${i}`}>{h.title}</Cell>)}
        </tr>
      </Header>
      <Body>
        {rows &&
          rows.map((r: any, i: number) => (
            <Row
              key={`${r.id}${i}`}
              onClick={() => {
                onRowClick && onRowClick(r);
              }}
            >
              {headers &&
                headers.map((h: any, i: number) => {
                  if (h.field) {
                    return (
                      <Cell key={`${h.field}${i}`} width={h.width}>
                        {getCellValue(r, h)}
                      </Cell>
                    );
                  } else {
                    return (
                      <Cell key={`${h.field}${i}`} width={h.width}>
                        {h.actions(r)}
                      </Cell>
                    );
                  }
                })}
            </Row>
          ))}
      </Body>
    </Table>
  );
};

const Table: any = styled.table`
  border-collapse: collapse;
  max-height: 100%;
  overflow: auto;
  width: 100%;
`;

const Header: any = styled.thead`
  font-size: 0.9em;
  font-weight: 700;
  height: 36px;
`;

const Body: any = styled.tbody`
  font-size: 0.82em;
`;

const Cell: any = styled.td`
  text-align: center;
  max-width: ${({width}) => width || '200px'};
`;

const Row: any = styled.tr`
  border-bottom: 1px solid #eee;
  color: #222;
  height: 32px;

  &:hover {
    background-color: #fafafa;
  }
`;

const Center: any = styled.div`
  color: gray;
  margin: 30px 0;
  text-align: center;
  width: 100%;
`;

export default UiTable;
