import {ILoadingState, IReducerAction} from "./IReducers";

export const REDUCER_LOADING_INITIAL: ILoadingState = {
    layers: [],
    consultaPrevia: false,
}

export const REDUCER_LOADING_ACTIONS = {
    ADD_LAYER: 'ADD_LAYER',
    REMOVE_LAYER: 'REMOVE_LAYER',

    SET_CONSULTA_PREVIA: 'SET_CONSULTA_PREVIA',
    CLEAR_CONSULTA_PREVIA: 'CLEAR_CONSULTA_PREVIA',
}

export const loadingReducer = (state: ILoadingState, action: IReducerAction) => {
    switch (action.type) {
        case REDUCER_LOADING_ACTIONS.ADD_LAYER:
            return {...state, layers: [...state.layers, action.payload]}
        case REDUCER_LOADING_ACTIONS.REMOVE_LAYER:
            return {...state, layers: state.layers.filter(id => id !== action.payload)}

        case REDUCER_LOADING_ACTIONS.SET_CONSULTA_PREVIA:
            return {...state, consultaPrevia: true}
        case REDUCER_LOADING_ACTIONS.CLEAR_CONSULTA_PREVIA:
            return {...state, consultaPrevia: false}

        default:
            return {...state}
    }
}
