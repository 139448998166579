import {Fragment, useEffect} from 'react'
import axios from "axios";
import {API_URL} from "../../utils/api";

export const CatchError = ({children}: any) => {
    useEffect(() => {
        window.onerror = (msg: string | Event, source: string | undefined, lineno: number | undefined, colno: number | undefined, error: any) => {
            axios.post(`${API_URL}/error`, {msg, source, lineno, colno, error}).then()
        }
    }, [])

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}
