import axios, {AxiosResponse} from 'axios';
import {useEffect, useState} from 'react';
import {API} from '../utils/api';

export const useGet = (path: string, headers?: any, baseURL?: string, trigger?: any) => {
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doRequest = async () => {
      try {
        setLoading(true);
        let p = `${baseURL || API.API_URL}${path}`;
        const url = new URL(p);
        const searchParams = url.searchParams;
        searchParams.append('t', `${new Date().getTime()}`);
        url.search = searchParams.toString();

        let resp: AxiosResponse = await axios.get(url.toString(), {headers});
        setData(resp?.data);
        setError(false);
      } catch (err) {
        setError(true);
        setData(undefined);
      } finally {
        setLoading(false);
      }
    };

    if (trigger !== 0 && trigger !== '' && trigger !== false) {
      doRequest().then();
    }
  }, [path, baseURL, trigger, headers]);

  return {data, error, loading};
};
