import {UiInfo} from "components/UiInfo";

const InfosDatas = ({infos}: any) => (
    <>
        <UiInfo label="Ano do alvará" value={infos["ANO_ALVARA"]}/>
        <UiInfo label="Ano de aprovação da planta" value={infos["ANO_APROVACAO_PLANTA"]}/>
        <UiInfo label="Ano de contrução" value={infos["ANO_CONSTRUCAO"]}/>
        <UiInfo label="Ano do prédio" value={infos["ANO_PREDIO"]}/>
        <UiInfo label="Ano do primeiro lançamento" value={infos["ANO_PRIMEIRO_LANCAMENTO"]}/>
        <UiInfo label="Data" value={infos["DATA"]}/>
        <UiInfo label="Data do alvará" value={infos["DATA_ALVARA"]}/>
        <UiInfo label="Data do cadastramento" value={infos["DATA_CADASTRAMENTO"]}/>
        <UiInfo label="Data do cadastramento" value={infos["DATA_DESATIVACAO"]}/>
        <UiInfo label="Data do habite-se" value={infos["DATA_HABITESE"]}/>
        <UiInfo label="Data do recadastro" value={infos["DATA_RECADASTRO"]}/>
        <UiInfo label="Data de renovação" value={infos["DATA_RENOVACAO"]}/>
        <UiInfo label="Data da última alteração" value={infos["DATA_ULTIMA_ALTERACAO"]}/>
        <UiInfo label="Data de validade" value={infos["DATA_VALIDADE"]}/>
        <UiInfo label="Número/Ano" value={infos["NUMERO_ANO"]}/>
    </>
)

export default InfosDatas;
