import UiButton from 'components/UiButton';
import UiFlexItem from 'components/UiFlex';
import UiPanel from 'components/UiPanel';
import UiText from 'components/UiText';
import {GeoJSON} from 'ol/format';
import {Vector as VectorLayer} from 'ol/layer';
import Map from 'ol/Map';
import {Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import View from 'ol/View';
import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {API_URL} from 'utils/api';
import {AppContext} from '../../../contexts/AppContext';
import {AddLayerContext} from './AddLayerContext';

const PreviewLayer = ({editing}: any) => {
  const {appConfigState} = useContext(AppContext);

  const {form, idTmpFile, shouldAskToMerge, setAskToMerge, createNewLayer} =
    useContext<any>(AddLayerContext);
  const [mapPreview, setMapPreview] = useState<any>();

  useEffect(() => {
    const intToHex = (i: any) => {
      let hex = Number(i).toString(16);
      return hex.length < 2 ? '0' + hex : hex;
    };

    const image = new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: form.corFundo + intToHex(form.opacidadeFundo),
      }),
      stroke: new Stroke({color: form.corLinha, width: +form.espessuraLinha}),
    });

    const styles: any = {
      Point: new Style({
        image: image,
      }),
      LineString: new Style({
        stroke: new Stroke({
          color: form.corLinha + intToHex(form.opacidadeLinha),
          width: +form.espessuraLinha,
        }),
      }),
      MultiLineString: new Style({
        stroke: new Stroke({
          color: form.corLinha + intToHex(form.opacidadeLinha),
          width: +form.espessuraLinha,
        }),
      }),
      MultiPoint: new Style({
        image: image,
      }),
      MultiPolygon: new Style({
        stroke: new Stroke({
          color: form.corLinha + intToHex(form.opacidadeLinha),
          width: +form.espessuraLinha,
        }),
        fill: new Fill({
          color: form.corFundo + intToHex(form.opacidadeFundo),
        }),
      }),
      Polygon: new Style({
        stroke: new Stroke({
          color: form.corLinha + intToHex(form.opacidadeLinha),
          width: +form.espessuraLinha,
        }),
        fill: new Fill({
          color: form.corFundo + intToHex(form.opacidadeFundo),
        }),
      }),
      GeometryCollection: new Style({
        stroke: new Stroke({
          color: form.corLinha + intToHex(form.opacidadeLinha),
          width: +form.espessuraLinha,
        }),
        fill: new Fill({
          color: form.corFundo + intToHex(form.opacidadeFundo),
        }),
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: form.corLinha + intToHex(form.opacidadeLinha),
          }),
        }),
      }),
      Circle: new Style({
        stroke: new Stroke({
          color: form.corLinha + intToHex(form.opacidadeLinha),
          width: +form.espessuraLinha,
        }),
        fill: new Fill({
          color: form.corFundo + intToHex(form.opacidadeFundo),
        }),
      }),
    };

    const styleFunction = (feature: any) => styles[feature.getGeometry().getType()];
    mapPreview?.getLayers().forEach((l: any) => l.setStyle(styleFunction));
  }, [form, mapPreview]);

  const isSaveDisabled = () => form.nome.trim() === '' || form.tipoCamada === '';

  const saveLayer = async () => {
    if (!shouldAskToMerge()) {
      await createNewLayer();
    } else {
      setAskToMerge(true);
    }
  };

  useEffect(() => {
    if (idTmpFile || form.id) {
      let vectorSource = new VectorSource({
        format: new GeoJSON(),
        url: form.id
          ? `${API_URL}/drive/download?cidade=${appConfigState.idCidadeSelecionada}&path=/camadas/${form.id}.geojson`
          : `${API_URL}/drive/download?path=/tmp/${idTmpFile}/${idTmpFile}.geojson&cidade=${appConfigState.idCidadeSelecionada}`,
      });

      let vectorLayer = new VectorLayer({source: vectorSource});

      let m: any = new Map({
        layers: [vectorLayer],
        target: 'previewMap',
        view: new View({
          center: [0, 0],
          zoom: 2,
          projection: 'EPSG:4326',
          constrainResolution: true,
        }),
      });

      vectorSource.on('featuresloadend', () => {
        let view = m.getView();
        view.fit(vectorSource.getExtent());
      });

      setMapPreview(m);

      return () => {
        m.setTarget();
        setMapPreview(null);
      };
    }
  }, [idTmpFile, appConfigState.idCidadeSelecionada, form.id]);

  if (!editing && !idTmpFile) {
    return (
      <UiPanel>
        <UiFlexItem justifyContent="center">
          <UiText italic size=".9em" color="gray">
            {!idTmpFile ? 'Para iniciar, envie o seu arquivo' : 'Carregando...'}
          </UiText>
        </UiFlexItem>
      </UiPanel>
    );
  }

  return (
    <UiPanel>
      <UiFlexItem justifyContent="center">
        <PreviewMap id="previewMap" />
      </UiFlexItem>
      {!editing && (
        <UiFlexItem margin="20px 0" justifyContent="flex-end">
          <UiButton primary disabled={isSaveDisabled()} onClick={saveLayer}>
            Salvar
          </UiButton>
        </UiFlexItem>
      )}
    </UiPanel>
  );
};

const PreviewMap: any = styled.div`
  height: 400px;
  width: 100%;
`;

export default PreviewLayer;
