import {useEffect, useRef, useState} from 'react';
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io';
import styled from 'styled-components';

const UiTabs = ({tabs, children, selectedTab, onSelectTab, showAnchors}: any) => {
  const [maxTabs, setMaxTabs] = useState(100);
  const [startIndex, setStartIndex] = useState(0);

  const rightTabs = tabs.filter((t: any) => t.align === 'right');
  const leftTabs = tabs.filter((t: any) => t.align !== 'right');
  let tmpRightTabs = rightTabs.slice(0, showAnchors ? maxTabs : rightTabs.length);
  let tmpLeftTabs = leftTabs.slice(
    startIndex,
    showAnchors && maxTabs - rightTabs.length + startIndex
  );

  const containerRef = useRef<any>();

  useEffect(() => {
    setMaxTabs(Math.ceil(containerRef.current?.offsetWidth / 175));
  }, [containerRef]);

  const handleDecreaseIndex = () => {
    if (startIndex === 0) {
      return;
    }

    setStartIndex(startIndex - 1);
  };

  const handleIncreaseIndex = () => {
    if (startIndex === leftTabs.length - maxTabs + 1) {
      return;
    }

    setStartIndex(startIndex + 1);
  };

  return (
    <Container ref={containerRef}>
      <Tabs>
        {showAnchors && rightTabs.length + leftTabs.length > maxTabs ? (
          <Arrow onClick={handleDecreaseIndex}>
            <IoIosArrowBack color="DarkBlue" />
          </Arrow>
        ) : null}
        <LeftTab>
          {tmpLeftTabs.map((t: any, i: any) => (
            <Tab
              key={i}
              active={selectedTab === t.id}
              onClick={() => {
                onSelectTab(t.id);
              }}
            >
              {t.name}
            </Tab>
          ))}
        </LeftTab>
        {showAnchors && rightTabs.length + leftTabs.length > maxTabs ? (
          <Arrow onClick={handleIncreaseIndex}>
            <IoIosArrowForward color="DarkBlue" />
          </Arrow>
        ) : null}
        <Spacer />
        <RightTab>
          {tmpRightTabs.map((t: any, i: any) =>
            t['tabAction'] ? (
              <TabAction key={i} active={selectedTab === t.id} onClick={t.onClick}>
                {t['tabAction']()}
              </TabAction>
            ) : (
              <Tab
                key={i}
                active={selectedTab === t.id}
                onClick={() => {
                  onSelectTab(t.id);
                }}
              >
                {t.name}
              </Tab>
            )
          )}
        </RightTab>
      </Tabs>
      <TabContainer>{children}</TabContainer>
    </Container>
  );
};

const Arrow: any = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 30px;
`;

const Container: any = styled.div<any>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 10px;
  width: calc(100% - 20px);
  max-width: 100%;
`;
const Tabs: any = styled.div`
  display: flex;
  min-height: 30px;
  max-height: 30px;
  overflow: auto;
`;
const Tab: any = styled.div<any>`
  align-items: center;
  background-color: ${({active}) => (active ? 'white' : '#EEE')};
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: ${props => (!props.active ? '1px solid #DDD' : '1px solid white')};
  cursor: pointer;
  display: flex;
  font-size: 0.85em;
  justify-content: center;
  padding: 0 7px;
  user-select: none;
  z-index: 10;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    border-right: 1px solid #ddd;
  }
`;

const TabAction: any = styled.div<any>`
  align-items: center;
  background-color: white;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  z-index: 10;

  &:last-child {
    border-right: 1px solid #ddd;
  }
`;

const TabContainer: any = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  flex-grow: 1;
  padding: 10px;
  position: relative;
  top: -1px;
  z-index: 0;
`;

const LeftTab: any = styled.div`
  display: flex;
`;
const RightTab: any = styled.div`
  display: flex;
`;

const Spacer: any = styled.div`
  flex-grow: 1;
`;

export default UiTabs;
