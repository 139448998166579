import UiFlex from "components/UiFlex";
import UiTable from "components/UiTable";
import React, {useContext, useMemo} from "react";
import {MdDelete, MdEdit, MdFileDownload} from "react-icons/md";
import {AppContext} from "contexts/AppContext";
import {SessionContext} from "contexts/SessionContext";
import {API} from "utils/api";
import {COLOR} from "utils/colors";
import {useNavigate} from "react-router-dom";
import {useGet} from "../../../hooks/useGet";

const TabelaCamadas = ({onDelete, triggerUpdate}: any) => {
    const {sessionState} = useContext(SessionContext);
    const {appConfigState} = useContext(AppContext);
    const navigate = useNavigate()

    const headersReq = useMemo(() => ({'Authorization': sessionState.token}), [sessionState.token])
    const {data: layers} = useGet(`/cidade/${appConfigState.idCidadeSelecionada}/camadas`, headersReq,
        undefined, triggerUpdate)

    const columnEditTable = (e: any) => {
        if (!sessionState.usuario?.permissoes?.postEdificacoes) return null

        return (
            <UiFlex justifyContent={"space-between"}>
                <MdFileDownload color={COLOR.PRIMARY} size="1.3em"
                                onClick={() => window.open(`${API.EXPORT_URL}/camadas/${e.id}?cidade=${appConfigState.idCidadeSelecionada}`)}
                                cursor="pointer"/>
                <MdEdit color={COLOR.WARNING} size="1.3em" cursor="pointer"
                        onClick={() => navigate("/cadastros/camadas/editar", {state: e})}/>
                <MdDelete color={COLOR.DANGER} size="1.3em" onClick={() => onDelete(e.id)} cursor="pointer"/>
            </UiFlex>
        );
    };

    const headers: any = [
        {title: "Nome", field: "nome"},
        {title: "Tipo", field: "tipoCamada"},
        {title: "Ultima alteração", field: 'lastUpdate'},
        {title: "Alterado por", field: 'updatedBy'},
    ];

    sessionState.usuario.permissoes?.postEdificacoes && headers.push({
        title: "",
        actions: columnEditTable,
        width: "55px",
        align: "center"
    });

    return <UiTable rows={layers} headers={headers} noDataText={"Nenhuma camada encontrada"}/>
};

export default TabelaCamadas
