import {default as UiFlex, default as UiFlexItem} from 'components/UiFlex';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import {useContext, useEffect, useState} from 'react';
import {MdClose} from 'react-icons/md';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import {API} from 'utils/api';
import UiButton from '../components/UiButton';
import UiModalYesNo from '../components/UiModalYesNo';
import {SessionContext} from '../contexts/SessionContext';
import {useDeleteImage} from '../hooks/files/useDeleteImage';

const ModalImagemFaixada = () => {
  const {modalState, appConfigState, modalDispatch} = useContext(AppContext);
  const {sessionState} = useContext(SessionContext);
  const [hasNoImage, setHasNoImage] = useState(false);
  const [deletingImage, setDeletingImage] = useState(false);

  const {isDeletingImage, deleteImage} = useDeleteImage();

  useEffect(() => {
    setHasNoImage(false);
  }, [modalState.mostrarImagens]);

  const imageName = modalState.mostrarImagens?.infos?.idGeometria || '';
  const time = new Date();
  const imageUrl = `${API.API_URL}/drive/download?cidade=${
    appConfigState.idCidadeSelecionada
  }&path=/imagens/${imageName}.jpg&t=${time.getTime()}`;

  const handleDownloadImage = () => {
    if (imageName) {
      const el = document.createElement('a');
      el.href = imageUrl;
      el.target = '_blank';
      el.download = `${imageName}.jpg`;
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    }
  };

  const handleDeleteImage = async () => {
    await deleteImage(
      sessionState.token,
      appConfigState.idCidadeSelecionada,
      `/imagens/${imageName}.jpg`
    );
    setHasNoImage(true);
    setDeletingImage(false);
  };

  const handleRemoveImage = () => setDeletingImage(true);

  if (!modalState.mostrarImagens?.infos?.idGeometria) return null;

  return (
    <Modal>
      <Container>
        <UiFlexItem direction="column">
          <UiFlexItem justifyContent="space-between">
            <UiText>Imagens</UiText>
            <MdClose
              onClick={() => modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_IMAGENS_FAXADA})}
              cursor="pointer"
            />
          </UiFlexItem>
          <UiFlexItem padding={'10px'}>
            {hasNoImage ? (
              <UiFlex padding={'20px'}>
                A geometria selecionada não possui uma imagem vinculada!
              </UiFlex>
            ) : (
              <Image onError={() => setHasNoImage(true)} src={imageUrl} />
            )}
          </UiFlexItem>
        </UiFlexItem>
        {sessionState.token && !hasNoImage && (
          <UiFlexItem justifyContent={'space-around'} padding={'10px 0 10px 0'}>
            <UiButton primary onClick={handleDownloadImage}>
              Fazer download
            </UiButton>
            <UiButton danger onClick={handleRemoveImage} loading={isDeletingImage}>
              Excluir
            </UiButton>
          </UiFlexItem>
        )}
      </Container>
      {sessionState.token && !hasNoImage && (
        <UiModalYesNo
          show={deletingImage}
          title={'Exclusão de imagem'}
          onNo={() => setDeletingImage(false)}
          body={'Deseja continuar com a exclusão da imagem?'}
          onYes={handleDeleteImage}
        />
      )}
    </Modal>
  );
};

const Modal: any = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  align-items: center;
  background-color: #f7f8f9cc;
  display: flex;
  justify-content: center;
  padding: 10px;
  z-index: 10;
`;

const Container: any = styled.div`
  background-color: white;
  box-shadow: 0 0 4px gray;
  padding: 10px;
`;

const Image: any = styled.img`
  height: auto;
  min-height: 400px;
  margin: 0 10px;
  width: 600px;
`;

export default ModalImagemFaixada;
