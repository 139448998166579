import UiButton from 'components/UiButton';
import UiFilePicker from 'components/UiFilePicker';
import UiFlex from 'components/UiFlex';
import UiInputText from 'components/UiInputText';
import UiBox from 'components/UiPanel';
import UiSelect from 'components/UiSelect';
import UiText from 'components/UiText';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {API} from 'utils/api';
import LimitVisualizer from './LimitVisualizer';
import {AppContext} from '../../../contexts/AppContext';
import {REDUCER_TRIGGER_ACTIONS} from '../../../reducers/triggers';
import {SessionContext} from '../../../contexts/SessionContext';
import ConfigPage from '../index';

const AddCidadePage = () => {
  const {triggerDispatch} = useContext(AppContext);
  const {headers: headersReq} = useContext(SessionContext);
  const [nome, setNome] = useState('');
  const [estado, setEstado] = useState('');
  const [geoJSON, setGeoJSON] = useState('');
  const [chaveBing, setChaveBing] = useState('');
  const [chaveGoogle, setChaveGoogle] = useState('');
  const [editing, setEditing] = useState('');

  const [website, setWebsite] = useState('');
  const [cep, setCEP] = useState('');
  const [telefone, setTelefone] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');

  const navigate = useNavigate();
  const location: any = useLocation();

  const inputRef = useRef<any>();

  const estados = [
    {AC: 'Acre'},
    {AL: 'Alagoas'},
    {AP: 'Amapá'},
    {AM: 'Amazonas'},
    {BA: 'Bahia'},
    {CE: 'Ceará'},
    {DF: 'Distrito Federal'},
    {ES: 'Espírito Santo'},
    {GO: 'Goías'},
    {MA: 'Maranhão'},
    {MT: 'Mato Grosso'},
    {MS: 'Mato Grosso do Sul'},
    {MG: 'Minas Gerais'},
    {PA: 'Pará'},
    {PB: 'Paraíba'},
    {PR: 'Paraná'},
    {PE: 'Pernambuco'},
    {PI: 'Piauí'},
    {RJ: 'Rio de Janeiro'},
    {RN: 'Rio Grande do Norte'},
    {RS: 'Rio Grande do Sul'},
    {RO: 'Rondônia'},
    {RR: 'Roraíma'},
    {SC: 'Santa Catarina'},
    {SP: 'São Paulo'},
    {SE: 'Sergipe'},
    {TO: 'Tocantins'},
  ];

  const transformEstados = () => {
    return estados.map(e => ({
      key: Object.keys(e)[0],
      value: Object.values(e)[0],
    }));
  };

  const handleSelectFiles = () => {
    if (inputRef.current?.files.length) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result as string;
        setGeoJSON(result);
      };
      fileReader.readAsText(inputRef.current.files[0]);
    }
  };

  const isSaveDisabled = () => {
    if (!nome.trim() || !estado.trim()) return true;
    return !editing && !geoJSON;
  };

  const handleSaveCidade = async () => {
    if (editing) {
      await API.putCidade(
        editing,
        {
          nome,
          estado,
          chaveGoogle,
          chaveBing,
          endereco,
          numero,
          complemento,
          bairro,
          cep,
          telefone,
          website,
        },
        headersReq
      );
    } else {
      await API.postCidade(
        {
          nome,
          estado,
          geoJSON,
          chaveGoogle,
          chaveBing,
          endereco,
          numero,
          complemento,
          bairro,
          cep,
          telefone,
          website,
        },
        headersReq
      );
    }

    triggerDispatch({type: REDUCER_TRIGGER_ACTIONS.RELOAD_CIDADES});
    navigate('/configuracoes/cidades');
  };

  useEffect(() => {
    if (location.state) {
      const {state} = location;
      setNome(state.nome);
      setEstado(state.estado);
      setGeoJSON(state.geoJSON);
      setChaveBing(state.chaveBing);
      setChaveGoogle(state.chaveGoogle);
      setEditing(state.id);
      setWebsite(state.website);
      setEndereco(state.endereco);
      setTelefone(state.telefone);
      setBairro(state.bairro);
      setComplemento(state.complemento);
      setNumero(state.numero);
      setCEP(state.cep);
    }
  }, [location]);

  return (
    <ConfigPage>
      <UiFlex direction={'column'}>
        <UiFlex>
          <UiBox>
            <UiText bold>Adicionar cidade</UiText>
            <UiFlex>
              <UiInputText
                label="Cidade"
                value={nome}
                onChange={(e: any) => {
                  setNome(e.target.value);
                }}
              />
              <UiSelect
                label="Estado"
                options={transformEstados()}
                value={estado}
                onChange={(e: any) => {
                  setEstado(e.target.value);
                }}
              />
            </UiFlex>
            <UiFlex>
              <UiInputText
                label="Chave BING"
                value={chaveBing}
                onChange={(e: any) => {
                  setChaveBing(e.target.value);
                }}
              />
              <UiInputText
                label="Chave Google"
                value={chaveGoogle}
                onChange={(e: any) => {
                  setChaveGoogle(e.target.value);
                }}
              />
            </UiFlex>
            {editing ? null : (
              <UiFilePicker
                label="Selecione o arquivo responsável pelas extremidades da cidade"
                inputRef={inputRef}
                accept=".geojson"
                onChange={handleSelectFiles}
              />
            )}

            <UiFlex justifyContent="flex-end" margin={'10px 10px 0 0'} width={'calc(100% - 10px)'}>
              <UiButton primary disabled={isSaveDisabled()} onClick={handleSaveCidade}>
                Salvar
              </UiButton>
            </UiFlex>
          </UiBox>
          <UiBox>
            <UiText bold>Informacoes da prefeitura</UiText>
            <UiFlex>
              <UiInputText
                label="Endereço"
                value={endereco}
                onChange={(e: any) => {
                  setEndereco(e.target.value);
                }}
              />
              <UiInputText
                label="Número"
                value={numero}
                onChange={(e: any) => {
                  setNumero(e.target.value);
                }}
              />
              <UiInputText
                label="Complemento"
                value={complemento}
                onChange={(e: any) => {
                  setComplemento(e.target.value);
                }}
              />
            </UiFlex>
            <UiFlex>
              <UiInputText
                label="Bairro"
                value={bairro}
                onChange={(e: any) => {
                  setBairro(e.target.value);
                }}
              />
              <UiInputText
                label="CEP"
                value={cep}
                onChange={(e: any) => {
                  setCEP(e.target.value);
                }}
              />
            </UiFlex>
            <UiFlex>
              <UiInputText
                label="Telefone"
                value={telefone}
                onChange={(e: any) => {
                  setTelefone(e.target.value);
                }}
              />
              <UiInputText
                label="Website"
                value={website}
                onChange={(e: any) => {
                  setWebsite(e.target.value);
                }}
              />
            </UiFlex>
            <UiFlex justifyContent="flex-end" margin={'10px 10px 0 0'} width={'calc(100% - 10px)'}>
              <UiButton primary disabled={isSaveDisabled()} onClick={handleSaveCidade}>
                Salvar
              </UiButton>
            </UiFlex>
          </UiBox>
        </UiFlex>
        <UiFlex>{geoJSON && <LimitVisualizer geoJson={geoJSON} />}</UiFlex>
      </UiFlex>
    </ConfigPage>
  );
};

export default AddCidadePage;
