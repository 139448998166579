import UiButton from 'components/UiButton'
import UiFlexItem from 'components/UiFlex'
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditInformacoesTerreno = ({objeto, onSetObjeto, onSave}: any) => {

    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlexItem direction="column">
            <UiFlexItem>

                <UiInputText label="Afastamento frontal" name={"AFASTAMENTO_FRONTAL"}
                             value={objeto["AFASTAMENTO_FRONTAL"]} onChange={handleChangeField}/>
                <UiInputText label="Alinhamento" name={"ALINHAMENTO"} value={objeto["ALINHAMENTO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Área Habite-se" name={"AREA_HABITESE"} value={objeto["AREA_HABITESE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Área do lote" name={"AREA_LOTE"} value={objeto["AREA_LOTE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Área lote vila" name={"AREA_LOTE_VILA"} value={objeto["AREA_LOTE_VILA"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Área projeto" name={"AREA_PROJETO"} value={objeto["AREA_PROJETO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Área rural" name={"AREA_RURAL"} value={objeto["AREA_RURAL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Distrito" name={"DISTRITO"} value={objeto["DISTRITO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Fachada" name={"FACHADA"} value={objeto["FACHADA"]} onChange={handleChangeField}/>
                <UiInputText label="Frente" name={"FRENTE"} value={objeto["FRENTE"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Fundos" name={"FUNDOS"} value={objeto["FUNDOS"]} onChange={handleChangeField}/>
                <UiInputText label="Gleba" name={"GLEBA"} value={objeto["GLEBA"]} onChange={handleChangeField}/>
                <UiInputText label="ID" name={"ID"} value={objeto["ID"]} onChange={handleChangeField}/>
                <UiInputText label="Lado" name={"LADO"} value={objeto["LADO"]} onChange={handleChangeField}/>
                <UiInputText label="Lado direto" name={"LADO_DIREITO"} value={objeto["LADO_DIREITO"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Lado esquerdo" name={"LADO_ESQUERDO"} value={objeto["LADO_ESQUERDO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Latitude" name={"LATITUDE"} value={objeto["LATITUDE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Longitude" name={"LONGITUDE"} value={objeto["LONGITUDE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Lote" name={"LOTE"} value={objeto["LOTE"]} onChange={handleChangeField}/>
                <UiInputText label="Matricula" name={"MATRICULA"} value={objeto["MATRICULA"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Nome do distrito" name={"NOME_DISTRITO"} value={objeto["NOME_DISTRITO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número" name={"NUMERO"} value={objeto["NUMERO"]} onChange={handleChangeField}/>
                <UiInputText label="Número do cadastro" name={"NUMERO_CADASTRO"} value={objeto["NUMERO_CADASTRO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número do imóvel" name={"NUMERO_IMOVEL"} value={objeto["NUMERO_IMOVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Número do lote" name={"NUMERO_LOTE"} value={objeto["NUMERO_LOTE"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Passeio" name={"PASSEIO"} value={objeto["PASSEIO"]} onChange={handleChangeField}/>
                <UiInputText label="Pavimentação" name={"PAVIMENTACAO"} value={objeto["PAVIMENTACAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Pedologia" name={"PEDOLOGIA"} value={objeto["PEDOLOGIA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Profundidade" name={"PROFUNDIDADE"} value={objeto["PROFUNDIDADE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Quadra" name={"QUADRA"} value={objeto["QUADRA"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Testada 1 - Extensão" name={"TESTADA_1_EXTENCAO"}
                             value={objeto["TESTADA_1_EXTENCAO"]} onChange={handleChangeField}/>
                <UiInputText label="Testada 1 - Face" name={"TESTADA_1_FACE"} value={objeto["TESTADA_1_FACE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 1 - Logradouro" name={"TESTADA_1_LOGRADOURO"}
                             value={objeto["TESTADA_1_LOGRADOURO"]} onChange={handleChangeField}/>
                <UiInputText label="Testada 1 - Número" name={"TESTADA_1_NUMERO"} value={objeto["TESTADA_1_NUMERO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 1 - Seção" name={"TESTADA_1_SECAO"} value={objeto["TESTADA_1_SECAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 1 - Tipo de logradouro" name={"TESTADA_1_TIPO"}
                             value={objeto["TESTADA_1_TIPO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Testada 2 - Extensão" name={"TESTADA_2_EXTENCAO"}
                             value={objeto["TESTADA_2_EXTENCAO"]} onChange={handleChangeField}/>
                <UiInputText label="Testada 2 - Face" name={"TESTADA_2_FACE"} value={objeto["TESTADA_2_FACE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 2 - Logradouro" name={"TESTADA_2_LOGRADOURO"}
                             value={objeto["TESTADA_2_LOGRADOURO"]} onChange={handleChangeField}/>
                <UiInputText label="Testada 2 - Número" name={"TESTADA_2_NUMERO"} value={objeto["TESTADA_2_NUMERO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 2 - Seção" name={"TESTADA_2_SECAO"} value={objeto["TESTADA_2_SECAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 2 - Tipo de logradouro" name={"TESTADA_2_TIPO"}
                             value={objeto["TESTADA_2_TIPO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Testada 3 - Extensão" name={"TESTADA_3_EXTENCAO"}
                             value={objeto["TESTADA_3_EXTENCAO"]} onChange={handleChangeField}/>
                <UiInputText label="Testada 3 - Face" name={"TESTADA_3_FACE"} value={objeto["TESTADA_3_FACE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 3 - Logradouro" name={"TESTADA_3_LOGRADOURO"}
                             value={objeto["TESTADA_3_LOGRADOURO"]} onChange={handleChangeField}/>
                <UiInputText label="Testada 3 - Número" name={"TESTADA_3_NUMERO"} value={objeto["TESTADA_3_NUMERO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 3 - Seção" name={"TESTADA_3_SECAO"} value={objeto["TESTADA_3_SECAO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada 3 - Tipo de logradouro" name={"TESTADA_3_TIPO"}
                             value={objeto["TESTADA_3_TIPO"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Secao" name={"SECAO"} value={objeto["SECAO"]} onChange={handleChangeField}/>
                <UiInputText label="Setor" name={"SETOR"} value={objeto["SETOR"]} onChange={handleChangeField}/>
                <UiInputText label="Situação na quadra" name={"SITUACAO_QUADRA"} value={objeto["SITUACAO_QUADRA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Testada" name={"TESTADA"} value={objeto["TESTADA"]} onChange={handleChangeField}/>
                <UiInputText label="Testada principal" name={"TESTADA_PRINCIPAL"} value={objeto["TESTADA_PRINCIPAL"]}
                             onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem>
                <UiInputText label="Tipo" name={"TIPO"} value={objeto["TIPO"]} onChange={handleChangeField}/>
                <UiInputText label="Tipo do imóvel" name={"TIPO_IMOVEL"} value={objeto["TIPO_IMOVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Tipo do logradouro" name={"TIPO_LOGRADOURO"} value={objeto["TIPO_LOGRADOURO"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Topografia" name={"TOPOGRAFIA"} value={objeto["TOPOGRAFIA"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Zona" name={"ZONA"} value={objeto["ZONA"]} onChange={handleChangeField}/>
            </UiFlexItem>
            <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlexItem>
        </UiFlexItem>
    )
}

export default EditInformacoesTerreno
