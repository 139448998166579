import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import UiButton from '../../../components/UiButton';
import {UiLoading} from '../../../components/UiLoading';
import {AppContext} from '../../../contexts/AppContext';
import {MapContext} from '../../../contexts/MapContext';
import {SessionContext} from '../../../contexts/SessionContext';
import {useFile} from '../../../hooks/useFile';
import {useGet} from '../../../hooks/useGet';
import {API} from '../../../utils/api';

export const ArquivosSection = ({infos}: any) => {
  const path = `/arquivos/${infos.idGeometria}`;
  const {appConfigState} = useContext(AppContext);
  const {headers: headersReq} = useContext(SessionContext);
  const {mapState} = useContext(MapContext);

  const [pickingImages, setPickingImages] = useState(false);
  const [shouldUpdate, updateFiles] = useState(Math.random());

  const files = useFile(pickingImages, '.pdf', false);

  useEffect(() => {
    const uploadFiles = async () => {
      if (files?.length) {
        const filesToSend = [...files];

        filesToSend.forEach(f => {
          try {
            const fd = new FormData();
            fd.append('file', f);
            API.overwriteFile(
              fd,
              `/arquivos/${mapState.selectedGeometry}/${f.name}`,
              appConfigState.idCidadeSelecionada,
              undefined,
              headersReq
            ).then(() => updateFiles(Math.random()));
          } catch (e) {
            console.error(e);
          }
        });

        setPickingImages(false);
      }
    };

    uploadFiles().then();
  }, [files, appConfigState.idCidadeSelecionada, headersReq, mapState.selectedGeometry]);

  const listFiles = useGet(
    `/drive/listfolder?path=${path}&cidade=${appConfigState.idCidadeSelecionada}&u=${shouldUpdate}`,
    headersReq
  );

  const handleUploadFile = () => setPickingImages(true);

  const renderFiles = () => {
    if (listFiles.loading) {
      return <UiLoading />;
    }

    if (listFiles.error || !listFiles.data?.length) {
      return <ListFilesInfos>Essa geometria ainda não possui arquivos</ListFilesInfos>;
    }

    return (
      <ListFiles>
        {listFiles.data.map((f: any) => (
          <File
            target="_blank"
            href={`${API.API_URL}/drive/download?path=${path}/${f.filename}&cidade=${appConfigState.idCidadeSelecionada}`}
            key={f.filename}
          >
            {f.filename}
          </File>
        ))}
      </ListFiles>
    );
  };

  return (
    <FileContainer>
      {renderFiles()}
      <Buttons>
        <UiButton onClick={handleUploadFile} primary small>
          Enviar arquivo
        </UiButton>
      </Buttons>
    </FileContainer>
  );
};

const FileContainer: any = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ListFilesInfos: any = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListFiles: any = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
const File: any = styled.a``;

const Buttons: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;
