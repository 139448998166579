import {FaSpinner} from 'react-icons/fa';
import styled, {keyframes} from 'styled-components';

const UiLoadingFullScreen = ({children}: any) => {
  return (
    <Loading>
      <Spinner>
        <FaSpinner size="35px" />
      </Spinner>
      {children}
    </Loading>
  );
};

const Loading: any = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Spin: any = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner: any = styled.div`
  animation: ${Spin} 2s linear infinite;
  margin-bottom: 20px;
`;

export default UiLoadingFullScreen;

