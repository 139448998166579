import UiButton from 'components/UiButton';
import {UiIconButton} from 'components/UiIconButton';
import UiInputText from 'components/UiInputText';
import UiModal from 'components/UiModal';
import UiBox from 'components/UiPanel';
import UiSelect from 'components/UiSelect';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import {useContext, useEffect, useMemo, useState} from 'react';
import {MdAdd, MdClose, MdDelete} from 'react-icons/md';
import {Transition} from 'react-transition-group';
import {REDUCER_MAPA_TEMATICO_ACTIONS} from 'reducers/mapatematico';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import UiFlex from '../components/UiFlex';
import {SessionContext} from '../contexts/SessionContext';
import {useGet} from '../hooks/useGet';

const duration = 100;

const opcoesMapaTematico = [
  {key: 'edificacao', value: 'Edificações'},
  {key: 'logradouro', value: 'Logradouros'},
  {key: 'lote', value: 'Lotes'},
];

const tipos = [
  {key: 'textual', value: 'Agrupamento'},
  {key: 'faixa_valores', value: 'Faixa de valores'},
];

const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ModalMapaTematico = () => {
  const {modalDispatch, mapaTematicoDispatch, modalState, appConfigState} = useContext(AppContext);
  const {sessionState} = useContext(SessionContext);

  const [caracteristicas, setCaracteristicas] = useState<any[]>([]);

  const [valorLegenda, setValorLegenda] = useState('');
  const [breakpoints, setBreakpoints] = useState([5, 10, 20, 25, 40]);
  const [form, setForm] = useState({
    camada: '',
    campo: '',
    tipo: '',
  });

  const headersReq = useMemo(() => ({Authorization: sessionState.token}), [sessionState.token]);
  const {data: caracteristicasReq, loading: loadingCarac} = useGet(
    `/commons/caracteristicas?tipo=${form.camada}&cidade=${appConfigState.idCidadeSelecionada}`,
    headersReq,
    undefined,
    form.camada
  );
  useEffect(() => {
    setForm(f => ({...f, campo: '', tipo: 'igual', valor: ''}));
    if (Array.isArray(caracteristicasReq)) {
      setCaracteristicas(caracteristicasReq.sort().map((e: any) => ({key: e, value: e})));
    }
  }, [caracteristicasReq]);

  const {data: valoresReq} = useGet(
    `/commons/valoresCaracteristicas?tipo=${form.camada}&cidade=${appConfigState.idCidadeSelecionada}&campo=${form.campo}&tipoAgrupamento=`,
    headersReq,
    undefined,
    form.campo
  );

  const gerarMapaTematico = async () => {
    try {
      const l: any = {};
      if (form.tipo === 'faixa_valores') {
        !breakpoints.length
          ? (l[0] = getRandomColor())
          : breakpoints.forEach(e => (l[e] = getRandomColor()));
      } else {
        valoresReq.forEach((e: any) => (l[e] = getRandomColor()));
      }

      mapaTematicoDispatch({type: REDUCER_MAPA_TEMATICO_ACTIONS.SET_ITEMS_LEGENDA, payload: l});
      mapaTematicoDispatch({
        type: REDUCER_MAPA_TEMATICO_ACTIONS.SET_PARAM_MAPA_TEMATICO,
        payload: {tipoCamada: form.camada, campo: form.campo, formaGeracaoDados: form.tipo},
      });
      modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_MAPA_TEMATICO});
      setCaracteristicas([]);
      setForm({
        camada: '',
        campo: '',
        tipo: '',
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddBreakpoint = () => {
    if (valorLegenda === '') {
      return;
    }

    let valores: any = new Set([...breakpoints, +valorLegenda]);
    valores = Array.from(valores).sort((a: any, b: any) => a - b);
    setBreakpoints(valores);
    setValorLegenda('');
  };

  const handleDeleteBreakpoint = (item: any) => {
    let newValores = breakpoints.filter(e => e !== item);
    setBreakpoints(newValores);
  };

  const renderBreakpoints = () => {
    if (form.tipo === 'faixa_valores') {
      return (
        <BKContainer>
          <UiFlex alignItems={'center'}>
            <UiInputText
              label={'Valores da legenda'}
              value={valorLegenda}
              onChange={(e: any) => setValorLegenda(e.target.value.replace(/\D/, ''))}
            />
            <UiIconButton
              icon={MdAdd}
              size={'1.5em'}
              margin={'15px 0 0 0'}
              onClick={handleAddBreakpoint}
              disabled={valorLegenda.trim() === ''}
            />
          </UiFlex>
          <UiFlex>
            <Breakpoints>
              {breakpoints?.map(e => (
                <Breakpoint key={e}>
                  {e}
                  <UiIconButton
                    icon={MdDelete}
                    size={'1.2em'}
                    color={'Salmon'}
                    onClick={() => handleDeleteBreakpoint(e)}
                  />
                </Breakpoint>
              ))}
            </Breakpoints>
          </UiFlex>
        </BKContainer>
      );
    }

    return null;
  };

  return (
    <Transition in={modalState.mapaTematico} timeout={duration}>
      {state => (
        <UiModal state={state} duration={duration}>
          <UiBox width={'90%'} maxWidth={'1000px'}>
            <UiFlex
              alignItems={'center'}
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText size="1.2em">Geração de mapa temático</UiText>
              <MdClose
                cursor="pointer"
                onClick={() => modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_MAPA_TEMATICO})}
                size={'1.3em'}
              />
            </UiFlex>
            <UiFlex breakpoint={'500px'}>
              <UiSelect
                label={'Mapa baseado em'}
                options={opcoesMapaTematico}
                selectText={'Selecione o tipo de camada'}
                value={form.camada}
                onChange={(e: any) => setForm({...form, camada: e.target.value})}
              />
              <UiSelect
                label={'Campo'}
                options={caracteristicas}
                loading={loadingCarac}
                disabled={!caracteristicas?.length}
                selectText={'Selecione o campo'}
                value={form.campo}
                onChange={(e: any) => setForm({...form, campo: e.target.value})}
              />
              <UiSelect
                label={'Tipo de filtro'}
                options={tipos}
                disabled={!form.campo}
                value={form.tipo}
                selectText={'Selecione o formato do mapa temático'}
                onChange={(e: any) => setForm({...form, tipo: e.target.value})}
              />
            </UiFlex>
            {renderBreakpoints()}
            <UiFlex
              justifyContent={'flex-end'}
              margin={'10px 10px 0 0'}
              width={'calc(100% - 10px)'}
            >
              <UiButton primary disabled={!form.tipo} onClick={gerarMapaTematico}>
                Gerar mapa temático
              </UiButton>
            </UiFlex>
          </UiBox>
        </UiModal>
      )}
    </Transition>
  );
};

const BKContainer: any = styled.div`
  margin-top: 20px;
  width: 40vh;
`;
const Breakpoints: any = styled.div`
  max-height: 200px;
  overflow: auto;
  padding: 20px;
  width: 100%;
`;

const Breakpoint: any = styled.div`
  align-items: center;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
`;

export default ModalMapaTematico;
