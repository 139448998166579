import styled from 'styled-components';

const UiRange = ({label, value, onChange, disabled, min, max, name}: any) => {
  return (
    <MainContainer onClick={(ev: any) => ev.stopPropagation()}>
      {label && <Label>{label}</Label>}
      <Input
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        min={min || 0}
        max={max || 100}
      />
    </MainContainer>
  );
};

const MainContainer: any = styled.div`
  color: #222;
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
`;

const Label: any = styled.label`
  font-size: 0.8em;
`;

const Input: any = styled.input.attrs({type: 'range'})`
  border: 1px solid #ddd;
  padding: 8px;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'normal')};

  &:focus {
    border: 1px solid #099cec;
  }
`;

export default UiRange;
