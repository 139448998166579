import {IModalState, IReducerAction} from "./IReducers";

export const REDUCER_MODAL_INITIAL: IModalState = {
    mapaTematico: false,
    impressao: false,
    buscaNoMapa: false,
    filtroNoMapa: false,
    mostrarImagens: false,
    googleStreetView: false
}

export const REDUCER_MODAL_ACTIONS = {
    CLOSE_MAPA_TEMATICO: 'CLOSE_MAPA_TEMATICO',
    OPEN_MAPA_TEMATICO: 'OPEN_MAPA_TEMATICO',
    TOGGLE_MAPA_TEMATICO: 'TOGGLE_MAPA_TEMATICO',

    CLOSE_BUSCA_MAPA: 'CLOSE_BUSCA_MAPA',
    OPEN_BUSCA_MAPA: 'OPEN_BUSCA_MAPA',
    TOGGLE_BUSCA_MAPA: 'TOGGLE_BUSCA_MAPA',

    CLOSE_FILTRO_MAPA: 'CLOSE_FILTRO_MAPA',
    OPEN_FILTRO_MAPA: 'OPEN_FILTRO_MAPA',
    TOGGLE_FILTRO_MAPA: 'TOGGLE_FILTRO_MAPA',

    CLOSE_IMAGENS_FAXADA: 'CLOSE_IMAGENS_FAXADA',
    OPEN_IMAGENS_FAXADA: 'OPEN_IMAGENS_FAXADA',

    CLOSE_GOOGLE_STREET_VIEW: 'CLOSE_GOOGLE_STREET_VIEW',
    OPEN_GOOGLE_STREET_VIEW: 'OPEN_GOOGLE_STREET_VIEW',

    OPEN_PRINT_MAP: 'OPEN_PRINT_MAP',
    CLOSE_PRINT_MAP: 'CLOSE_PRINT_MAP',
}

export const modalReducer = (state: IModalState, action: IReducerAction) => {
    switch (action.type) {
        case REDUCER_MODAL_ACTIONS.CLOSE_MAPA_TEMATICO:
            return {...state, mapaTematico: false}
        case REDUCER_MODAL_ACTIONS.OPEN_MAPA_TEMATICO:
            return {...state, mapaTematico: true}
        case REDUCER_MODAL_ACTIONS.TOGGLE_MAPA_TEMATICO:
            return {...state, mapaTematico: !state.mapaTematico}

        case REDUCER_MODAL_ACTIONS.CLOSE_BUSCA_MAPA:
            return {...state, buscaNoMapa: false}
        case REDUCER_MODAL_ACTIONS.OPEN_BUSCA_MAPA:
            return {...state, buscaNoMapa: true}
        case REDUCER_MODAL_ACTIONS.TOGGLE_BUSCA_MAPA:
            return {...state, buscaNoMapa: !state.buscaNoMapa}

        case REDUCER_MODAL_ACTIONS.CLOSE_FILTRO_MAPA:
            return {...state, filtroNoMapa: false}
        case REDUCER_MODAL_ACTIONS.OPEN_FILTRO_MAPA:
            return {...state, filtroNoMapa: true}
        case REDUCER_MODAL_ACTIONS.TOGGLE_FILTRO_MAPA:
            return {...state, filtroNoMapa: !state.filtroNoMapa}

        case REDUCER_MODAL_ACTIONS.CLOSE_IMAGENS_FAXADA:
            return {...state, mostrarImagens: false}
        case REDUCER_MODAL_ACTIONS.OPEN_IMAGENS_FAXADA:
            return {...state, mostrarImagens: action.payload}

        case REDUCER_MODAL_ACTIONS.CLOSE_GOOGLE_STREET_VIEW:
            return {...state, googleStreetView: false}
        case REDUCER_MODAL_ACTIONS.OPEN_GOOGLE_STREET_VIEW:
            return {...state, googleStreetView: action.payload}

        case REDUCER_MODAL_ACTIONS.OPEN_PRINT_MAP:
            return {...state, impressao: true}
        case REDUCER_MODAL_ACTIONS.CLOSE_PRINT_MAP:
            return {...state, impressao: false}

        default:
            return {...state}
    }
}
