import {Transition} from 'react-transition-group';
import UiModal from '../components/UiModal';
import UiBox from '../components/UiPanel';
import UiFlex from '../components/UiFlex';
import UiText from '../components/UiText';
import {MdClose} from 'react-icons/md';
import {REDUCER_MODAL_ACTIONS} from '../reducers/modals';
import UiButton from '../components/UiButton';
import React, {useContext} from 'react';
import {AppContext} from '../contexts/AppContext';
import {GoogleStreetView} from '../components/GoogleStreetView';

const duration = 100;

export const ModalStreetView = () => {
  const {modalState, modalDispatch} = useContext(AppContext);

  const handleCloseModal = () =>
    modalDispatch({type: REDUCER_MODAL_ACTIONS.CLOSE_GOOGLE_STREET_VIEW});

  return (
    <Transition in={Boolean(modalState.googleStreetView)} timeout={duration}>
      {state => (
        <UiModal state={state} duration={duration}>
          <UiBox width={'90%'} maxWidth={'1000px'}>
            <UiFlex
              alignItems={'center'}
              justifyContent={'space-between'}
              margin={'10px'}
              width={'calc(100% - 20px)'}
            >
              <UiText size="1.2em">Google Street View</UiText>
              <MdClose cursor="pointer" onClick={handleCloseModal} size={'1.3em'} />
            </UiFlex>
            <UiFlex alignItems={'center'} gap={'10px'} direction={'column'}>
              {modalState.googleStreetView && <GoogleStreetView />}
            </UiFlex>
            <UiFlex
              justifyContent={'flex-end'}
              margin={'10px 10px 0 0'}
              width={'calc(100% - 10px)'}
            >
              <UiButton primary onClick={handleCloseModal}>
                Fechar
              </UiButton>
            </UiFlex>
          </UiBox>
        </UiModal>
      )}
    </Transition>
  );
};
