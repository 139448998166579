import {AppContext} from "contexts/AppContext";
import React, {useContext, useEffect, useMemo, useReducer} from "react";
import {useLocation} from "react-router-dom";
import {IMapContext} from "./IContexts";
import {mapReducer, REDUCER_MAP_ACTIONS, REDUCER_MAP_INITIAL} from "../reducers/map";
import {useGet} from "../hooks/useGet";
import {SessionContext} from "./SessionContext";

export const MapContext = React.createContext<IMapContext>({
    mapState: REDUCER_MAP_INITIAL,
    mapDispatch: () => undefined
});

export const MapContextProvider = ({children}: any) => {
    const [mapState, mapDispatch] = useReducer(mapReducer, REDUCER_MAP_INITIAL)

    const {sessionState} = useContext(SessionContext)
    const {appConfigState} = useContext(AppContext);
    const location: any = useLocation();
    const headers = useMemo(() => ({'Authorization': sessionState.token}), [sessionState.token])
    const {data: layers} = useGet(`/cidade/${appConfigState.idCidadeSelecionada}/camadas`, headers)

    useEffect(() => localStorage.setItem("sidebarType", mapState.sidebarType), [mapState.sidebarType]);
    useEffect(() => {
        if (layers) mapDispatch({type: REDUCER_MAP_ACTIONS.SET_LAYERS, payload: layers})
    }, [layers])

    useEffect(() => {
        if (location.state) {
            const tipoCamada = location.state.tipoCamada;
            const ls = mapState.layers.filter((e: any) => e.tipoCamada === tipoCamada);
            mapDispatch({type: REDUCER_MAP_ACTIONS.SET_LAYERS, payload: ls})
        }
    }, [location.state, mapState.layers]);

    useEffect(() => {
        if (appConfigState.cidadeConfig) {
            if (mapState.selectedBackground.map === "bing" && !appConfigState.cidadeConfig?.chaveBing) {
                mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_BACKGROUND, payload: {map: "osm", type: ""}})
            }

            if (mapState.selectedBackground.map === "google" && !appConfigState.cidadeConfig?.chaveGoogle) {
                mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_BACKGROUND, payload: {map: "osm", type: ""}})
            }
        }
    }, [appConfigState.cidadeConfig, mapState.selectedBackground.map]);

    return (
        <MapContext.Provider value={{mapState, mapDispatch}}>
            {children}
        </MapContext.Provider>
    );
};
