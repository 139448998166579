import {useContext} from 'react';
import {AiOutlineArrowUp, AiOutlineFile, AiOutlineFolder} from 'react-icons/ai';
import styled from 'styled-components';
import {AppContext} from '../../contexts/AppContext';
import {SessionContext} from '../../contexts/SessionContext';
import {useGet} from '../../hooks/useGet';
import {humanReadableSize, renderDate} from '../../utils/utils';
import UiFlex from '../UiFlex';
import {UiLoading} from '../UiLoading';
import UiText from '../UiText';
import {MdFileDownload} from 'react-icons/md';
import {COLOR} from "utils/colors";
import {API} from 'utils/api';

export const UiListFiles = ({path, onChangePath, shouldUpdate}: any) => {
  const {headers: headersReq} = useContext(SessionContext);
  const {appConfigState} = useContext(AppContext);

  const listFiles = useGet(
    `/drive/listfolder?path=${path}&cidade=${appConfigState.idCidadeSelecionada}&u=${shouldUpdate}`,
    headersReq
  );

  const isFile = (t: string) => {
    switch (t) {
      case 'directory':
      case 'back':
        return false
    }

    return true
  }

  const getIcon = (t: string) => {
    switch (t) {
      case 'directory':
        return <AiOutlineFolder />;
      case 'back':
        return <AiOutlineArrowUp />;
      default:
        return <AiOutlineFile />;
    }
  };

  const handleSelectItem = (file: any) => {
    if (file.type === 'directory') {
      let newPath = `${path}${file.filename}/`;
      onChangePath(newPath);
    } else if (file.type === 'back') {
      const pathSplitted = path.split('/');
      pathSplitted.pop();
      pathSplitted.pop();
      let newPath = `${pathSplitted.join('/')}` || '/';
      if (!newPath.endsWith('/')) {
        newPath = `${newPath}/`;
      }
      onChangePath(newPath);
    }
  };

  const shouldPointer = (file: any) => file.type === 'directory' || file.type === 'back';

  const renderFileRow = (file: any) => (
    <File
      cursor={shouldPointer(file) ? 'pointer' : 'default'}
      key={file.filename}
      onClick={() => handleSelectItem(file)}
    >
      <UiFlex cursor={shouldPointer(file) ? 'pointer' : 'default'}>
        <Icon>{getIcon(file.type)}</Icon>
        <Name>{file.filename}</Name>
      </UiFlex>
      <UiFlex justifyContent="center" cursor={shouldPointer(file) ? 'pointer' : 'default'}>
        <ModTime>{renderDate(file.modTime)}</ModTime>
      </UiFlex>
      <UiFlex justifyContent="center" cursor={shouldPointer(file) ? 'pointer' : 'default'}>
        <Size>{humanReadableSize(file.size)}</Size>
      </UiFlex>
      {
        isFile(file.type) ? (
          <UiFlex justifyContent="right">
            <Icon>
              <MdFileDownload color={COLOR.PRIMARY} size="1.3em"
                onClick={() => window.open(`${API.EXPORT_URL}/download?path=${path}${file.filename}&cidade=${appConfigState.idCidadeSelecionada}`)}
                cursor="pointer" />
            </Icon>
          </UiFlex>
        ) : null
      }
    </File>
  );

  const renderListFiles = () => (
    <UiFlex direction={'column'} maxHeight={'calc(100% - 210px)'} overflow={'auto'}>
      <UiFlex margin={'0 0 5px 0'}>
        <UiFlex>
          <UiText bold>Nome do arquivo</UiText>
        </UiFlex>
        <UiFlex>
          <UiText bold>Data de modificação</UiText>
        </UiFlex>
        <UiFlex>
          <UiText bold>Tamanho</UiText>
        </UiFlex>
      </UiFlex>
      <UiFlex direction="column" maxHeight={'calc(100vh - 210px)'} overflow="auto">
        {path !== '/' && renderFileRow({type: 'back', filename: 'Voltar', size: ''})}
        {listFiles?.data?.map((file: any) => renderFileRow(file))}
      </UiFlex>
    </UiFlex>
  );

  return (
    <UiFlex direction={'column'} margin={'20px 0 0 0'}>
      {listFiles.loading ? <UiLoading /> : renderListFiles()}
    </UiFlex>
  );
};

const Icon: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 2px;
  font-size: 1.1em;
`;

const Name: any = styled.div`
  flex-grow: 2;
`;

const ModTime: any = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const Size: any = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const Actions: any = styled.div`
  visibility: hidden;
  margin: 0 5px;
`;

const File: any = styled.div<any>`
  cursor: ${({cursor}) => cursor || 'default'};
  min-height: 28px;
  display: flex;
  align-items: center;
  width: 100%;

  :hover {
    background-color: #efefef;
  }

  :hover ${Actions} {
    visibility: visible;
  }
`;
