import {OpenLayersContext} from "contexts/OpenLayersContext";
import {Select} from "ol/interaction";
import {useContext, useEffect} from "react";
import {REDUCER_OPENLAYERS_ACTIONS} from "../../../../reducers/openlayers";

export const DeleteGeometry = () => {
    const {openLayersDispatch, openLayersState} = useContext(OpenLayersContext);

    useEffect(() => {
        if (openLayersState.map && openLayersState.deletingGeometry) {
            const condFunc = (e: any) => e.type === "click"

            let select = new Select({condition: condFunc});
            select.on("select", function (selectEvent) {
                const ls = openLayersState.map.getLayers();
                ls.forEach((e: any) => {
                    let source = e.getSource();
                    let feature = selectEvent.selected[0];
                    if (feature && source?.hasFeature && source?.hasFeature(feature)) {
                        source.removeFeature(feature);
                        openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.DELETE_GEOMETRY, payload: feature})
                    }
                });
            });

            openLayersState.map.addInteraction(select);

            return () => {
                openLayersState.map.removeInteraction(select)
            }
        }
    }, [openLayersState.map, openLayersState.deletingGeometry, openLayersDispatch]);

    return null;
};
