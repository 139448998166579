import UiFlex from "components/UiFlex";
import {UiIconButton} from "components/UiIconButton";
import UiPaginator from "components/UiPaginator";
import UiBox from "components/UiPanel";
import UiTable from "components/UiTable";
import UiText from "components/UiText";
import React, {useContext, useEffect, useState} from "react";
import {FaEye} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {SessionContext} from "contexts/SessionContext";
import {UiLoading} from "../../../components/UiLoading";
import {AppContext} from "../../../contexts/AppContext";
import {renderArea, renderDocumento, renderMoney} from "../../../utils/utils";
import {useGet} from "../../../hooks/useGet";
import RegisterPage from "../index";

export const LotesPage = () => {
    const {sessionState, headers: headersReq} = useContext(SessionContext);
    const {appConfigState} = useContext(AppContext)

    const [offset, setOffset] = useState(0);
    const [limit] = useState(Math.floor((window.innerHeight - 206) / 34));

    const [actualPage, setActualPage] = useState(0);

    const navigate = useNavigate();

    const {data: totalCount} = useGet(`/lotes/count?cidade=${appConfigState.idCidadeSelecionada}`, headersReq)
    const {data: lotes} = useGet(`/lotes?cidade=${appConfigState.idCidadeSelecionada}&skip=${offset}&limit=${limit}&filter=`,
        headersReq, undefined, totalCount)

    useEffect(() => {
        setOffset(actualPage * limit);
    }, [actualPage, limit]);

    const renderTable = () => !lotes ? <UiLoading/> :
        <UiTable rows={lotes} headers={headers} noDataText={"Nenhum lote cadastrado"}/>

    const handleChangePage = (e: any) => setActualPage(e)

    const renderActions = (e: any) => {
        return (
            <>
                <FaEye size="1.2em" color="#099cec" cursor="pointer" onClick={() => {
                    navigate('/', {state: {...e, tipoCamada: "lote"}});
                }}/>
                {sessionState.permissoes?.postLotes ? (
                    <UiIconButton icon={MdEdit} size="1.2em" color="warning" onClick={() => {
                        navigate("/cadastros/lotes/editar", {state: e});
                    }}/>
                ) : null}
            </>
        );
    };

    const headers = [
        {title: "Inscrição Imoliaria", field: "idGeometria"},
        {title: "Area do lote", field: "AREA_LOTE", applyRender: renderArea},
        {title: "Nome do contribuinte", field: "CONTRIBUINTE"},
        {title: "CPF/CNPJ", field: "CPF_CNPJ", applyRender: renderDocumento},
        {title: "Endereço", field: "LOGRADOURO"},
        {title: "Bairro", field: "BAIRRO"},
        {title: "Valor Venal", field: "VALOR_VENAL_IMOVEL", applyRender: renderMoney},
        {actions: renderActions},
    ];

    return (
        <RegisterPage>
            <UiFlex direction={"column"}>
                <UiBox>
                    <UiFlex alignItems="center" justifyContent={"space-between"} margin={"5px 10px"}
                            width={"calc(100% - 20px)"}>
                        <UiText bold size={"1.2em"}>Lotes</UiText>
                    </UiFlex>
                    {renderTable()}
                </UiBox>
                {lotes?.length > 0 && (
                    <UiPaginator totalCount={totalCount} actualPage={actualPage} onChangePage={handleChangePage}/>
                )}
            </UiFlex>
        </RegisterPage>
    );
};
