import {GiSpinalCoil} from 'react-icons/gi';
import styled, {keyframes} from 'styled-components';

const getColor = (props: any) => {
  if (props.color) return props.color;
  if (props.disabled || props.loading) return '#FFF';
  if (props.primary) return 'white';
  if (props.warning) return 'DarkOrange';
  if (props.danger) return 'red';
};

const getBackgroundColor = (props: any) => {
  if (props.disabled || props.loading) return '#BBB';
  if (props.secondary) return 'transparent';
  if (props.primary) return '#099cec';
  if (props.danger || props.warning) return 'white';
};

const getBorder = (props: any) => {
  if (props.disabled || props.loading) return '1px solid #BBB';
  if (props.secondary) return 'transparent';
  if (props.primary) return '1px solid #099cec';
  if (props.danger) return '1px solid red';
  if (props.warning) return '1px solid DarkOrange';
};

const UiButton = ({children, loading, ...props}: any) => {
  return (
    <Button loading={loading} {...props}>
      {loading && (
        <Loading>
          <GiSpinalCoil />
        </Loading>
      )}
      {children}
    </Button>
  );
};

const Button: any = styled.button<any>`
  background-color: ${getBackgroundColor};
  border: ${getBorder};
  color: ${getColor};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${props => props.margin || '0'};
  padding: ${props => (props.small ? '4px 10px' : '8px 20px')};
`;

const spinner = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Loading: any = styled.div`
  margin: 0 5px;
  animation: ${spinner} 0.6s linear infinite;
`;

export default UiButton;
