import {OpenLayersContext} from "contexts/OpenLayersContext";
import {METERS_PER_UNIT} from "ol/proj/Units";
import React, {useContext, useEffect, useState} from "react";
import UiFlex from "../../../components/UiFlex";

const InfoBar = () => {
    const {openLayersState} = useContext(OpenLayersContext);
    const [scale, setScale] = useState<number>(0);

    useEffect(() => {
        const mapScale = (dpi: any) => {
            let unit = openLayersState.map.getView().getProjection().getUnits();
            let resolution = openLayersState.map.getView().getResolution();
            if (!resolution) {
                return 0
            }
            let inchesPerMetre = 39.37;
            let mpu: any = METERS_PER_UNIT
            return resolution * mpu[unit] * inchesPerMetre * dpi;
        };

        if (openLayersState.map) {
            setScale(Math.floor(mapScale(72)));
        }
    }, [openLayersState.map, openLayersState.zoom]);

    return (
        <UiFlex minHeight={"20px"} alignItems={"center"} justifyContent={"space-between"}>
            <UiFlex margin={"0 0 0 20px"} maxWidth={"400px"}>
                <UiFlex>{openLayersState.coordenadas.latitude || ""}</UiFlex>
                <UiFlex>{openLayersState.coordenadas.longitude || ""}</UiFlex>
            </UiFlex>
            <UiFlex maxWidth={"400px"} justifyContent={"flex-end"}>
                <UiFlex>Escala: 1 : {scale}</UiFlex>
                <UiFlex>Zoom: {Math.floor(openLayersState.zoom)}</UiFlex>
            </UiFlex>
        </UiFlex>
    );
};

export default InfoBar;
