import UiButton from 'components/UiButton';
import UiFlexItem from 'components/UiFlex';
import UiText from 'components/UiText';
import styled from 'styled-components';

const UiModalYesNo = ({title, onYes, onNo, body, show}: any) => {
  if (!show) return null;

  return (
    <Modal>
      <Container>
        <UiFlexItem>
          <UiText bold size="1.1em">
            {title}
          </UiText>
        </UiFlexItem>
        <UiFlexItem margin={'20px 3px'}>
          <UiText>{body}</UiText>
        </UiFlexItem>
        <UiFlexItem justifyContent="flex-end">
          <UiButton primary onClick={onNo}>
            Cancelar
          </UiButton>
          <Spacer />
          <UiButton danger onClick={onYes}>
            Excluir
          </UiButton>
        </UiFlexItem>
      </Container>
    </Modal>
  );
};

const Spacer: any = styled.div`
  margin: 0 6px;
`;

const Modal: any = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  align-items: center;
  background-color: #f7f8f9cc;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

const Container: any = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  width: 400px;
`;

export default UiModalYesNo;

