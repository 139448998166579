import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {SessionContext} from 'contexts/SessionContext';
import {GeoJSON} from 'ol/format';
import {useContext, useState} from 'react';
import styled from 'styled-components';
import {API} from 'utils/api';
import {AppContext} from '../../../../contexts/AppContext';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../../reducers/openlayers';
import {getSourceForLastLayer} from '../../../../utils/openlayers';

const SaveControl = ({show}: any) => {
  const {headers: headersReq} = useContext(SessionContext);
  const {appConfigState} = useContext(AppContext);
  const {openLayersState, openLayersDispatch} = useContext(OpenLayersContext);
  const {mapState} = useContext(MapContext);

  const [loading, setLoading] = useState(false);

  const saveGeoJSON = async () => {
    if (mapState.selectedLayers?.length > 0) {
      setLoading(true);
      if (openLayersState.activeDrawTool) {
        const source = getSourceForLastLayer(openLayersState.map.getLayers());
        let features = source.getFeatures();

        let newFeatures: any[] = [];
        features.forEach((e: any) => {
          if (!e.getId()) {
            newFeatures.push(e);
          }
        });

        const geoJSON = new GeoJSON();
        const featColl = geoJSON.writeFeaturesObject(newFeatures);
        featColl.features = featColl.features.filter((feat: any) => {
          return feat.geometry.type !== 'GeometryCollection';
        });

        try {
          await API.saveLayerDraw(
            mapState.selectedLayers[mapState.selectedLayers.length - 1],
            featColl.features,
            appConfigState.idCidadeSelecionada,
            headersReq
          );
          openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.DISABLE_DRAW});
        } catch (err) {
          console.error(err);
        }
      } else if (openLayersState.deletingGeometry) {
        try {
          await API.deleteFeatures(
            mapState.selectedLayers[mapState.selectedLayers.length - 1],
            openLayersState.deletedGeometries.map((f: any) => f.getId()),
            appConfigState.idCidadeSelecionada,
            headersReq
          );
          openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.RESET_DELETE_GEOMETRY});
        } catch (err) {
          console.error(err);
        }
      }
      setLoading(false);
    }
  };

  if (!show) return null;

  return (
    <Button primary onClick={saveGeoJSON} disabled={loading}>
      Salvar
    </Button>
  );
};

const Button: any = styled.button<any>`
  background-color: ${({disabled}) => (disabled ? 'gray' : '#099cec')};
  border: 1px solid ${({disabled}) => (disabled ? 'gray' : '#099cec')};
  border-radius: 3px;
  color: ${({disabled}) => (disabled ? 'black' : 'white')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 5px 10px;
`;

export default SaveControl;
