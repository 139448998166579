export const menus = (usuario: any) => {
    const m = [{id: 1, text: "Mapa", urls: ["/"]}];
    if (usuario) {
        m.push({
            id: 2,
            text: "Minha cidade",
            urls: [
                "/cadastros/camadas",
                "/cadastros/edificacoes",
                "/cadastros/logradouros",
                "/cadastros/logradouros/editar",
                "/cadastros/lotes",
                "/cadastros/camadas/adicionar",
                "/cadastros/camadas/editar",
                "/cadastros/usuarios",
                "/cadastros/usuarios/adicionar",
                "/cadastros/usuarios/editar",
                "/cadastros/arquivos",
                "/cadastros/configuracoes",
                "/cadastros/lotes/editar",
                "/cadastros/edificacoes/editar",
                "/cadastros/audit",
                "/cadastros/envios",
            ],
        });
    }

    if (usuario?.superAdmin) {
        m.push({
            id: 3,
            text: "Configurações",
            urls: [
                "/configuracoes/geral",
                "/configuracoes/cidades",
                "/configuracoes/cidades/adicionar",
                "/configuracoes/cidades/editar",
                "/configuracoes/superusuarios",
                "/configuracoes/superusuarios/adicionar",
                "/configuracoes/superusuarios/editar",
            ],
        });
    }

    return m;
};
