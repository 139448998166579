export const sessoes: any = {
    DOCUMENTOS_PESSOAIS: "Dados pessoais",
    ENDERECO: "Endereço",
    EDIFICACAO: "Informações da edificação",
    INFORMACOES_TERRENO: "Informações do terreno",
    INFORMACOES_PUBLICA: "Informações publicas",
    INFORMACOES_COMPLEMENTARES: "Informações complementares",
    INFORMACOES_GENERICA: "Informações genérica",
    DATAS: "Datas",
    PLANO_DIRETOR: "Plano diretor",
};
