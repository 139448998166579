import {UiInfo} from "components/UiInfo";

const InfosComplementares = ({infos}: any) => (
    <>
        <UiInfo label="Adquirente" value={infos["ADQUIRENTE"]}/>
        <UiInfo label="Benefício Lei" value={infos["BENEFICIO_LEI"]}/>
        <UiInfo label="Bloco terreno" value={infos["BLOCO_TERRENO"]}/>
        <UiInfo label="Bloco Contribuinte" value={infos["BLOCO_CONTRIBUINTE"]}/>
        <UiInfo label="Educações menores" value={infos["EDUCACAO_MENORES"]}/>
        <UiInfo label="Mensagem" value={infos["MENSAGEM"]}/>
        <UiInfo label="Nome do cartório" value={infos["NOME_CARTORIO"]}/>
        <UiInfo label="Número da conta corrente" value={infos["NUMERO_CONTA_CORRENTE"]}/>
        <UiInfo label="Observacao" value={infos["OBSERVACAO"]}/>
        <UiInfo label="Endereço internet" value={infos["ENDERECO_INTERNET"]}/>
    </>
)

export default InfosComplementares;
