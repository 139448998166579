import {MdFileUpload} from 'react-icons/md';
import styled from 'styled-components';

const UiFilePicker = ({label, accept, multiple, onChange, value, inputRef}: any) => {
  const activeFileChooser = () => {
    inputRef.current.click();
  };

  return (
    <Container onClick={activeFileChooser}>
      <input
        type="file"
        style={{display: 'none'}}
        ref={inputRef}
        accept={accept}
        multiple={multiple}
        onChange={onChange}
        value={value}
      />
      <Icon>
        <MdFileUpload />
      </Icon>
      {label}
    </Container>
  );
};

const Container: any = styled.div`
  align-items: center;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  font-size: 0.85em;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  margin: 10px;
  width: calc(100% - 20px);
`;
const Icon: any = styled.div`
  font-size: 2.5em;
`;

export default UiFilePicker;

