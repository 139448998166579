import {OpenLayersContext} from "contexts/OpenLayersContext";
import {LineString, Polygon} from "ol/geom";
import {Snap} from "ol/interaction";
import Draw from "ol/interaction/Draw";
import {Vector as VectorLayer} from "ol/layer";
import {unByKey} from "ol/Observable";
import Overlay, {Positioning} from "ol/Overlay";
import {Vector as VectorSource} from "ol/source";
import {getArea, getLength} from "ol/sphere";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import {useContext, useEffect, useRef} from "react";
import {getSourceForLastLayer} from "../../../../utils/openlayers";
import {Type} from "ol/geom/Geometry";

const MeasureLayer = () => {
    const {openLayersState} = useContext(OpenLayersContext);
    const sourceDraw = useRef<any>();

    const formatLength = (line: any) => {
        let length = getLength(line);
        length = length * 1000 * 100;
        return Math.round(length * 100) / 100 + " m";
    };

    const formatArea = (polygon: any) => {
        let area = getArea(polygon);
        area = area * 1000000 * 100 * 100;
        return Math.round(area * 100) / 100 + " m<sup>2</sup>";
    };

    useEffect(() => {
        if (openLayersState.map && openLayersState.activeMeasureTool) {
            sourceDraw.current = new VectorSource();

            let layer = new VectorLayer({
                source: sourceDraw.current,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(255, 255, 255, 0.2)",
                    }),
                    stroke: new Stroke({
                        color: "#ffcc33",
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: "#ffcc33",
                        }),
                    }),
                }),
            });
            openLayersState.map.addLayer(layer);

            return () => {
                openLayersState.map.removeLayer(layer);
            };
        }
    }, [openLayersState.map, openLayersState.activeMeasureTool]);

    useEffect(() => {
        const criaTooltipMedida = () => {
            let overlayElement = document.createElement("div");
            overlayElement.className = "ol-tooltip ol-tooltip-measure";

            let t = new Overlay({
                element: overlayElement,
                offset: [0, -15],
                positioning: 'bottom-center' as Positioning,
            });
            openLayersState.map.addOverlay(t);
        };

        const getLastOverlay = () => {
            let all = openLayersState.map.getOverlays();
            return all.item(all.getLength() - 1);
        };

        let draw: any = null;
        let snap: any = null;
        if (openLayersState.map && openLayersState.activeMeasureTool) {
            let type: Type = openLayersState.activeMeasureTool === "area" ? 'Polygon' : 'LineString';
            let listener: any = null;
            let sketch = null;

            draw = new Draw({
                source: sourceDraw.current,
                type: type,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(255, 255, 255, 0.2)",
                    }),
                    stroke: new Stroke({
                        color: "rgba(0, 0, 0, 0.5)",
                        lineDash: [10, 10],
                        width: 2,
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({
                            color: "rgba(0, 0, 0, 0.7)",
                        }),
                        fill: new Fill({
                            color: "rgba(255, 255, 255, 0.2)",
                        }),
                    }),
                }),
            });


            const source = getSourceForLastLayer(openLayersState.map.getLayers())
            if (source.getFeatures) {
                snap = new Snap({source});
            }


            draw.on("drawstart", (evt: any) => {
                sketch = evt.feature;
                let tooltipCoord = evt.coordinate;

                listener = sketch.getGeometry().on("change", function (evt: any) {
                    let geom = evt.target;
                    let output;
                    if (geom instanceof Polygon) {
                        output = formatArea(geom);
                        tooltipCoord = geom.getInteriorPoint().getCoordinates();
                    } else if (geom instanceof LineString) {
                        output = formatLength(geom);
                        tooltipCoord = geom.getLastCoordinate();
                    }

                    const lastElement = getLastOverlay();
                    const el = lastElement.getElement()
                    if (el) {
                        el.innerHTML = output || ''
                        lastElement.setElement(el)
                    }
                    lastElement.setPosition(tooltipCoord);
                });
            });

            draw.on("drawend", () => {
                const lastElement = getLastOverlay();
                const el = lastElement.getElement()
                if (el) {
                    el.className = "ol-tooltip ol-tooltip-static"
                    lastElement.setElement(el)
                }

                lastElement.setOffset([0, -7]);
                sketch = null;
                criaTooltipMedida();
                unByKey(listener);
            });

            openLayersState.map.addInteraction(draw);
            if (snap) openLayersState.map.addInteraction(snap);

            let layers = openLayersState.map.getLayers();
            let i = layers.getLength() - 2;
            if (i >= 0) {
                let lastLayer = layers.item(i);
                let source = (lastLayer as any).getSource();
                if (source && source.getFeatures) {
                    snap = new Snap({
                        source: source,
                    });

                    openLayersState.map.addInteraction(snap);
                }
            }
            criaTooltipMedida();

            return () => {
                if (openLayersState.map && draw) {
                    openLayersState.map.getOverlays().clear();
                    openLayersState.map.removeInteraction(draw);
                    if (snap) openLayersState.map.removeInteraction(snap)
                }
            };
        }
    }, [openLayersState.map, openLayersState.activeMeasureTool, sourceDraw]);

    return null;
};

export default MeasureLayer;
