import UiLoadingFullScreen from 'components/UiLoadingFullScreen';
import {AppContext} from 'contexts/AppContext';
import {register} from 'ol/proj/proj4';
import MapPage from 'pages/MapPage';
import proj4 from 'proj4';
import {useContext, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {Navigate, Route, Routes} from 'react-router-dom';
import {REDUCER_APP_CONFIG_ACTIONS} from 'reducers/appconfig';
import {REDUCER_SESSION_ACTIONS} from 'reducers/session';
import {SessionContext} from './contexts/SessionContext';
import ConfigPage from './pages/ConfigPage';
import AddCidadePage from './pages/ConfigPage/CidadesPage/AddCidadePage';
import TabelaCidades from './pages/ConfigPage/CidadesPage/TabelaCidades';
import GeralConfigPage from './pages/ConfigPage/GeralConfigPage';
import {SuperUsuariosPage} from './pages/ConfigPage/SuperUsuariosPage';
import RegisterPage from './pages/MinhaCidadePage';
import {ArquivosPage} from './pages/MinhaCidadePage/ArquivosPage';
import TabelaAudit from './pages/MinhaCidadePage/AuditPage/TabelaAudit';
import {EdificacoesPage} from './pages/MinhaCidadePage/EdificacoesPage';
import EditEdificacao from './pages/MinhaCidadePage/EdificacoesPage/EditEdificacao';
import TabelaEnvios from './pages/MinhaCidadePage/EnviosPage/TabelaEnvios';
import LayersPage from './pages/MinhaCidadePage/LayersPage';
import AddLayerPage from './pages/MinhaCidadePage/LayersPage/AddLayerPage';
import {LogradouroPages} from './pages/MinhaCidadePage/LogradourosPage';
import EditLogradouro from './pages/MinhaCidadePage/LogradourosPage/EditLogradouro';
import {LotesPage} from './pages/MinhaCidadePage/LotesPage';
import EditLote from './pages/MinhaCidadePage/LotesPage/EditLote';
import MinhaCidadeConfigPage from './pages/MinhaCidadePage/MinhaCidadeConfigPage';
import {UsuariosPage} from './pages/MinhaCidadePage/UsuariosPage';
import AddUsuarioPage from './pages/MinhaCidadePage/UsuariosPage/AddUsuarioPage';
import {SelecaoCidadePage} from './pages/SelecaoCidadePage';
import {SelectCidadeComponent} from './pages/SelectCidadeComponent';

const App = () => {
  const {appConfigState, appConfigDispatch} = useContext(AppContext);
  const {sessionState, sessionDispatch} = useContext(SessionContext);

  useEffect(() => {
    ReactGA.initialize('G-NF27950JKB');
    ReactGA.send({hitType: 'pageview', page: window.location.pathname});
  }, []);

  if (!appConfigState.idCidadeSelecionada) {
    return <SelecaoCidadePage />;
  } else if (!sessionState.cidadeSelecionadaSessao) {
    return <UiLoadingFullScreen>Carregando sessão do usuário</UiLoadingFullScreen>;
  }

  proj4.defs(
    'EPSG:31982',
    '+proj=utm +zone=22 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  register(proj4);

  const handleSelectCidade = () => {
    sessionStorage.removeItem('zoom');
    sessionStorage.removeItem('centerLongitude');
    sessionStorage.removeItem('centerLatitude');
    localStorage.removeItem('token');
    sessionDispatch({type: REDUCER_SESSION_ACTIONS.CLEAR_TOKEN});
    appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.CLEAR_ID_CIDADE_SELECIONADA});
  };

  if (appConfigState.cidades) {
    const cidadeSelecionada = appConfigState.cidades.find(
      cidade => cidade.id === appConfigState.idCidadeSelecionada
    );

    if (!cidadeSelecionada) {
      return (
        <div style={{paddingTop: '10%', textAlign: 'center', fontSize: '25px'}}>
          Ops, parece que essa cidade não existe! <br />
          <button onClick={handleSelectCidade}>Escolher outra cidade</button>
        </div>
      );
    }
  }

  if (!sessionState.usuario && sessionState.token) {
    return <UiLoadingFullScreen>Carregando sessão do usuário</UiLoadingFullScreen>;
  }

  if (sessionState.usuario) {
    return (
      <Routes>
        <Route path="/" element={<MapPage />} />
        <Route path="cadastros">
          <Route path="camadas" element={<LayersPage />} />
          <Route path="camadas/adicionar" element={<AddLayerPage />} />
          <Route path="camadas/editar" element={<AddLayerPage />} />
          <Route path="lotes" element={<LotesPage />} />
          <Route path="lotes/editar" element={<EditLote />} />
          <Route path="logradouros" element={<LogradouroPages />} />
          <Route path="logradouros/editar" element={<EditLogradouro />} />
          <Route path="edificacoes" element={<EdificacoesPage />} />
          <Route path="edificacoes/editar" element={<EditEdificacao />} />
          <Route path="usuarios" element={<UsuariosPage />} />
          <Route
            path="usuarios/adicionar"
            element={
              <RegisterPage>
                <AddUsuarioPage />
              </RegisterPage>
            }
          />
          <Route
            path="usuarios/editar"
            element={
              <RegisterPage>
                <AddUsuarioPage />
              </RegisterPage>
            }
          />
          <Route path="arquivos" element={<ArquivosPage />} />
          <Route path="configuracoes" element={<MinhaCidadeConfigPage />} />
          <Route path="audit" element={<TabelaAudit />} />
          <Route path="envios" element={<TabelaEnvios />} />
        </Route>

        <Route path="configuracoes">
          <Route path="geral" element={<GeralConfigPage />} />
          <Route path="cidades" element={<TabelaCidades />} />
          <Route path="cidades/adicionar" element={<AddCidadePage />} />
          <Route path="cidades/editar" element={<AddCidadePage />} />
          <Route path="superusuarios" element={<SuperUsuariosPage />} />
          <Route
            path="superusuarios/adicionar"
            element={
              <ConfigPage>
                <AddUsuarioPage superAdmin />
              </ConfigPage>
            }
          />
          <Route
            path="superusuarios/editar"
            element={
              <ConfigPage>
                <AddUsuarioPage superAdmin />
              </ConfigPage>
            }
          />
        </Route>

        <Route path="cidade/:id" element={<SelectCidadeComponent />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<MapPage />} />
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default App;
