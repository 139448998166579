import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {Control} from 'ol/control';
import {useContext, useEffect, useRef} from 'react';
import styled from 'styled-components';

const ButtonIconControl = ({onClick, title, icon, position, iconSize, disabled}: any) => {
  const {mapState} = useContext(MapContext);
  const {openLayersState} = useContext(OpenLayersContext);
  const component = useRef<any>();

  useEffect(() => {
    if (openLayersState.map && component.current && mapState.sidebarType === 'in') {
      const control = new Control({element: component.current});
      openLayersState.map.addControl(control);

      return () => {
        openLayersState.map.removeControl(control);
      };
    }
  }, [openLayersState.map, mapState.sidebarType]);

  return (
    <Container
      ref={component}
      onClick={!disabled ? onClick : () => {}}
      show={mapState.sidebarType === 'in'}
      title={title}
      position={position}
    >
      {icon({
        color: disabled ? 'gray' : '#099cec',
        size: iconSize || '1.7em',
        cursor: 'pointer',
      })}
    </Container>
  );
};

const Container: any = styled.div<any>`
  align-items: center;
  background-color: white;
  box-shadow: 0 0 2px silver;
  display: ${({show}) => (show ? 'flex' : 'none')};
  justify-content: center;
  padding: 5px;

  position: absolute;
  left: 20px;
  top: ${({position}) => 10 + (position - 1) * 40}px;
  height: 40px;
  width: 40px;
`;

export default ButtonIconControl;
