import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import TileLayer from 'ol/layer/Tile';
import {XYZ} from 'ol/source';
import {useContext, useEffect} from 'react';
import {API} from 'utils/api';
import {AppContext} from '../../../../contexts/AppContext';

const createLayer = (url: string, idCidadeSelecionada: string, type: string) => {
  let tileImage = new XYZ({
    url: `${url}?id=${idCidadeSelecionada}&qualidade=${type}`,
  });
  let layer = new TileLayer({source: tileImage});
  layer.setZIndex(1);
  return layer;
};

export const VantImageLayer = () => {
  const {appConfigState} = useContext(AppContext);
  const {openLayersState} = useContext(OpenLayersContext);
  const {mapState} = useContext(MapContext);

  useEffect(() => {
    if (mapState.selectedOrtoimagem.type) {
      let url = `${API.ORTO_URL}/image/vantt/{z}/{x}/{y}`;
      if (openLayersState.map && mapState.selectedOrtoimagem.type === 'mde') {
        url = `${API.ORTO_URL}/image/mde/{z}/{x}/{y}`;
      }

      const layer = createLayer(
        url,
        appConfigState.idCidadeSelecionada,
        mapState.selectedOrtoimagem.type
      );
      openLayersState.map.addLayer(layer);

      return () => {
        openLayersState.map.removeLayer(layer);
      };
    }
  }, [openLayersState.map, mapState.selectedOrtoimagem.type, appConfigState.idCidadeSelecionada]);

  return null;
};
