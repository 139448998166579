import {UiInfo} from 'components/UiInfo'

const InfosGenericas = ({infos}: any) => (
    <>
        <UiInfo label="CÓDIGO" value={infos["CODIGO"]}/>
        <UiInfo label="Código Agência" value={infos["CODIGO_AGENCIA"]}/>
        <UiInfo label="Código Bairro" value={infos["CODIGO_BAIRRO"]}/>
        <UiInfo label="Códogo Bairro Corresponsável" value={infos["CODIGO_BAIRRO_CORRESPONSAVEL"]}/>
        <UiInfo label="Código Banco" value={infos["CODIGO_BANCO"]}/>
        <UiInfo label="Código do Contribuinte" value={infos["CODIGO_CONTRIBUINTE"]}/>
        <UiInfo label="Código Corresponsável" value={infos["CODIGO_CORRESPONSAVEL"]}/>
        <UiInfo label="Código Distrito" value={infos["CODIGO_DISTRITO"]}/>
        <UiInfo label="Código do Imóvel" value={infos["CODIGO_IMOVEL"]}/>
        <UiInfo label="Código do Logradouro" value={infos["CODIGO_LOGRADOURO"]}/>
        <UiInfo label="Código Logradouro Corresponsável" value={infos["CODIGO_LOGRADOURO_CORRESPONSAVEL"]}/>
        <UiInfo label="Ficha Mãe" value={infos["FICHA_MAE"]}/>
        <UiInfo label="Fração Ideal M" value={infos["FRACAO_IDEAL_M"]}/>
        <UiInfo label="Fração Ideal" value={infos["FRACAO_IDEAL"]}/>
        <UiInfo label="Fração Ideal P" value={infos["FRACAO_IDEAL_P"]}/>
        <UiInfo label="FRAÇÃO TERRENO" value={infos["FRACAO_TERRENO"]}/>
        <UiInfo label="ID BAIRRO" value={infos["ID_BAIRRO"]}/>
        <UiInfo label="ID BAIRRO CORRESPONDENCIA" value={infos["ID_BAIRRO_CORRESPONDENCIA"]}/>
        <UiInfo label="ID BASE ENGLOBADO" value={infos["ID_BASE_ENGLOBADO"]}/>
        <UiInfo label="ID RUA" value={infos["ID_RUA"]}/>
        <UiInfo label="ID RUA CORRESPONDENCIA" value={infos["ID_RUA_CORRESPONDENCIA"]}/>
        <UiInfo label="ID SETOR" value={infos["ID_SETOR"]}/>
        <UiInfo label="ID TRECHO" value={infos["ID_TRECHO"]}/>
        <UiInfo label="ID ÚNICO" value={infos["ID_UNICO"]}/>
        <UiInfo label="ID ÚNICO RESPONSÁVEL" value={infos["ID_UNICO_RESPONSAVEL"]}/>
        <UiInfo label="ID ZONA" value={infos["ID_ZONA"]}/>
        <UiInfo label="#" value={infos["INFO_ADICIONAL"]}/>
        <UiInfo label="SubLote" value={infos["SUBLOTE"]}/>
    </>
)

export default InfosGenericas
