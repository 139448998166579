import {useFetch} from 'hooks/useFetch';
import {API_URL} from 'utils/api';

export const useDeleteImage = () => {
  const {sendRequest, isLoading: isDeletingImage} = useFetch();

  const deleteImage = async (
    token,
    cityId,
    imageName,
    onSuccess = undefined,
    onError = undefined
  ) => {
    const url = `${API_URL}/drive/file?path=${imageName}&cidade=${cityId}`;
    await sendRequest('DELETE', url, {Authorization: token}, null, onSuccess, onError);
  };

  return {isDeletingImage, deleteImage};
};
