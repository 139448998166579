import {AppContext} from 'contexts/AppContext';
import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {useContext} from 'react';
import {FaDrawPolygon} from 'react-icons/fa';
import {FiFilter} from 'react-icons/fi';
import {IoIosArrowForward} from 'react-icons/io';
import {MdDashboard, MdLayers, MdMap, MdPhoto, MdPrint, MdSearch} from 'react-icons/md';
import {RiPinDistanceLine} from 'react-icons/ri';
import {REDUCER_MODAL_ACTIONS} from 'reducers/modals';
import styled from 'styled-components';
import {REDUCER_MAP_ACTIONS} from '../../../reducers/map';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../reducers/openlayers';

export const SideToolbar = () => {
  const {mapState, mapDispatch} = useContext(MapContext);
  const {modalDispatch, appConfigState} = useContext(AppContext);
  const {openLayersDispatch} = useContext(OpenLayersContext);

  const disableOrtoimagemMde = () =>
    !appConfigState.cidadeConfig?.hasMDE && !appConfigState.cidadeConfig?.hasOrtoimage;
  const toggleLayersModal = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_LAYERS_SECTION});
  const toggleSelectBackgroundModal = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_BACKGROUND_SECTION});
  const toggleSelectOrtoimageModal = () => {
    if (!disableOrtoimagemMde())
      openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_ORTOIMAGEM_SECTION});
  };
  const toggleSearchBox = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_BUSCA_MAPA});
  const toggleFilterBox = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_FILTRO_MAPA});
  const toggleMapaTematico = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_MAPA_TEMATICO});
  const togglePrint = () => modalDispatch({type: REDUCER_MODAL_ACTIONS.OPEN_PRINT_MAP});
  const toggleMeasureTool = () =>
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_MEASURE_SECTION});
  const toggleToolbarType = () =>
    mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_SIDEBAR_TYPE, payload: 'in'});
  const toggleDrawTool = () => {
    if (mapState.selectedLayers.length > 0)
      openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.TOGGLE_DRAW_SECTION});
  };

  if (mapState.sidebarType === 'in') return null;
  return (
    <Container>
      <ItemContainer onClick={toggleLayersModal}>
        <MdLayers size="2em" />
      </ItemContainer>

      <ItemContainer onClick={toggleSelectBackgroundModal}>
        <MdMap size="2em" />
      </ItemContainer>

      <ItemContainer onClick={toggleSelectOrtoimageModal}>
        <MdPhoto size="2em" color={disableOrtoimagemMde() ? 'gray' : 'black'} />
      </ItemContainer>

      <ItemContainer onClick={toggleSearchBox}>
        <MdSearch size="2em" />
      </ItemContainer>

      <ItemContainer onClick={toggleFilterBox}>
        <FiFilter size="1.8em" />
      </ItemContainer>

      <ItemContainer onClick={toggleMapaTematico}>
        <MdDashboard size="2em" />
      </ItemContainer>

      <ItemContainer onClick={togglePrint}>
        <MdPrint size="2em" />
      </ItemContainer>

      <ItemContainer onClick={toggleMeasureTool}>
        <RiPinDistanceLine size="2em" />
      </ItemContainer>

      <ItemContainer onClick={toggleDrawTool} disabled={mapState.selectedLayers.length === 0}>
        <FaDrawPolygon
          size="1.8em"
          color={mapState.selectedLayers.length === 0 ? 'gray' : 'black'}
        />
      </ItemContainer>

      <Spacer />

      <ItemContainer onClick={toggleToolbarType}>
        <IoIosArrowForward size="1.5em" />
      </ItemContainer>
    </Container>
  );
};

const Container: any = styled.div`
  align-items: center;
  border-right: 1px solid gray;
  color: #333;
  display: flex;
  flex-direction: column;
  width: 40px;
`;

const ItemContainer: any = styled.div<any>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;

  &:hover {
    background-color: #ccc;
  }
`;

const Spacer: any = styled.div`
  flex-grow: 1;
`;
