import {useEffect, useState} from 'react';
import styled from 'styled-components';

const UiPaginator = ({totalCount, actualPage, onChangePage}: any) => {
  const [options, setOptions] = useState([1, 2, 3, '...']);

  useEffect(() => {
    if (totalCount <= 9) {
      let a = [];
      for (let i = 0; i < totalCount; i++) {
        a.push(i + 1);
      }
      setOptions(a);
      return;
    }

    if (actualPage + 1 === 1) {
      setOptions([1, 2, 3, 4, 5, '...']);
    } else if (actualPage + 1 === 2) {
      setOptions([1, 2, 3, 4, 5, '...']);
    } else if (actualPage + 1 === 3) {
      setOptions([1, 2, 3, 4, 5, '...']);
    } else if (actualPage + 1 === 4) {
      setOptions([1, 2, 3, 4, 5, '...']);
    } else if (actualPage + 1 === 5) {
      setOptions([1, 2, 3, 4, 5, '...']);
    } else if (actualPage === totalCount - 1) {
      setOptions([
        '...',
        totalCount - 4,
        totalCount - 3,
        totalCount - 2,
        totalCount - 1,
        totalCount,
      ]);
    } else if (actualPage === totalCount - 2) {
      setOptions([
        '...',
        totalCount - 4,
        totalCount - 3,
        totalCount - 2,
        totalCount - 1,
        totalCount,
      ]);
    } else if (actualPage === totalCount - 3) {
      setOptions([
        '...',
        totalCount - 4,
        totalCount - 3,
        totalCount - 2,
        totalCount - 1,
        totalCount,
      ]);
    } else if (actualPage === totalCount - 4) {
      setOptions([
        '...',
        totalCount - 4,
        totalCount - 3,
        totalCount - 2,
        totalCount - 1,
        totalCount,
      ]);
    } else if (actualPage === totalCount - 5) {
      setOptions([
        '...',
        totalCount - 4,
        totalCount - 3,
        totalCount - 2,
        totalCount - 1,
        totalCount,
      ]);
    } else {
      setOptions(['...', actualPage, actualPage + 1, actualPage + 2, '...']);
    }
  }, [actualPage, totalCount]);

  useEffect(() => {
    if (actualPage >= totalCount) {
      let newValue = totalCount - 1;
      if (newValue < 0) {
        newValue = 0;
      }
      onChangePage(newValue);
    }
  }, [actualPage, totalCount, onChangePage]);

  const handleSelectPage = (o: any) => onChangePage(o - 1);

  if (totalCount === 0) return null;

  return (
    <Container>
      <Paginator>
        <Box disabled={actualPage === 0} bold onClick={() => actualPage !== 0 && onChangePage(0)}>
          {'<<'}
        </Box>
        <Box
          disabled={actualPage === 0}
          bold
          onClick={() => actualPage !== 0 && onChangePage(actualPage - 1)}
        >
          {'<'}
        </Box>
        {options.map((o, i) =>
          o === '...' ? (
            <BoxNoClick key={i}>...</BoxNoClick>
          ) : (
            <Box bold key={i} active={actualPage + 1 === o} onClick={() => handleSelectPage(o)}>
              {o}
            </Box>
          )
        )}
        <Box
          disabled={actualPage === totalCount - 1}
          bold
          onClick={() => {
            actualPage !== totalCount - 1 && onChangePage(actualPage + 1);
          }}
        >
          {'>'}
        </Box>
        <Box
          disabled={actualPage === totalCount - 1}
          bold
          onClick={() => {
            actualPage !== totalCount - 1 && onChangePage(totalCount - 1);
          }}
        >
          {'>>'}
        </Box>
      </Paginator>
    </Container>
  );
};

const Container: any = styled.div`
  display: flex;
  margin: auto;
`;

const Paginator: any = styled.div`
  display: flex;
  margin: auto;
`;

const backgroundColor = (props: any) => {
  if (props.active) {
    return '#006BB4';
  } else if (props.disabled) {
    return '#EEE';
  } else {
    return 'white';
  }
};

const color = (props: any) => {
  if (props.active) {
    return 'white';
  } else if (props.disabled) {
    return '#CCC';
  } else {
    return '#006BB4';
  }
};

const Box: any = styled.div<any>`
  align-items: center;
  border-left: 1px solid #006bb4;
  border-top: 1px solid #006bb4;
  border-bottom: 1px solid #006bb4;
  background-color: ${props => backgroundColor(props)};
  color: ${props => color(props)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 28px;
  font-size: 0.9em;
  font-weight: ${props => (props.bold ? 'bold' : 'regular')};
  justify-content: center;
  width: 28px;

  &:last-child {
    border-right: 1px solid #006bb4;
  }

  &:hover {
    background-color: ${props => (props.disabled ? '#EEE' : props.active ? '#006BB4' : '#099cec')};
    color: ${props => (props.disabled ? '#CCC' : props.active ? 'white' : 'white')};
  }
`;

const BoxNoClick: any = styled.div<any>`
  align-items: center;
  border-left: 1px solid #006bb4;
  border-top: 1px solid #006bb4;
  border-bottom: 1px solid #006bb4;
  background-color: ${({active}) => (active ? '#006BB4' : 'transparent')};
  color: ${({active}) => (active ? 'white' : '#006BB4')};
  cursor: default;
  display: flex;
  height: 28px;
  justify-content: center;
  width: 28px;
`;

export default UiPaginator;
