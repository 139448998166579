import {FaSpinner} from 'react-icons/fa';
import styled, {keyframes} from 'styled-components';

const UiSelect = ({
  label,
  value,
  options,
  onChange,
  placeholder,
  bigLabel,
  width,
  selectText,
  multiple,
  disabled,
  loading,
  name,
}: any) => {
  return (
    <Container width={width}>
      {label?.trim() !== '' && <Label bigLabel={bigLabel}>{label}</Label>}
      {loading ? (
        <ContainerLoading>
          <SpinBox>
            <FaSpinner />
          </SpinBox>
        </ContainerLoading>
      ) : (
        <ContainerSelect multiple={multiple} disabled={disabled}>
          <Select
            disabled={disabled}
            multiple={multiple}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
          >
            {selectText ? (
              <option value={''} disabled>
                {selectText}
              </option>
            ) : null}
            {options?.map((e: any) => (
              <option value={e.key} key={e.key}>
                {e.value}
              </option>
            ))}
          </Select>
        </ContainerSelect>
      )}
    </Container>
  );
};

const Container: any = styled.div<any>`
  margin: ${({margin}) => margin || '10px'};
  width: ${({width}) => width || 'calc(100% - 20px)'};
`;

const ContainerSelect: any = styled.div<any>`
  position: relative;
  width: 100%;
  background-image: ${({multiple}) => (multiple ? '' : 'url(/arrow.svg)')};
  background-repeat: no-repeat;
  background-color: white;
  background-position: calc(100% - 10px) 50%;
  background-size: 10px 10px;
  overflow: hidden;
`;

const ContainerLoading: any = styled.div`
  background-color: transparent;
  border: 1px solid #ddd;
  cursor: auto;
  margin: 0;
  outline: none;
  padding: 11.5px;
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;
const Spin: any = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinBox: any = styled.div`
  animation: ${Spin} 2s linear infinite;
  display: flex;
  width: fit-content;
`;

const Select: any = styled.select`
  appearance: none;
  background-color: ${({disabled}) => (disabled ? '#EEE' : 'transparent')};
  border: 1px solid #ddd;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'auto')};
  margin: 0;
  outline: none;
  padding: 9px;
  width: 100%;

  &:focus {
    border-radius: 0;
  }
`;

const Label: any = styled.div<any>`
  font-size: 0.8em;
`;

export default UiSelect;
