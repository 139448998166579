import UiModal from "components/UiModal";
import UiBox from "components/UiPanel";
import React, {useContext, useState} from "react";
import {Transition} from "react-transition-group";
import UiText from "../components/UiText";
import {MdClose} from "react-icons/md";
import UiFlex from "../components/UiFlex";
import UiSelect from "../components/UiSelect";
import {AddLayerContext} from "../pages/MinhaCidadePage/LayersPage/AddLayerContext";
import UiButton from "../components/UiButton";

const duration = 100;

export const ModalAskMergeLayer = ({show, onClose}: any) => {

    const {getLayersSameType, createNewLayer, mergeLayer} = useContext<any>(AddLayerContext)
    const [mergeIn, setMergeIn] = useState('')
    const [keepIDs, setKeepIDs] = useState('older')
    const [loading, setLoading] = useState(false)

    const transformedLayers = getLayersSameType().map((l: any) => ({key: l.id, value: l.nome}))

    const priorizarIDs = [
        {key: 'older', value: 'Os mais antigos'},
        {key: 'newer', value: 'Os mais novos'},
    ]

    const handleMerge = async () => {
        setLoading(true)
        try {
            await mergeLayer(mergeIn, keepIDs)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Transition in={show} timeout={duration}>
            {(state) => (
                <UiModal state={state} duration={duration}>
                    <UiBox width={"90%"} maxWidth={"400px"}>
                        <UiFlex alignItems={"center"} justifyContent={"space-between"} margin={"10px"}
                                width={"calc(100% - 20px)"}>
                            <UiText size="1.2em">Mesclar a camada atual?</UiText>
                            <MdClose cursor="pointer" onClick={onClose} size={"1.3em"}/>
                        </UiFlex>
                        <UiFlex padding={"10px 0 0 0"}>
                            <UiSelect label={"Mesclar com"} options={transformedLayers}
                                      selectText={"Selecione a camada a ser mesclada"} value={mergeIn}
                                      onChange={(e: any) => setMergeIn(e.target.value)}/>
                        </UiFlex>
                        <UiFlex padding={"10px 0 0 0"}>
                            <UiSelect label={"Em caso de IDs duplicados manter"} options={priorizarIDs} selectText={""}
                                      value={keepIDs} onChange={(e: any) => setKeepIDs(e.target.vaue)}/>
                        </UiFlex>
                        <UiFlex justifyContent={"flex-end"}>
                            <UiFlex maxWidth={"fit-content"} padding={'0 0 0 5px'}>
                                <UiButton secondary color={"orange"} onClick={onClose} disabled={mergeIn === '' || loading}>
                                    Cancelar
                                </UiButton>
                            </UiFlex>

                            <UiFlex maxWidth={"fit-content"} padding={'0 0 0 5px'}>
                                <UiButton secondary color={"DarkBlue"} onClick={createNewLayer} disabled={mergeIn === '' || loading}>
                                    Criar nova
                                </UiButton>
                            </UiFlex>

                            <UiFlex maxWidth={"fit-content"} padding={'0 0 0 5px'}>
                                <UiButton primary onClick={handleMerge} disabled={mergeIn === '' || loading}
                                          loading={loading}>
                                    Mesclar
                                </UiButton>
                            </UiFlex>
                        </UiFlex>
                    </UiBox>
                </UiModal>
            )}
        </Transition>
    );
};
