import {OpenLayersContext} from "contexts/OpenLayersContext";
import GeoJSON from "ol/format/GeoJSON";
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import {Stroke, Style} from "ol/style";
import {useContext, useEffect} from "react";

const LimiteCidadeLayer = ({geojson}: any) => {
    const {openLayersState} = useContext(OpenLayersContext);

    useEffect(() => {
        if (geojson?.features) {
            let features: any = [];
            let geoJSON = new GeoJSON();
            geojson.features?.forEach((f: any) => {
                try {
                    let geoFeature = geoJSON.readFeature(f)
                    features.push(geoFeature);
                } catch (e) {
                    console.error(e,f);
                }
            });

            const zoom = sessionStorage.getItem("zoom") || 2;

            let vectorSource = new VectorSource({features});
            let vectorLayer = new VectorLayer({source: vectorSource});

            vectorLayer.set("NO_SELECTABLE", true)
            vectorLayer.setStyle(new Style({stroke: new Stroke({color: "red", width: 2})}));
            openLayersState.map.addLayer(vectorLayer);
            vectorLayer.setZIndex(10);

            const view = openLayersState.map.getView();
            if (zoom < 5) {
                view.fit(vectorSource.getExtent(), {duration: 200, padding: [10, 10, 10, 10]});
            } else {
                const centerLatitude = sessionStorage.getItem("centerLatitude") || 0
                const centerLongitude = sessionStorage.getItem("centerLongitude") || 0
                let center = [+centerLatitude, +centerLongitude,];
                view.setCenter(center);
                view.setZoom(+zoom);
            }

            return () => {
                openLayersState.map.removeLayer(vectorLayer)
            }
        }
    }, [openLayersState.map, geojson]);

    return null;
};

export default LimiteCidadeLayer;
