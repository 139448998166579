import {useContext, useState} from 'react';
import {FaEdit, FaImage, FaKey, FaList, FaMap} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {AppContext} from '../../../../contexts/AppContext';
import {OpenLayersContext} from '../../../../contexts/OpenLayersContext';
import {SessionContext} from '../../../../contexts/SessionContext';
import ModalUpdateInscricao from '../../../../modals/ModalUpdateInscricao';
import {REDUCER_LOADING_ACTIONS} from '../../../../reducers/loading';
import {REDUCER_MODAL_ACTIONS} from '../../../../reducers/modals';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../../reducers/openlayers';

export const UiSectionButtons = ({infos}: any) => {
  const {sessionState} = useContext(SessionContext);
  const {loadingDispatch, modalDispatch, appConfigState} = useContext(AppContext);
  const {openLayersDispatch} = useContext(OpenLayersContext);
  const navigate = useNavigate();
  const [showModalUpdateInscricao, setShowModalUpdateInscricao] = useState(false);

  const isNovaPetropolis = sessionState.cidadeSelecionadaSessao === '5f4e397f4397338c4f8f5f0c';

  const exportMap = async () => {
    loadingDispatch({type: REDUCER_LOADING_ACTIONS.SET_CONSULTA_PREVIA});
    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.SET_EXPORT_MAP});
  };

  const handleDownloadConsultaPrevia = async () => exportMap().then();

  const handleEdit = () => {
    let path = 'edificacoes';
    if (infos.data.tipoCamada === 'lote') {
      path = 'lotes';
    } else if (infos.data.tipoCamada === 'logradouro') {
      path = 'logradouros';
    }

    navigate(`/cadastros/${path}/editar`, {state: infos.data.infos});
  };

  const handleShowImagens = () =>
    modalDispatch({
      type: REDUCER_MODAL_ACTIONS.OPEN_IMAGENS_FAXADA,
      payload: infos.data,
    });

  const handleShowStreetMap = () =>
    modalDispatch({
      type: REDUCER_MODAL_ACTIONS.OPEN_GOOGLE_STREET_VIEW,
      payload: infos.data,
    });

    const getEditStatus = () => {
      if (infos.data.tipoCamada === 'lote') return sessionState.permissoes?.postLotes
      if (infos.data.tipoCamada === 'edificacao') return sessionState.permissoes?.postEdificacoes
      if (infos.data.tipoCamada === 'logradouros') return sessionState.permissoes?.postLogradouros
      return false
    }

  return (
    <Buttons>
      {sessionState.token && getEditStatus() && (
        <FaEdit onClick={handleEdit} cursor="pointer" title="Editar" color="#099cec" size="1.2em" />
      )}
      {(infos.data.tipoCamada === 'lote' || infos.data.tipoCamada === 'edificacao') && (
        <FaImage
          onClick={handleShowImagens}
          cursor="pointer"
          title="Imagens"
          color="#099cec"
          size="1.2em"
        />
      )}
      {(infos.data.tipoCamada === 'lote' || infos.data.tipoCamada === 'edificacao') &&
        sessionState.token && getEditStatus() && (
          <FaKey
            onClick={() => setShowModalUpdateInscricao(true)}
            cursor="pointer"
            title="Alterar inscricao"
            color="#099cec"
            size="1.2em"
          />
        )}
      {infos.data.tipoCamada === 'lote' && !isNovaPetropolis && (
        <FaList
          color="#099cec"
          size="1.2em"
          onClick={handleDownloadConsultaPrevia}
          cursor="pointer"
          title="Consulta prévia"
        />
      )}

      {infos.data.tipoCamada === 'logradouro' && appConfigState.cidadeConfig.chaveGoogle && (
        <FaMap
          color="#099cec"
          size="1.2em"
          onClick={handleShowStreetMap}
          cursor="pointer"
          title="Google Street View"
        />
      )}

      <ModalUpdateInscricao
        show={showModalUpdateInscricao}
        onClose={() => setShowModalUpdateInscricao(false)}
        tipo={infos.data.tipoCamada}
      />
    </Buttons>
  );
};

const Buttons: any = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
`;
