import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {Select} from 'ol/interaction';
import {Fill, Stroke, Style, Text} from 'ol/style';
import {useContext, useEffect} from 'react';
import {REDUCER_MAP_ACTIONS} from '../../../../reducers/map';

const SelectLayer = () => {
  const {mapState, mapDispatch} = useContext(MapContext);
  const {openLayersState} = useContext(OpenLayersContext);

  useEffect(() => {
    if (!openLayersState.deletingGeometry) {
      const condFunc = (e: any) => e.type === 'click';
      const layersFunc = (e: any) => !e.get('NO_SELECTABLE');

      let select = new Select({condition: condFunc, layers: layersFunc});

      select.on('select', function (e: any) {
        if (e.selected?.length) {
          const firstSelected = e.selected[0];
          let texto = firstSelected.get('TEXTO');
          if (texto) {
            select.getFeatures().clear();
            return;
          }

          let s = new Style({
            stroke: new Stroke({
              color: '#ffcc33',
              width: 3,
            }),
            fill: new Fill({
              color: '#ffcc3366',
            }),
            text: new Text({
              font: '12px "Open Sans", "Arial Unicode MS", "sans-serif"',
              placement: 'line',
              offsetY: -8,
              stroke: new Stroke({
                width: 1,
              }),
              fill: new Fill({
                color: 'black',
              }),
            }),
          });

          s.getText().setText(e.selected[0].get('NOME_RUA'));

          if (firstSelected.getGeometry().getType() !== 'Point') {
            firstSelected.setStyle(s);
          }

          const extent = firstSelected.getGeometry().getExtent();
          if (extent.length === 4) {
            const lat = (extent[3] + extent[1]) / 2;
            const lng = (extent[2] + extent[0]) / 2;
            mapDispatch({
              type: REDUCER_MAP_ACTIONS.SET_SELECTED_GEOMETRY_COORDINATES,
              payload: {lat, lng},
            });
          }

          const id = select.getLayer(firstSelected)?.get('ID');
          mapDispatch({
            type: REDUCER_MAP_ACTIONS.SELECT_GEOMETRY,
            payload: {geometry: firstSelected.getId(), layer: id},
          });
        } else {
          mapDispatch({
            type: REDUCER_MAP_ACTIONS.SELECT_GEOMETRY,
            payload: {geometry: '', layer: ''},
          });
        }
      });

      openLayersState.map.addInteraction(select);

      return () => {
        openLayersState.map.removeInteraction(select);
      };
    }
  }, [
    openLayersState.map,
    openLayersState.deletingGeometry,
    mapState.selectedBackground,
    mapDispatch,
  ]);

  return null;
};

export default SelectLayer;
