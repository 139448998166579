import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {Control} from 'ol/control';
import {useContext, useEffect, useRef} from 'react';
import {IoIosArrowBack} from 'react-icons/io';
import styled from 'styled-components';
import {REDUCER_MAP_ACTIONS} from '../../../../reducers/map';

const ButtonChangeSidebarType = () => {
  const {openLayersState} = useContext(OpenLayersContext);
  const {mapState, mapDispatch} = useContext(MapContext);
  const component = useRef();

  useEffect(() => {
    if (openLayersState.map && component.current && mapState.sidebarType === 'in') {
      const control = new Control({element: component.current});
      openLayersState.map.addControl(control);

      return () => {
        openLayersState.map.removeControl(control);
      };
    }
  }, [openLayersState.map, mapState.sidebarType]);

  return (
    <Container
      ref={component}
      onClick={() => {
        mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_SIDEBAR_TYPE, payload: 'out'});
      }}
      type={mapState.sidebarType}
    >
      <IoIosArrowBack
        size="1.8em"
        color={
          mapState.selectedBackground.type === '' ||
          mapState.selectedBackground.type === 'RoadOnDemand'
            ? '#099cec'
            : 'white'
        }
        cursor="pointer"
      />
    </Container>
  );
};

const Container: any = styled.div<any>`
  display: ${({type}) => (type === 'in' ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

export default ButtonChangeSidebarType;
