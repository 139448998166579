import {IFileUploadState, IReducerAction} from "./IReducers";
import {IEnvio} from "../contexts/IContexts";

const isEnviando = (envios: IEnvio[]): boolean => {
    let isEnviando = false
    envios.forEach(e => {
        if (e.total > e.loaded) {
            isEnviando = true;
        }
    })
    return isEnviando
}

export const REDUCER_FILE_UPLOAD_INITIAL: IFileUploadState = {
    isEnviando: false,
    envios: []
}

export const REDUCER_FILE_UPLOAD_ACTIONS = {
    SET_ENVIANDO: 'SET_ENVIANDO',
    CLEAR_ENVIANDO: 'CLEAR_ENVIANDO',

    ADD_TO_ENVIO: 'ADD_TO_ENVIO',
    REMOVE_FROM_ENVIO: 'REMOVE_FROM_ENVIO',
    CLEAR_ENVIOS: 'CLEAR_ENVIOS',

    UPDATE_PROGRESS: 'UPDATE_PROGRESS'
}

const updateProgress = (state: IFileUploadState, payload: any): any => {
    let tmpEnvios: IEnvio[] = [...state.envios];
    tmpEnvios.forEach((envio) => {
        if (envio.id === payload.id) {
            envio.loaded = payload.loaded;
            envio.total = payload.total;
        }
    })
    return {...state, envios: tmpEnvios, isEnviando: isEnviando(tmpEnvios)}
}

export const fileUploadReducer = (state: IFileUploadState, action: IReducerAction) => {
    switch (action.type) {
        case REDUCER_FILE_UPLOAD_ACTIONS.SET_ENVIANDO:
            return {...state, isEnviando: true}
        case REDUCER_FILE_UPLOAD_ACTIONS.CLEAR_ENVIANDO:
            return {...state, isEnviando: false}

        case REDUCER_FILE_UPLOAD_ACTIONS.ADD_TO_ENVIO:
            const newEnvios = [...state.envios, action.payload]
            return {...state, envios: newEnvios, isEnviando: isEnviando(newEnvios)}
        case REDUCER_FILE_UPLOAD_ACTIONS.CLEAR_ENVIOS:
            return {...state, envios: []}
        case REDUCER_FILE_UPLOAD_ACTIONS.REMOVE_FROM_ENVIO:
            return {...state, envios: state.envios.filter(e => e.id !== action.payload)}

        case REDUCER_FILE_UPLOAD_ACTIONS.UPDATE_PROGRESS:
            return updateProgress(state, action.payload)
        default:
            return {...state}
    }
}
