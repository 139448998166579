import {ImSpinner6} from 'react-icons/im';
import styled, {keyframes} from 'styled-components';
import UiFlex from '../UiFlex';
import UiText from '../UiText';

export const UiLoading = () => {
  return (
    <UiFlex justifyContent={'center'} alignItems={'center'} direction={'column'}>
      <Spinner>
        <ImSpinner6 size="20px" color={'gray'} />
      </Spinner>
      <UiText color={'gray'}>Carregando</UiText>
    </UiFlex>
  );
};

const Spin: any = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner: any = styled.div`
  animation: ${Spin} 2s linear infinite;
  height: 20px;
  width: 20px;
  margin-bottom: 4px;
`;
