import UiButton from 'components/UiButton'
import UiFlex from "components/UiFlex";
import UiInputText from 'components/UiInputText'
import React from 'react'
import {useNavigate} from 'react-router-dom'

const EditDocumentosPessoais = ({objeto, onSetObjeto, onSave}: any) => {
    const navigate = useNavigate()

    const handleChangeField = (e: any) => onSetObjeto({...objeto, [e.target.name]: e.target.value})

    return (
        <UiFlex direction="column">
            <UiFlex>
                <UiInputText label="Complemento nome" name={"COMPLEMENTO_NOME"} value={objeto["COMPLEMENTO_NOME"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Contribuinte" name={"CONTRIBUINTE"} value={objeto["CONTRIBUINTE"]}
                             onChange={handleChangeField}/>
                <UiInputText label="CPF/CNPJ" name={"CPF_CNPJ"} value={objeto["CPF_CNPJ"]}
                             onChange={handleChangeField}/>
                <UiInputText label="Corresponsável" name={"CORRESPONSAVEL"} value={objeto["CORRESPONSAVEL"]}
                             onChange={handleChangeField}/>
                <UiInputText label="CPF/CNPJ do corresponsável" name={"CPF_CNPJ_CORRESPONSAVEL"}
                             value={objeto["CPF_CNPJ_CORRESPONSAVEL"]} onChange={handleChangeField}/>
            </UiFlex>
            <UiFlex justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                <UiButton warning margin="0 20px 0 0" onClick={() => navigate(-1)}>
                    Cancelar
                </UiButton>
                <UiButton primary onClick={onSave}>
                    Salvar
                </UiButton>
            </UiFlex>
        </UiFlex>
    )
}

export default EditDocumentosPessoais
