import {AppContext} from "contexts/AppContext";

import {MapContext} from "contexts/MapContext";
import {extend} from "ol/extent";
import {fromLonLat} from "ol/proj";
import {createContext, useContext, useEffect, useReducer} from "react";
import {openlayerReducer, REDUCER_OPENLAYERS_ACTIONS, REDUCER_OPENLAYERS_INITIAL} from "reducers/openlayers";
import {IOpenLayersContext} from "./IContexts";
import {REDUCER_MAP_ACTIONS} from "../reducers/map";
import {getPrintFromMap} from "../utils/openlayers";
import {API} from "../utils/api";
import {REDUCER_LOADING_ACTIONS} from "../reducers/loading";

export const OpenLayersContext = createContext<IOpenLayersContext>({
    openLayersState: REDUCER_OPENLAYERS_INITIAL, openLayersDispatch: () => {
    }
});

const OpenLayersProvider = ({children}: any) => {
    const {loadingDispatch, appConfigState} = useContext(AppContext);
    const {mapState, mapDispatch} = useContext(MapContext);

    const [openLayersState, openLayersDispatch] = useReducer(openlayerReducer, REDUCER_OPENLAYERS_INITIAL)

    useEffect(() => {
        if (openLayersState.isExportingMap) {
            const afterComplete = async () => {
                const canvas = getPrintFromMap(openLayersState.map)
                canvas.then(async e => {
                    try {
                        let resp = await API.gerarConsultaPrevia(appConfigState.idCidadeSelecionada, mapState.selectedGeometry, e.toDataURL());
                        if (resp.data) window.open(`${API.API_URL}/drive/download?path=${(resp.data as any).path}&cidade=${appConfigState.idCidadeSelecionada}`);
                    } catch (e) {
                        window.alert("Houve um erro na requisição");
                    }
                    loadingDispatch({type: REDUCER_LOADING_ACTIONS.CLEAR_CONSULTA_PREVIA});
                    openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.UNSET_EXPORT_MAP})
                })
            };

            afterComplete().then();
        }
    }, [openLayersState.isExportingMap, openLayersState.map, mapState.selectedGeometry, loadingDispatch,
        appConfigState.idCidadeSelecionada]);

    useEffect(() => {
        const centerLatitude = sessionStorage.getItem("centerLatitude")
        const centerLongitude = sessionStorage.getItem("centerLongitude")
        const z = sessionStorage.getItem("zoom")
        const zoom = z ? +z : 2

        let center = [centerLatitude ? +centerLatitude : 0, centerLongitude ? +centerLongitude : 0];

        const view = openLayersState.map.getView();
        if (zoom !== 2) {
            view.setCenter(center);
            view.setZoom(zoom);
        }

        const mapElement: any = document.querySelector("#mapElement");
        openLayersState.map.setTarget(mapElement);

        const updateZoom = () => openLayersDispatch({
            type: REDUCER_OPENLAYERS_ACTIONS.UPDATE_ZOOM,
            payload: openLayersState.map.getView().getZoom(),
        })
        openLayersState.map.on("moveend", updateZoom);

        return () => {
            openLayersState.map.un('moveend', updateZoom)
            openLayersState.map.setTarget(undefined);
        };
    }, [openLayersState.map]);

    useEffect(() => {
        if (openLayersState.map && !openLayersState.loadingLayers.length && mapState.shouldCenter) {
            const isFeatureSelected = (feat: any) =>
                mapState.searchSelected.filter((e: any) => e === feat.getId()).length > 0;

            const geometries: any[] = [];
            openLayersState.map.getLayers().forEach((e: any) => {
                e.getSource().forEachFeature &&
                e.getSource().forEachFeature((feat: any) => {
                    if (isFeatureSelected(feat)) {
                        geometries.push(feat);
                    }
                });
            });

            let first: any;
            if (geometries.length > 0) {
                first = geometries[0].getGeometry().getExtent();
                geometries.forEach((g) => extend(first, g.getGeometry().getExtent()));

                openLayersState.map.getView().fit(first, {
                    duration: 500,
                    maxZoom: 18,
                });
                mapDispatch({type: REDUCER_MAP_ACTIONS.SET_SHOULD_CENTER, payload: false})
            }
        }
    }, [
        openLayersState.map,
        openLayersState.loadingLayers,
        mapState.searchSelected,
        mapState.shouldCenter,
        mapDispatch,
    ]);

    useEffect(() => {
        const savePositionToLocalStorage = () => {
            let center = openLayersState.map.getView().getCenter();
            if (center && center[0] !== 0 && center[1] !== 0) {
                sessionStorage.setItem("zoom", `${openLayersState.map.getView().getZoom()}` || '');
                sessionStorage.setItem("centerLatitude", `${center[0]}`);
                sessionStorage.setItem("centerLongitude", `${center[1]}`);
            }
        };

        if (openLayersState.map) {
            openLayersState.map.on("moveend", savePositionToLocalStorage);
            return () => openLayersState.map.un("moveend", savePositionToLocalStorage);
        }
    }, [openLayersState.map]);

    useEffect(() => {
        openLayersState.map.on("pointermove", (evt: any) => {
            if (evt.dragging) return;

            let coords = openLayersState.map.getEventCoordinate(evt.originalEvent);
            let translated = fromLonLat(coords, "EPSG:31982");
            openLayersDispatch({
                type: REDUCER_OPENLAYERS_ACTIONS.SET_COORDENADAS, payload: {
                    latitude: +translated[0].toFixed(3),
                    longitude: +translated[1].toFixed(3),
                },
            })
        });

        return () => {
            const listeners = openLayersState.map.getListeners('pointermove')
            if (listeners) {
                listeners.forEach((a: any) => openLayersState.map.un("pointermove", a));
            }
        };
    }, [openLayersState.map]);

    return (
        <OpenLayersContext.Provider value={{openLayersState, openLayersDispatch}}>
            {children}
        </OpenLayersContext.Provider>
    );
};

export default OpenLayersProvider;
