import {AppContext} from 'contexts/AppContext';
import {FileUploadContext} from 'contexts/FileUploadContext';
import {SessionContext} from 'contexts/SessionContext';
import {useContext, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import {REDUCER_APP_CONFIG_ACTIONS} from 'reducers/appconfig';
import styled from 'styled-components';
import {menus} from 'utils/topMenus';
import {REDUCER_SESSION_ACTIONS} from '../../reducers/session';
import {API_URL} from '../../utils/api';
import LoginBox from './LoginBox';
import UserBox from './UserBox';

export const UiHeader = () => {
  const {appConfigState, appConfigDispatch, triggerState} = useContext(AppContext);
  const {sessionState, sessionDispatch} = useContext(SessionContext);
  const {fileUploadState} = useContext(FileUploadContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [logging, setLogging] = useState(false);
  const [showingUser, setShowingUser] = useState(false);

  const [errors, setErrors] = useState({global: false, cidade: false});

  const isSelected = (urls: any) => {
    let isSelected = false;
    urls.forEach((e: any) => {
      if (e === location.pathname) {
        isSelected = true;
      }
    });
    return isSelected;
  };

  const handleChangeCidade = () => {
    sessionStorage.removeItem('zoom');
    sessionStorage.removeItem('centerLongitude');
    sessionStorage.removeItem('centerLatitude');
    localStorage.removeItem('token');
    sessionDispatch({type: REDUCER_SESSION_ACTIONS.CLEAR_TOKEN});
    appConfigDispatch({type: REDUCER_APP_CONFIG_ACTIONS.CLEAR_ID_CIDADE_SELECIONADA});
  };

  const handleError = () => {
    if (!errors.cidade) {
      setErrors({global: false, cidade: true});
    } else if (!errors.global) {
      setErrors({global: true, cidade: true});
    }
  };

  return (
    <>
      <Header>
        {appConfigState.idCidadeSelecionada && triggerState.reloadLogo && (
          <LogoImg
            src={`${API_URL}/cidadeconfig/${appConfigState.idCidadeSelecionada}/logo?rerender=${triggerState.reloadLogo}`}
            onError={handleError}
          />
        )}
        <Title>{appConfigState.cidadeConfig?.nome || 'Minha Cidade'}</Title>
        <Menus>
          {menus(sessionState.usuario).map(m => {
            return (
              <Menu key={m.id} onClick={() => navigate(m.urls[0])} selected={isSelected(m.urls)}>
                {m.text}
              </Menu>
            );
          })}
        </Menus>
        <Spacer />
        <UploadFileStatus>
          {fileUploadState.isEnviando ? 'Envio de arquivos em andamento' : ''}
        </UploadFileStatus>
        <TrocarCidade onClick={handleChangeCidade}>Trocar cidade</TrocarCidade>
        <UserSession>
          {sessionState.token && sessionState.usuario ? (
            <UserAvatarContainer onClick={() => setShowingUser(true)}>
              <UserAvatar src={sessionState.usuario['foto'] || '/static/avatar.jpg'} />
            </UserAvatarContainer>
          ) : (
            <Login onClick={() => setLogging(!logging)}> Entrar </Login>
          )}
        </UserSession>
      </Header>

      <Transition in={logging} timeout={200}>
        {state => <LoginBox state={state} onClose={() => setLogging(false)} />}
      </Transition>

      {showingUser && <UserBox onClose={() => setShowingUser(false)} />}
    </>
  );
};

const Header: any = styled.div`
  background-color: #099cec;
  display: flex;
  height: 52px;
  align-items: center;
`;

const LogoImg: any = styled.img`
  border-radius: 15%;
  color: white;
  margin-left: 20px;
  user-select: none;
  height: 40px;
  min-width: 40px;

  @media (max-width: 500px) {
    height: 25px;
    min-width: 25px;
    margin-left: 10px;
  }
`;

const Title: any = styled.div`
  color: white;
  margin-left: 8px;
  font-size: 1.3em;
  font-weight: bold;
  user-select: none;
  display: flex;
  justify-content: center;

  @media (max-width: 500px) {
    font-size: 1em;
  }
`;

const Menus: any = styled.div`
  align-items: center;
  display: flex;
  margin-left: 5px;
  height: 100%;
`;

const Menu: any = styled.span<any>`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  margin: 0 20px;
  border-bottom: ${({selected}) => (selected ? '1px solid white' : 'none')};

  @media (max-width: 500px) {
    margin: 0 10px;
  }
`;

const Spacer: any = styled.div`
  flex-grow: 1;
`;

const UserSession: any = styled.div`
  align-items: center;
  display: flex;
  margin-right: 0;
`;
const UserAvatarContainer: any = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const UserAvatar: any = styled.img`
  border-radius: 50%;
  height: 25px;
  margin-right: 10px;
`;

const TrocarCidade: any = styled.div`
  color: white;
  cursor: pointer;
  font-size: 0.8em;
  margin-right: 30px;

  @media (max-width: 500px) {
    margin-right: 5px;
  }
`;

const Login: any = styled.a`
  color: white;
  cursor: pointer;
  font-size: 0.9em;
  margin-right: 30px;
`;

const UploadFileStatus: any = styled.div`
  color: DarkGreen;
  font-size: 0.75em;
  font-weight: bold;
  margin-right: 20px;
`;
