import {UiInfo} from "components/UiInfo";

const InfosTerreno = ({infos}: any) => (
    <>
        <UiInfo label="Inscrição Imobiliária" value={infos["idGeometria"]}/>
        <UiInfo area label="Área do Lote" value={infos["AREA_LOTE"]}/>
        <UiInfo distancia label="Testada" value={infos["TESTADA"]}/>
        <UiInfo distancia label="Profundidade" value={infos["PROFUNDIDADE"]}/>
        <UiInfo distancia label="Afastamento Frontal" value={infos["AFASTAMENTO_FRONTAL"]}/>
        <UiInfo distancia label="Alinhamento" value={infos["ALINHAMENTO"]}/>
        <UiInfo area label="Área do Lote Vila" value={infos["AREA_LOTE_VILA"]}/>
        <UiInfo label="Distrito" value={infos["DISTRITO"]}/>
        <UiInfo label="Setor" value={infos["SETOR"]}/>
        <UiInfo label="Quadra" value={infos["QUADRA"]}/>
        <UiInfo label="Lote" value={infos["LOTE"]}/>
        <UiInfo label="Número do Imóvel" value={infos["NUMERO_IMOVEL"]}/>
        <UiInfo label="Gleba" value={infos["GLEBA"]}/>
        <UiInfo label="Lado" value={infos["LADO"]}/>
        <UiInfo label="Matrícula" value={infos["MATRICULA"]}/>
        <UiInfo label="Seção" value={infos["SECAO"]}/>
        <UiInfo label="Pedologia" value={infos["PEDOLOGIA"]}/>
        <UiInfo label="Tipo" value={infos["TIPO"]}/>
        <UiInfo label="Topografia" value={infos["TOPOGRAFIA"]}/>
        <UiInfo area label="AREA DO HABITE-SE" value={infos["AREA_HABITESE"]}/>
        <UiInfo area label="Área Projeto" value={infos["AREA_PROJETO"]}/>
        <UiInfo area label="Área Rural" value={infos["AREA_RURAL"]}/>
        <UiInfo label="Fachada" value={infos["FACHADA"]}/>
        <UiInfo distancia label="FRENTE" value={infos["FRENTE"]}/>
        <UiInfo distancia label="FUNDOS" value={infos["FUNDOS"]}/>
        <UiInfo distancia label="LADO DIREITO" value={infos["LADO_DIREITO"]}/>
        <UiInfo distancia label="LADO ESQUERDO" value={infos["LADO_ESQUERDO"]}/>
        <UiInfo label="LATITUDE" value={infos["LATITUDE"]}/>
        <UiInfo label="LONGITUDE" value={infos["LONGITUDE"]}/>
        <UiInfo label="Nome Distrito" value={infos["NOME_DISTRITO"]}/>
        <UiInfo label="Número do Cadastro" value={infos["NUMERO_CADASTRO"]}/>
        <UiInfo label="Número do Lote" value={infos["NUMERO_LOTE"]}/>
        <UiInfo label="Passeio" value={infos["PASSEIO"]}/>
        <UiInfo label="Pavimentação" value={infos["PAVIMENTACAO"]}/>
        <UiInfo label="SITUAÇÃO NA QUADRA" value={infos["SITUACAO_QUADRA"]}/>
        <UiInfo distancia label="TESTADA 1 - EXTENSÃO" value={infos["TESTADA1_EXTENSAO"]}/>
        <UiInfo label="TESTADA 1 - FACE" value={infos["TESTADA1_FACE"]}/>
        <UiInfo label="TESTADA 1 - LOGRADOURO" value={infos["TESTADA1_LOGRADOURO"]}/>
        <UiInfo label="TESTADA 1 - NÚMERO" value={infos["TESTADA1_NUMERO"]}/>
        <UiInfo label="TESTADA 1 - SEÇÃO" value={infos["TESTADA1_SECAO"]}/>
        <UiInfo label="TESTADA 1 - TIPO LOGRADOURO" value={infos["TESTADA1_TIPO_LOGRADOURO"]}/>
        <UiInfo label="TESTADA 2 - EXTENSÃO" value={infos["TESTADA2_EXTENSAO"]}/>
        <UiInfo label="TESTADA 2 - FACE" value={infos["TESTADA2_FACE"]}/>
        <UiInfo label="TESTADA 2 - LOGRADOURO" value={infos["TESTADA2_LOGRADOURO"]}/>
        <UiInfo label="TESTADA 2 - NÚMERO" value={infos["TESTADA2_NUMERO"]}/>
        <UiInfo label="TESTADA 2 - SEÇÃO" value={infos["TESTADA2_SECAO"]}/>
        <UiInfo label="TESTADA 2 - TIPO LOGRADOURO" value={infos["TESTADA2_TIPO_LOGRADOURO"]}/>
        <UiInfo label="TESTADA 3 - EXTENSÃO" value={infos["TESTADA3_EXTENSAO"]}/>
        <UiInfo label="TESTADA 3 - FACE" value={infos["TESTADA3_FACE"]}/>
        <UiInfo label="TESTADA 3 - LOGRADOURO" value={infos["TESTADA3_LOGRADOURO"]}/>
        <UiInfo label="TESTADA 3 - NÚMERO" value={infos["TESTADA3_NUMERO"]}/>
        <UiInfo label="TESTADA 3 - SEÇÃO" value={infos["TESTADA3_SECAO"]}/>
        <UiInfo label="TESTADA 3 - TIPO LOGRADOURO" value={infos["TESTADA3_TIPO_LOGRADOURO"]}/>
        <UiInfo distancia label="Testada Principal " value={infos["TESTADA_PRINCIPAL"]}/>
        <UiInfo label="Tipo do Imóvel" value={infos["TIPO_IMOVEL"]}/>
        <UiInfo label="TIPO LOGRADOURO" value={infos["TIPO_LOGRADOURO"]}/>
        <UiInfo label="Zona" value={infos["ZONA"]}/>
    </>
)

export default InfosTerreno;
