import UiButton from 'components/UiButton';
import {default as UiFlex, default as UiFlexItem} from 'components/UiFlex';
import UiBox from 'components/UiPanel';
import UiText from 'components/UiText';
import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {humanReadableSize} from 'utils/utils';
import {SessionContext} from '../../../contexts/SessionContext';
import {useGet} from '../../../hooks/useGet';
import {useIntervalGet} from '../../../hooks/useIntervalGet';
import ConfigPage from '../index';

const CidadeBox = ({cidade}: any) => {
  const {headers: headersReq} = useContext(SessionContext);
  const navigate = useNavigate();

  const {data} = useIntervalGet(`/cidade/${cidade.id}/cacheSize`, 10000, headersReq);

  return (
    <UiBox margin={'5px auto'}>
      <UiFlex justifyContent={'space-between'} padding={'0 0 20px 0'} alignItems={'center'}>
        <UiText size={'1.4em'} bold>
          {cidade.nome}
        </UiText>
        <UiFlex
          maxWidth={'200px'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          direction={'column'}
        >
          <UiText size={'.8em'}>Tamanho do cache:</UiText>
          <UiText size={'1.3em'}>{humanReadableSize(data?.size) || '-'}</UiText>
        </UiFlex>
      </UiFlex>
      <UiFlex justifyContent={'space-between'}>
        <UiText>
          {cidade.endereco} - {cidade.numero}, {cidade.bairro}/{cidade.estado}
        </UiText>
        <UiText>CEP: {cidade.cep}</UiText>
      </UiFlex>
      <UiFlex justifyContent={'space-between'}>
        <UiText>{cidade.telefone}</UiText>
        <UiText>{cidade.website}</UiText>
      </UiFlex>
      <UiFlex>
        <UiText>ID: {cidade.id}</UiText>
      </UiFlex>
      <UiFlex padding={'10px 0 0 0'}>
        <Link onClick={() => navigate('/configuracoes/cidades/editar', {state: cidade})}>
          Editar
        </Link>
      </UiFlex>
    </UiBox>
  );
};

const TabelaCidades = () => {
  const navigate = useNavigate();
  const {headers: headersReq} = useContext(SessionContext);
  const {data: cidades} = useGet(`/cidades`, headersReq);

  const renderTable = () => {
    if (!cidades) {
      return (
        <UiText size=".9em" italic color="gray">
          Carregando..
        </UiText>
      );
    }

    if (cidades.length === 0) {
      return (
        <UiText size=".9em" italic color="gray">
          Nenhuma cidade cadastrada
        </UiText>
      );
    }

    return (
      <UiFlex
        direction={'column'}
        padding={'10px 0 0 0'}
        overflow={'auto'}
        maxHeight={'calc(100vh - 170px)'}
      >
        {cidades.map((cidade: any) => (
          <CidadeBox cidade={cidade} />
        ))}
      </UiFlex>
    );
  };

  return (
    <ConfigPage>
      <UiBox>
        <UiFlexItem alignItems="center" justifyContent={'space-between'}>
          <UiText bold>Cidades</UiText>
          <UiButton
            small
            primary
            onClick={() => {
              navigate('/configuracoes/cidades/adicionar');
            }}
          >
            Adicionar cidade
          </UiButton>
        </UiFlexItem>
        <UiFlexItem justifyContent="center">{renderTable()}</UiFlexItem>
      </UiBox>
    </ConfigPage>
  );
};

const Link: any = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: blue;
  padding: 0;
`;

export default TabelaCidades;
