import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import MapboxVector from 'ol/layer/MapboxVector';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import {useContext, useEffect} from 'react';
import {AppContext} from 'contexts/AppContext';

const BackgroundLayer = () => {
  const {mapState} = useContext(MapContext);
  const {openLayersState} = useContext(OpenLayersContext);
  const {appConfigState} = useContext(AppContext);

  useEffect(() => {
    if (openLayersState.map) {
      let layer: any;

      if (mapState.selectedBackground.map === 'bing') {
        localStorage.setItem('LAST_BG_MAP', 'bing');
        localStorage.setItem('LAST_BG_TYPE', mapState.selectedBackground.type);

        layer = new TileLayer({
          source: new BingMaps({
            key: appConfigState.cidadeConfig?.chaveBing,
            imagerySet: mapState.selectedBackground.type,
            maxZoom: 19,
          }),
        });
      } else if (mapState.selectedBackground.map === 'osm') {
        localStorage.setItem('LAST_BG_MAP', 'osm');
        localStorage.setItem('LAST_BG_TYPE', '');

        let osm = new OSM();
        layer = new TileLayer({source: osm});
      } else if (mapState.selectedBackground.map === 'mapbox') {
        localStorage.setItem('LAST_BG_MAP', 'mapbox');
        localStorage.setItem('LAST_BG_TYPE', mapState.selectedBackground.type);

        layer = new MapboxVector({
          styleUrl: mapState.selectedBackground.type,
          accessToken:
            'pk.eyJ1IjoicmFpYW5mcyIsImEiOiJja20yMmwyemMwMHozMm5xa2wzNHdtMzBoIn0.cZSpMZb7cyECKv14ghmMFg',
        });
      }

      layer.setZIndex(0);
      openLayersState.map.addLayer(layer);
      return () => {
        openLayersState.map.removeLayer(layer);
      };
    }
  }, [
    openLayersState.map,
    mapState.selectedBackground,
    appConfigState.cidadeSelecionada,
    appConfigState.cidadeConfig?.chaveBing,
  ]);

  return null;
};

export default BackgroundLayer;
