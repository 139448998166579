import UiTabs from 'components/UiTabs'
import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {API} from 'utils/api'
import EditDocumentosPessoais from '../EditComponents/EditDocumentosPessoais'
import EditEndereco from '../EditComponents/EditEndereco'
import EditInformacoesComplementares from '../EditComponents/EditInformacoesComplementares'
import EditInformacoesEdificacoes from '../EditComponents/EditInformacoesEdificacoes'
import EditInformacoesGenericas from '../EditComponents/EditInformacoesGenericas'
import EditInformacoesPublicas from '../EditComponents/EditInformacoesPublicas'
import EditInformacoesTerreno from '../EditComponents/EditInformacoesTerreno'
import EditValores from '../EditComponents/EditValores'
import {SessionContext} from "../../../contexts/SessionContext";
import {AppContext} from "../../../contexts/AppContext";
import RegisterPage from "../index";

const EditLote = () => {
    const {headers: headersReq} = useContext(SessionContext)
    const {appConfigState} = useContext(AppContext)

    const [selectedTab, setSelectedTab] = useState(1)
    const [lote, setLote] = useState<any>({})

    const navigate = useNavigate()
    const location: any = useLocation()

    useEffect(() => {
        if (location.state) {
            setLote(location.state)
        }
    }, [location])

    const tabs = [
        {id: 1, name: 'Documentos pessoais'},
        {id: 2, name: 'Endereço'},
        {id: 3, name: 'Informações da edificação'},
        {id: 4, name: 'Informações do terreno'},
        {id: 5, name: 'Valores'},
        {id: 6, name: 'Informações publicas'},
        {id: 7, name: 'Informações complementares'},
        {id: 8, name: 'Informações genérica'},
    ]

    const handleSaveLote = async () => {
        await API.saveLote(lote["idGeometria"], lote, appConfigState.idCidadeSelecionada, headersReq)
        navigate(-1)
    }

    const renderTabs = () => {
        switch (selectedTab) {
            case 1:
                return <EditDocumentosPessoais objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 2:
                return <EditEndereco objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 3:
                return <EditInformacoesEdificacoes objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 4:
                return <EditInformacoesTerreno objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 5:
                return <EditValores objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 6:
                return <EditInformacoesPublicas objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 7:
                return <EditInformacoesComplementares objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            case 8:
                return <EditInformacoesGenericas objeto={lote} onSetObjeto={setLote} onSave={handleSaveLote}/>
            default:
                return null
        }
    }

    return (
        <RegisterPage>
            <UiTabs tabs={tabs} selectedTab={selectedTab} onSelectTab={(id: any) => setSelectedTab(id)}>
                {renderTabs()}
            </UiTabs>
        </RegisterPage>
    )
}

export default EditLote
