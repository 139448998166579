import {DateTime} from "luxon";

export const humanReadableSize = (size: any) => {
    if (!size) return ''
    let sizeFormatted = `${size}b`;
    if (size / 1024 > 1) sizeFormatted = `${(size / 1024).toFixed(1)}Kb`
    if (size / 1024 / 1024 > 1) sizeFormatted = `${(size / 1024 / 1024).toFixed(1)}Mb`
    if (size / 1024 / 1024 / 1024 > 1) sizeFormatted = `${(size / 1024 / 1024 / 1024).toFixed(1)}Gb`
    if (size / 1024 / 1024 / 1024 / 1024 > 1) sizeFormatted = `${(size / 1024 / 1024 / 1024 / 1024).toFixed(1)}Tb`
    return sizeFormatted
}

const normalizeMoney = (e: string) => e && +(e.replace('.', '').replace(',', ''))

export const renderArea = (e: any) => !e ? '-' : `${(+e).toFixed(2)} m²`
export const renderDate = (date: string) => {
    const d = DateTime.fromISO(date)
    if (!d.isValid) return ''
    if (d.year === 0) return '-'
    return d.toFormat("dd/MM/yyyy HH:mm")
}

export const renderMoney = (e: string) => {
    const normalized = normalizeMoney(e)
    return normalized ? Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(normalized / 100) : e
}

export const renderCPF = (data: string) => {
    return `${data}`
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1-$2")
        .replace(/(-\d{2})(\d)$/, "$1");
}


export const renderCNPJ = (data: string) => {
    return `${data}`
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(\d{4})-(\d{2})(\d)$/, "$1-$2");
}

export const renderDocumento = (data: string) => {
    let clean = `${data}`.replace(/\D/g, "");
    if (clean.length > 11) {
        return renderCNPJ(`${data}`);
    } else {
        return renderCPF(`${data}`);
    }
}

export const renderCEP = (e: any) => {
    if (!e || e === "0") return "-"
    if (e.length === 8) return `${e.substring(0, 5)}-${e.substring(5, 8)}`
    return e;
};
