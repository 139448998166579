import {IMapState, IReducerAction} from './IReducers';

const lastBGMap = localStorage.getItem('LAST_BG_MAP');
const lastBGType = localStorage.getItem('LAST_BG_TYPE');

export const REDUCER_MAP_INITIAL: IMapState = {
  selectedInfoSession: 'DOCUMENTOS_PESSOAIS',
  selectedGeometry: '',
  selectedGeometryCoords: {lat: 0, lng: 0},
  selectedLayerForGeometry: '',
  searchSelected: [],
  filteredGeometries: [],
  selectedThematicMap: '',
  selectedLayers: [],
  shouldCenter: false,
  layers: [],
  sidebarType: localStorage.getItem('sidebarType') || 'out',
  selectedBackground: {map: lastBGMap || 'osm', type: lastBGType || ''},
  selectedOrtoimagem: {map: 'ortoimagem', type: ''},
  thematicMaps: [],
};

export const REDUCER_MAP_ACTIONS = {
  SELECT_INFO_SESSION: 'SELECT_INFO_SESSION',
  SET_LAYERS: 'SET_LAYERS',
  SELECT_LAYER: 'SELECT_LAYER',
  SELECT_LAYERS: 'SELECT_LAYERS',
  SELECT_BACKGROUND: 'SELECT_BACKGROUND',
  SELECT_SEARCH: 'SELECT_SEARCH',
  SET_SHOULD_CENTER: 'SET_SHOULD_CENTER',
  SELECT_SEARCH_AND_CENTER: 'SELECT_SEARCH_AND_CENTER',
  UNSET_SHOULD_CENTER: 'UNSET_SHOULD_CENTER',
  CLEAR_FILTRO: 'CLEAR_FILTRO',
  SELECT_ORTOIMAGEM: 'SELECT_ORTOIMAGEM',
  SELECT_SIDEBAR_TYPE: 'SELECT_SIDEBAR_TYPE',
  SET_FILTERED_GEOMETRIES: 'SET_FILTERED_GEOMETRIES',
  CLEAR_THEMATIC_MAP: 'CLEAR_THEMATIC_MAP',
  SELECT_THEMATIC_MAP: 'SELECT_THEMATIC_MAP',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  SELECT_GEOMETRY: 'SELECT_GEOMETRY',
  SET_SELECTED_GEOMETRY_COORDINATES: 'SET_SELECTED_GEOMETRY_COORDINATES',
};

const selectLayer = (state: IMapState, layerToSelect: any): any => {
  const isSelected = state.selectedLayers.some(layer => layer.id === layerToSelect.id);
  if (isSelected) {
    return {
      ...state,
      selectedLayers: state.selectedLayers.filter(layer => layer.id !== layerToSelect.id),
      shouldCenter: false,
    };
  }

  return {...state, selectedLayers: [...state.selectedLayers, layerToSelect]};
};

export const mapReducer = (state: IMapState, action: IReducerAction) => {
  switch (action.type) {
    case REDUCER_MAP_ACTIONS.SELECT_INFO_SESSION:
      return {...state, selectedInfoSession: action.payload};
    case REDUCER_MAP_ACTIONS.SET_LAYERS:
      return {...state, layers: action.payload, shouldCenter: true};
    case REDUCER_MAP_ACTIONS.SELECT_LAYER:
      return selectLayer(state, action.payload);
    case REDUCER_MAP_ACTIONS.SELECT_LAYERS:
      return {...state, selectedLayers: action.payload};
    case REDUCER_MAP_ACTIONS.SELECT_BACKGROUND:
      const {payload} = action;
      localStorage.setItem('LAST_BG_MAP', payload.map);
      localStorage.setItem('LAST_BG_TYPE', payload.type);
      return {...state, selectedBackground: payload};
    case REDUCER_MAP_ACTIONS.SET_SHOULD_CENTER:
      return {...state, shouldCenter: true};
    case REDUCER_MAP_ACTIONS.UNSET_SHOULD_CENTER:
      return {...state, shouldCenter: false};
    case REDUCER_MAP_ACTIONS.SET_FILTERED_GEOMETRIES:
      return {...state, filteredGeometries: action.payload};
    case REDUCER_MAP_ACTIONS.CLEAR_FILTRO:
      return {...state, filteredGeometries: []};
    case REDUCER_MAP_ACTIONS.SELECT_ORTOIMAGEM:
      return {...state, selectedOrtoimagem: action.payload};
    case REDUCER_MAP_ACTIONS.SELECT_SIDEBAR_TYPE:
      return {...state, sidebarType: action.payload};

    case REDUCER_MAP_ACTIONS.CLEAR_THEMATIC_MAP:
      return {...state, thematicMaps: []};
    case REDUCER_MAP_ACTIONS.SELECT_THEMATIC_MAP:
      return {...state, thematicMaps: action.payload};

    case REDUCER_MAP_ACTIONS.SELECT_SEARCH:
      return {...state, searchSelected: action.payload};
    case REDUCER_MAP_ACTIONS.SELECT_SEARCH_AND_CENTER:
      return {...state, searchSelected: action.payload, shouldCenter: true};
    case REDUCER_MAP_ACTIONS.CLEAR_SEARCH:
      return {...state, searchSelected: []};

    case REDUCER_MAP_ACTIONS.SET_SELECTED_GEOMETRY_COORDINATES:
      return {...state, selectedGeometryCoords: action.payload};

    case REDUCER_MAP_ACTIONS.SELECT_GEOMETRY:
      return {
        ...state,
        selectedGeometry: action.payload.geometry,
        selectedLayerForGeometry: action.payload.layer,
      };
    default:
      return {...state};
  }
};
