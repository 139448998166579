import {MapContext} from 'contexts/MapContext';
import {OpenLayersContext} from 'contexts/OpenLayersContext';
import {useContext, useRef} from 'react';
import {Transition} from 'react-transition-group';
import styled from 'styled-components';
import useOutsideClick from 'utils/useOutsideClick';
import {REDUCER_MAP_ACTIONS} from '../../../../reducers/map';
import {REDUCER_OPENLAYERS_ACTIONS} from '../../../../reducers/openlayers';

const duration = 200;

const BGLayersToolbarModal = () => {
  const {mapState, mapDispatch} = useContext(MapContext);
  const {openLayersState, openLayersDispatch} = useContext(OpenLayersContext);

  const component = useRef();

  useOutsideClick(
    component,
    () => openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_BACKGROUND_SECTION}),
    openLayersState.showBackgroundSection
  );

  const backgrounds = [
    {nome: 'OpenStreet Map', map: 'osm', type: ''},
    {nome: 'Bing - Aéreo', map: 'bing', type: 'AerialWithLabelsOnDemand'},
    {nome: 'Bing - Aéreo (Sem etiquetas)', map: 'bing', type: 'Aerial'},
    {nome: 'Bing - Estrada', map: 'bing', type: 'RoadOnDemand'},
  ];

  return (
    <Transition in={openLayersState.showBackgroundSection} timeout={duration}>
      {state => (
        <Container ref={component} type={mapState.sidebarType} state={state}>
          {backgrounds.map(e => {
            return (
              <BackgroundLayer
                key={e.nome}
                onClick={() => {
                  mapDispatch({type: REDUCER_MAP_ACTIONS.SELECT_BACKGROUND, payload: e});
                  openLayersDispatch({type: REDUCER_OPENLAYERS_ACTIONS.CLOSE_BACKGROUND_SECTION});
                }}
              >
                {e.nome}
              </BackgroundLayer>
            );
          })}
        </Container>
      )}
    </Transition>
  );
};

const Container: any = styled.div<any>`
  position: absolute;
  left: ${({type}) => (type === 'out' ? '43px' : '68px')};
  top: ${({type}) => (type === 'out' ? '93px' : '103px')};
  z-index: 1000000;

  flex-direction: column;
  background-color: #ffffffcc;
  backdrop-filter: blur(5px);
  border: 1px solid silver;
  box-shadow: 0 0 2px silver;

  transform: ${({state}) => (state === 'entered' ? 'scaleX(1)' : 'scaleX(0)')};
  opacity: ${({state}) => (state === 'entered' ? '1' : '0')};
  transition: transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out;
  transform-origin: left;
`;

const BackgroundLayer: any = styled.div`
  display: flex;
  padding: 10px;
  width: 250px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

export default BGLayersToolbarModal;
