import UiButton from 'components/UiButton';
import UiFlexItem from 'components/UiFlex';
import UiBox from 'components/UiPanel';
import UiTable from 'components/UiTable';
import UiText from 'components/UiText';
import {useContext} from 'react';
import {MdEdit} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import {COLOR} from 'utils/colors';
import {AppContext} from '../../../contexts/AppContext';
import {SessionContext} from '../../../contexts/SessionContext';
import {useGet} from '../../../hooks/useGet';
import {renderDate} from '../../../utils/utils';
import ConfigPage from '../index';

export const SuperUsuariosPage = () => {
  const {appConfigState} = useContext(AppContext);
  const {headers: headersReq} = useContext(SessionContext);
  const navigate = useNavigate();

  const {data: usuarios} = useGet(
    `/superusuarios?cidade=${appConfigState.idCidadeSelecionada}`,
    headersReq
  );

  const columnEditTable = (e: any) => {
    return (
      <div>
        <MdEdit
          color={COLOR.WARNING}
          size="1.3em"
          onClick={() => {
            navigate('/configuracoes/superusuarios/editar', {state: e});
          }}
        />
      </div>
    );
  };

  const renderNome = (_: string, row: any) => `${row.primeiroNome} ${row.ultimoNome}`;
  const headers = [
    {title: 'Usuário', field: 'usuario'},
    {title: 'Nome', field: 'primeiroNome', applyRender: renderNome},
    {title: 'Ultimo acesso', field: 'ultimoAcesso', applyRender: renderDate},
    {title: '', actions: columnEditTable, width: '25px', align: 'center'},
  ];

  const renderTable = () => {
    if (!usuarios) {
      return (
        <UiText size=".9em" italic color="gray">
          Carregando..
        </UiText>
      );
    }

    if (usuarios.length === 0) {
      return (
        <UiText size=".9em" italic color="gray">
          Nenhum super usuário cadastrado
        </UiText>
      );
    }

    return <UiTable rows={usuarios} headers={headers} />;
  };

  return (
    <ConfigPage>
      <UiBox maxHeight="calc(100% - 177px)">
        <UiFlexItem alignItems="center" justifyContent={'space-between'}>
          <UiText bold>Super usuários</UiText>
          <UiButton
            small
            primary
            onClick={() => {
              navigate('/configuracoes/superusuarios/adicionar');
            }}
          >
            Adicionar super usuário
          </UiButton>
        </UiFlexItem>
        <UiFlexItem justifyContent="center">{renderTable()}</UiFlexItem>
      </UiBox>
    </ConfigPage>
  );
};
