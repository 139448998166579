import UiFlex from 'components/UiFlex';
import UiModalYesNo from 'components/UiModalYesNo';
import {UiNotifications} from 'components/UiNotifications';
import UiBox from 'components/UiPanel';
import UiText from 'components/UiText';
import {FileUploadContext} from 'contexts/FileUploadContext';
import {SessionContext} from 'contexts/SessionContext';
import {useContext, useEffect, useRef, useState} from 'react';
import {BiLayerPlus} from 'react-icons/bi';
import {FaDatabase, FaMap} from 'react-icons/fa';
import {IoImageOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {API} from 'utils/api';
import {AppContext} from '../../../contexts/AppContext';
import {useFile} from '../../../hooks/useFile';
import RegisterPage from '../index';
import TabelaCamadas from './TabelaCamadas';

const LayersPage = () => {
  const {appConfigState} = useContext(AppContext);
  const {addNotification} = useContext(UiNotifications);
  const {headers: headersReq} = useContext(SessionContext);

  const [isDeleting, setDeleting] = useState<string>('');
  const [triggerUpdate, update] = useState(1);

  const [pickingCSVFile, setPickingCSVFile] = useState(false);
  const [pickingOrtoFile, setPickingOrtoFile] = useState(false);

  const csvFile = useFile(pickingCSVFile, '.csv', false);
  const ortoFile = useFile(pickingOrtoFile, '.ecw', false);

  useEffect(() => {
    const uploadOrtoFile = async () => {
      if (ortoFile?.length) {
        try {
          const fd = new FormData();
          fd.append('file', ortoFile[0]);
          addNotification({
            id: Math.random(),
            type: 'success',
            timeout: 10000,
            text: 'Envio do arquivo em andamento',
            subtext: 'Iniciando o envio do arquivo',
          });

          let resp: any = await API.uploadTmpFile(
            fd,
            '',
            appConfigState.idCidadeSelecionada,
            headersReq
          );

          addNotification({
            id: Math.random(),
            type: 'success',
            timeout: 10000,
            text: 'Arquivo enviado com sucesso',
            subtext: 'Iniciando o processamento do arquivo',
          });
          setPickingOrtoFile(false);

          addNotification({
            id: Math.random(),
            type: 'success',
            timeout: 10000,
            text: 'Iniciando processamento do arquivo',
            subtext: 'O arquivo está sendo processado em background',
          });
          await API.startReprojectionOrtoimagem(
            appConfigState.idCidadeSelecionada,
            resp.data.id,
            ortoFile[0].name,
            headersReq
          );
        } catch (e) {
          console.error(e);
        }
      }
    };

    uploadOrtoFile().then();
  }, [ortoFile, appConfigState.idCidadeSelecionada, addNotification, headersReq]);

  useEffect(() => {
    const uploadCSVFile = async () => {
      if (csvFile?.length) {
        try {
          const fd = new FormData();
          fd.append('file', csvFile[0]);
          let resp: any = await API.uploadTmpFile(
            fd,
            '',
            appConfigState.idCidadeSelecionada,
            headersReq
          );
          addNotification({
            id: Math.random(),
            type: 'success',
            timeout: 10000,
            text: 'Arquivo enviado com sucesso',
            subtext: 'Iniciando o processamento do arquivo',
          });
          setPickingCSVFile(false);
          await API.processCSVFile(
            appConfigState.idCidadeSelecionada,
            resp.data.id,
            csvFile[0].name,
            headersReq
          );
          addNotification({
            id: Math.random(),
            type: 'success',
            timeout: 10000,
            text: 'Arquivo processado com sucesso',
            subtext: 'O arquivo foi processado com sucesso',
          });
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (pickingCSVFile) {
      uploadCSVFile().then();
    }
  }, [csvFile, appConfigState.idCidadeSelecionada, addNotification, headersReq, pickingCSVFile]);

  const {sessionState} = useContext(SessionContext);
  const {uploadFile} = useContext(FileUploadContext);

  const navigate = useNavigate();

  const mdeRef = useRef<any>();

  const handleChooseMdefile = () => {
    if (mdeRef.current?.files?.length) {
      uploadFile(mdeRef.current.files[0], async (id: any) => {
        addNotification({
          id: Math.random(),
          type: 'success',
          text: 'Arquivo enviado com sucesso',
          subtext: 'Iniciando a reprojeção do arquivo',
          timeout: 10000,
        });
        await API.startReprojectionMde(id, appConfigState.idCidadeSelecionada, headersReq);
      });
    }
  };

  const handleDeleteCamada = async () => {
    try {
      await API.deleteLayer(appConfigState.idCidadeSelecionada, isDeleting, headersReq);
      setDeleting('');
      update(Math.random());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <RegisterPage>
      <UiBox overflow="auto" maxHeight="calc(100% - 117px)">
        <UiFlex
          alignItems="center"
          justifyContent={'space-between'}
          margin={'5px 10px'}
          width={'calc(100% - 20px)'}
        >
          <UiText bold size={'1.2em'}>
            Camadas
          </UiText>
          <UiFlex
            justifyContent="flex-end"
            hidden={!sessionState.usuario?.permissoes?.postEdificacoes}
          >
            <UiFlex maxWidth={'100px'} justifyContent="space-between">
              <FaMap
                onClick={() => setPickingOrtoFile(true)}
                title={'Importar ortoimagem'}
                size={'20px'}
                color={'#099cec'}
                cursor={'pointer'}
              />
              <IoImageOutline
                onClick={() => mdeRef.current?.click()}
                title={'Importar MDE'}
                size={'20px'}
                color={'#099cec'}
                cursor={'pointer'}
              />
              <FaDatabase
                onClick={() => setPickingCSVFile(true)}
                title={'Importar base de dados'}
                size={'20px'}
                color={'#099cec'}
                cursor={'pointer'}
              />
              <BiLayerPlus
                onClick={() => navigate('/cadastros/camadas/adicionar')}
                title={'Adicionar camada'}
                size={'20px'}
                color={'#099cec'}
                cursor={'pointer'}
              />
            </UiFlex>
          </UiFlex>
        </UiFlex>

        <TabelaCamadas onDelete={setDeleting} triggerUpdate={triggerUpdate} />

        <UiModalYesNo
          title="Excluir camada"
          body="Excluir essa camada excluirá também os dados vinculados a ela. Deseja continuar?"
          show={isDeleting}
          onNo={() => setDeleting('')}
          onYes={handleDeleteCamada}
        />

        <input
          type="file"
          style={{display: 'none'}}
          ref={mdeRef}
          onChange={handleChooseMdefile}
          accept=".tif"
        />
      </UiBox>
    </RegisterPage>
  );
};

export default LayersPage;
