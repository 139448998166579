import styled from 'styled-components';

const UiColorPicker = ({label, value, onChange, disabled, name}: any) => {
  return (
    <MainContainer onClick={(ev: any) => ev.stopPropagation()}>
      {label && <Label>{label}</Label>}
      <Input value={value} onChange={onChange} disabled={disabled} name={name} />
    </MainContainer>
  );
};

const MainContainer: any = styled.div`
  color: #222;
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: calc(100% - 20px);
`;

const Label: any = styled.label`
  font-size: 0.8em;
`;

const Input: any = styled.input.attrs({type: 'color'})`
  border: 1px solid #ddd;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 8px;
  padding: 8px;
  width: 100%;

  &:focus {
    border: 1px solid #099cec;
  }
`;

export default UiColorPicker;
