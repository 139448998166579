import {IMapaTematicoState, IReducerAction} from "./IReducers";

export const REDUCER_MAPA_TEMATICO_INITIAL: IMapaTematicoState = {
    legenda: {},
    tipoCamada: '',
    campo: '',
    formaGeracaoDados: '',
    show: false
}

export const REDUCER_MAPA_TEMATICO_ACTIONS = {
    SET_ITEMS_LEGENDA: 'SET_ITEMS_LEGENDA',
    CLEAR_ITEMS_LEGENDA: 'CLEAR_ITEMS_LEGENDA',

    SET_PARAM_MAPA_TEMATICO: 'SET_PARAM_MAPA_TEMATICO',
    CLEAR_PARAM_MAPA_TEMATICO: 'CLEAR_PARAM_MAPA_TEMATICO',
    DISABLE_MAPA_TEMATICO: 'DISABLE_MAPA_TEMATICO',
    CLEAR_PARAMS: 'CLEAR_PARAMS'
}

export const mapaTematicoReducer = (state: IMapaTematicoState, action: IReducerAction) => {
    switch (action.type) {
        case REDUCER_MAPA_TEMATICO_ACTIONS.SET_ITEMS_LEGENDA:
            return {...state, legenda: action.payload}
        case REDUCER_MAPA_TEMATICO_ACTIONS.CLEAR_ITEMS_LEGENDA:
            return {...state, legenda: {}}
        case REDUCER_MAPA_TEMATICO_ACTIONS.SET_PARAM_MAPA_TEMATICO:
            return {...state, ...action.payload}
        case REDUCER_MAPA_TEMATICO_ACTIONS.CLEAR_PARAM_MAPA_TEMATICO:
            return {...state, tipoCamada: '', campo: '', formaGeracaoDados: '', show: true}
        case REDUCER_MAPA_TEMATICO_ACTIONS.DISABLE_MAPA_TEMATICO:
            return {...state, tipoCamada: '', campo: '', formaGeracaoDados: '', legenda: {}, show: false}
        case REDUCER_MAPA_TEMATICO_ACTIONS.CLEAR_PARAMS:
            return {...state, tipoCamada: '', campo: '', formaGeracaoDados: '', show: true, legenda: {}}
        default:
            return {...state}
    }
}
