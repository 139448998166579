import UiButton from "components/UiButton";
import UiCheckbox from "components/UiCheckbox";
import UiFlex from "components/UiFlex";
import UiFlexItem from "components/UiFlex";
import UiInputPassword from "components/UiInputPassword";
import UiInputText from "components/UiInputText";
import UiBox from "components/UiPanel";
import UiPanel from "components/UiPanel";
import UiText from "components/UiText";
import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {API} from "utils/api";
import {AppContext} from "../../../contexts/AppContext";
import {SessionContext} from "../../../contexts/SessionContext";

const fieldsPermissao: any = {
    postCamadas: "Editar camadas",
    getEdificacoes: "Visualizar edificações",
    postEdificacoes: "Editar edificações",
    getLogradouros: "Visualizar logradouros",
    postLogradouros: "Editar logradouros",
    getLotes: "Visualizar lotes",
    postLotes: "Editar lotes",
    getUsuarios: "Visualizar usuários",
    postUsuarios: "Editar usuários",
    postFiles: "Editar arquivos",
    configureLogo: "Configurar logo",
    clearOrtoCache: "Limpar cache de ortoimagens",
    saveMapDraw: "Salvar desenhos no mapa",
};

const AddUsuarioPage = ({superAdmin}: any) => {
    const navigate = useNavigate();
    const location: any = useLocation();
    const {appConfigState} = useContext(AppContext)
    const {headers: headersReq} = useContext(SessionContext)

    const [editing, setEditing] = useState("");

    const [form, setForm] = useState({
        primeiroNome: "",
        ultimoNome: "",
        usuario: "",
        senha: "",
        confirmarSenha: "",
    });

    const [permissoes, setPermissoes] = useState<any>({
        postCamadas: false,
        getEdificacoes: false,
        postEdificacoes: false,
        getLogradouros: false,
        postLogradouros: false,
        getLotes: false,
        postLotes: false,
        getUsuarios: false,
        postUsuarios: false,
        getFiles: false,
        postFiles: false,
        configureLogo: false,
        clearOrtoCache: false,
        saveMapDraw: false,
    });

    const isFormInvalid = () => {
        if (!editing) {
            return (
                form.primeiroNome.trim() === "" ||
                form.usuario.trim() === "" ||
                form.senha.trim() === "" ||
                form.senha.trim() !== form.confirmarSenha.trim()
            );
        }

        return (
            form.primeiroNome.trim() === "" ||
            form.usuario.trim() === "" ||
            form.senha?.trim() !== form.confirmarSenha?.trim()
        );
    };

    const handleAddUsuarios = async () => {
        if (editing) {
            await API.putUsuario(appConfigState.idCidadeSelecionada, editing, {
                ...form,
                permissoes,
            }, superAdmin ? 'superAdmin' : '', headersReq);
        } else {
            await API.postUsuario(appConfigState.idCidadeSelecionada, {
                ...form,
                permissoes,
            }, superAdmin ? "superAdmin" : '', headersReq);
        }
        navigate(-1)
    };

    useEffect(() => {
        const {state} = location;
        if (state) {
            setEditing(state.id);
            setPermissoes(state.permissoes);
            delete state.permissoes;
            setForm(state);
        }
    }, [location]);

    return (
        <UiFlex>
            <UiFlexItem direction="column" md={6}>
                <UiBox>
                    <UiFlexItem direction="column">
                        <UiText bold>{editing ? "Editar" : "Adicionar"}
                            {superAdmin ? 'uuper usuário' : 'usuário'}</UiText>
                        <UiFlexItem>
                            <UiInputText label="Nome" value={form.primeiroNome} onChange={(e: any) => {
                                setForm({...form, primeiroNome: e.target.value});
                            }}/>
                            <UiInputText label="Sobrenome" value={form.ultimoNome} onChange={(e: any) => {
                                setForm({...form, ultimoNome: e.target.value});
                            }}/>
                        </UiFlexItem>
                        <UiFlexItem>
                            <UiInputText label="Usuário" value={form.usuario} onChange={(e: any) => {
                                setForm({...form, usuario: e.target.value});
                            }} error={form.usuario.trim() === ""} errorMsg="O usuário deve ser preenchido"
                                         disabled={!!editing}/>
                        </UiFlexItem>
                        <UiFlexItem>
                            <UiInputPassword label="Senha" value={form.senha} onChange={(e: any) => {
                                setForm({...form, senha: e.target.value});
                            }}/>
                            <UiInputPassword label="Confirmar a senha" value={form.confirmarSenha}
                                             error={form.senha !== form.confirmarSenha}
                                             errorMsg="As senhas não conferem" onChange={(e: any) => {
                                setForm({...form, confirmarSenha: e.target.value});
                            }}/>
                        </UiFlexItem>
                        <UiFlexItem justifyContent="flex-end" margin={"10px 10px 0 0"} width={"calc(100% - 10px)"}>
                            <UiButton warning onClick={() => navigate(-1)}>
                                Cancelar
                            </UiButton>
                            <UiButton primary margin="0 0 0 20px" disabled={isFormInvalid()}
                                      onClick={() => handleAddUsuarios()}>
                                {editing ? "Salvar" : "Adicionar"}
                            </UiButton>
                        </UiFlexItem>
                    </UiFlexItem>
                </UiBox>
            </UiFlexItem>
            <UiFlexItem direction="column" md={6}>
                <UiPanel>
                    <UiFlexItem direction="column">
                        <UiFlexItem>
                            <UiText bold>Permissões</UiText>
                        </UiFlexItem>
                        <UiFlexItem wrap="wrap">
                            {Object.keys(fieldsPermissao).map((e) => {
                                return (
                                    <UiFlexItem width="50%" key={e}>
                                        <UiCheckbox label={fieldsPermissao[e]} onChange={() => {
                                            setPermissoes({...permissoes, [e]: !permissoes[e]});
                                        }} value={permissoes[e]}/>
                                    </UiFlexItem>
                                );
                            })}
                        </UiFlexItem>
                    </UiFlexItem>
                </UiPanel>
            </UiFlexItem>
        </UiFlex>
    );
};

export default AddUsuarioPage;
