import UiButton from 'components/UiButton';
import UiFlexItem from 'components/UiFlex';
import {UiInfo} from 'components/UiInfo';
import {useContext, useEffect, useState} from 'react';
import {API} from 'utils/api';
import {SessionContext} from '../../../contexts/SessionContext';
import {useFile} from '../../../hooks/useFile';

const InfosGeodesico = ({infos}: any) => {
  const {headers: headersReq} = useContext(SessionContext);
  const [hasArquivo, setHasArquivo] = useState(false);
  const [attachingFile, setAttachingFile] = useState(false);

  const files = useFile(attachingFile, '.pdf', false);

  useEffect(() => {
    if (attachingFile && files?.length) {
      const file = files[0];
      const fr = new FileReader();
      fr.onload = async () => {
        try {
          await API.attachMarcoGeodesicoFile(fr.result, file.name, infos.idBanco, headersReq);
          setHasArquivo(true);
        } catch (err) {
          console.error(err);
        }
      };
      fr.readAsDataURL(file);
      setAttachingFile(false);
    }
  }, [attachingFile, files, infos.idBanco, headersReq]);

  useEffect(() => {
    setHasArquivo(!!infos['arquivo']);
  }, [infos]);

  return (
    <UiFlexItem>
      <UiFlexItem direction="column">
        <UiFlexItem justifyContent="center" aligItens="center">
          <UiInfo label="Rede geodésica" value={infos['rede']} />
        </UiFlexItem>
        <UiFlexItem justifyContent="center" aligItens="center" margin={'10px 0'}>
          <UiButton primary onClick={() => setAttachingFile(true)}>
            Anexar arquivo
          </UiButton>
        </UiFlexItem>
        <UiFlexItem justifyContent="center" aligItens="center">
          <UiButton
            primary
            disabled={!infos['arquivo'] && !hasArquivo}
            onClick={() => {
              window.open(
                `${API.API_URL}/drive/download?cidade=${infos.cidade}&path=/geodesico/${infos.id}.pdf`
              );
            }}
          >
            Visualizar arquivo
          </UiButton>
        </UiFlexItem>
      </UiFlexItem>
    </UiFlexItem>
  );
};

export default InfosGeodesico;
