import styled from 'styled-components';

const UiBox: any = styled.div<any>`
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 2px silver;
  margin: ${({margin}) => margin || '8px'};
  min-width: ${({minWidth}) => minWidth || 'auto'};
  max-width: ${({maxWidth}) => maxWidth || 'auto'};
  max-height: ${({maxHeight}) => maxHeight || 'auto'};
  overflow: ${({overflow}) => overflow || 'none'};
  padding: ${({padding}) => padding || '10px'};
  width: ${({width}) => width || 'calc(100% - 16px)'};
`;

export default UiBox;
